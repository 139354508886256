import axios from 'axios';

export class DeviceTypeService {
  cache = {
    device_types: {},
  };

  getDeviceTypes = (params, forceReload) => {
    let paramsString = JSON.stringify(params);
    if (this.cache.device_types[paramsString] && !forceReload) {
      return this.cache.device_types[paramsString];
    }
    let options = { params: params };
    let retVal = axios.get('/device_types', options).then(res => {
      this.cache.device_types[paramsString] = retVal;
      return res;
    });
    return retVal;
  };
}

export default new DeviceTypeService();
