import axios from 'axios';

export class CarrierService {
  cache = {
    carriers: {},
  };

  getCarriers = (params, forceReload) => {
    let paramsString = JSON.stringify(params);
    if (this.cache.carriers[paramsString] && !forceReload) {
      return this.cache.carriers[paramsString];
    }
    let options = { params: params };
    let retVal = axios.get('/carriers', options).then(res => {
      this.cache.carriers[paramsString] = retVal;
      return res;
    });
    return retVal;
  };
}

export default new CarrierService();
