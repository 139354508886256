import axios from 'axios';

export class CustomerService {
  cache = {
    customers: {},
  };

  getAllCustomers = (params, forceReload) => {
    let paramsString = JSON.stringify(params);
    if (this.cache.customers[paramsString] && !forceReload) {
      return this.cache.customers[paramsString];
    }
    let options = { params: params };
    let retVal = axios.get('/customers', options).then(res => {
      this.cache.customers[paramsString] = retVal;
      return res;
    });
    return retVal;
  };

  getCustomerById = customer_id => {
    return axios.get(`/customers/${customer_id}`);
  };

  addCustomer = body => {
    return axios.post('/customers', body).then(res => {
      this.clearCache();
      return res;
    });
  };

  editCustomer = (customer_id, body, config) => {
    return axios.put(`/customers/${customer_id}`, body, config).then(res => {
      this.clearCache();
      return res;
    });
  };

  clearCache = () => {
    this.cache = {
      customers: {},
    };
  };
}

export default new CustomerService();
