import React, { Component } from 'react';
import styles from './Service.module.scss';
import ServicesPageStyles from '../ServicesPage.module.scss';
import miscStyles from '../../../styles/misc.module.scss';
import CategoryService from '../../../services/CategoryService';

export default class Service extends Component {
  state = {
    name: '',
    category_id: '',
    etag: '',
    currentEditService: null,
  };

  handleEditClick = async id => {
    let res = await CategoryService.getServiceById(id);
    this.setState({
      name: this.props.service.name,
      category_id: this.props.service.category_id,
      currentEditService: res.data,
      etag: res.headers.etag,
    });
  };

  handleInput = (key, value) => {
    this.setState({ [key]: value });
  };

  submitEdit = () => {
    const { name, category_id, etag } = this.state;
    const { id } = this.props.service;
    let body = {
      id: id,
      name: name,
      category_id: category_id,
    };
    let config = {
      headers: {
        'If-Match': etag,
      },
    };
    try {
      CategoryService.editService(id, body, config).then(res => {
        this.props.getServices();
        this.props.getServiceDomains();
        this.setState({
          etag: '',
          currentEditService: null,
        });
      });
    } catch (err) {
      console.log(err);
      this.setState({
        name: this.props.service.name,
        category_id: this.props.service.category_id,
      });
    }
  };

  cancelEdit = () => {
    //also used after successful PUT to clear inputs and close modal
    this.setState({
      name: this.props.service.name,
      category_id: this.props.service.category_id,
      etag: '',
      currentEditService: null,
    });
  };

  editServiceModal = () => {
    const { name, category_id } = this.state;
    return (
      <div className={miscStyles.modalWrapper} onClick={() => this.cancelEdit()}>
        <div className={ServicesPageStyles.editAddServiceModal} onClick={e => e.stopPropagation()}>
          <h3>Edit {this.props.service.name}</h3>
          <div className={ServicesPageStyles.serviceModalInputs}>
            <div>
              <p>Category:</p>
              <select
                name='edit-service-category-options'
                id='edit-service-category-options'
                value={category_id}
                onChange={e => this.handleInput('category_id', e.target.value)}
              >
                <option disabled defaultValue>
                  -- Select Category --
                </option>
                <option value=''>(none)</option>
                {this.props.categoryOptions()}
              </select>
            </div>
            <div>
              <p>Name:</p>
              <input type='text' value={name} onChange={e => this.handleInput('name', e.target.value)} />
            </div>
          </div>
          <div className={ServicesPageStyles.serviceModalButtons}>
            <p onClick={() => this.cancelEdit()}>Cancel</p>
            <p onClick={() => this.submitEdit()}>Submit</p>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { service, selectedServiceId, handleSelection } = this.props;
    return (
      <div
        className={
          selectedServiceId === service.id
            ? `${ServicesPageStyles.serviceItem} ${styles.selectedService}`
            : `${ServicesPageStyles.serviceItem}`
        }
      >
        <p
          onClick={() => handleSelection('selectedServiceId', service.id)}
          className={ServicesPageStyles.selectServiceText}
        >
          {service.name}
        </p>
        <p
          className={
            selectedServiceId === service.id
              ? `${ServicesPageStyles.editServiceButton}`
              : `${ServicesPageStyles.editServiceButtonUnselected}`
          }
          onClick={e => this.handleEditClick(service.id)}
        >
          EDIT
        </p>

        {this.state.currentEditService && this.editServiceModal()}
      </div>
    );
  }
}
