import axios from 'axios';
import moment from 'moment';

export class ElasticSearchService {
  cache = {
    reports: {
      application: {},
      customer: {},
    },
  };

  getUsage = (params, forceReload) => {
    let date = moment(new Date());
    date.subtract(2, 'months');
    date.set('date', 1);
    date.set('hour', 0);
    date.set('minute', 0);
    date.set('second', 0);

    const backstop = date.format();

    let query = {
      query: {
        query: {
          bool: {
            must: [
              {
                range: {
                  usage_timestamp: {
                    gte: backstop,
                  },
                },
              },
              {
                exists: {
                  field: 'reversed_domain',
                },
              },
            ],
          },
        },
        size: 0,
        aggs: {
          usage: {
            terms: {
              field: params.field,
              show_term_doc_count_error: true,
              size: 100,
              order: {
                bytes: 'desc',
              },
            },
            aggs: {
              bytes: {
                sum: {
                  field: 'bytes',
                },
              },
            },
          },
        },
      },
      action: 'query',
      index: 'accounting_usages',
    };

    if (params.uncategorized) {
      query.query.query.bool.must_not = [
        {
          exists: {
            field: 'category_token',
          },
        },
        {
          exists: {
            field: 'service_token',
          },
        },
      ];
    }

    if (params.deviceId) {
      query.query.query.bool.must.push({
        term: {
          device_id: params.deviceId,
        },
      });
    } else if (params.customerId) {
      query.query.query.bool.must.push({
        term: {
          customer_id: params.customerId,
        },
      });
    }

    return axios.post('/esproxy', query);
  };

  getAuthAttempts = (params, forceReload) => {
    let date = moment(new Date());
    date.subtract(7, 'days');
    date.set('hour', 0);
    date.set('minute', 0);
    date.set('second', 0);

    const backstop = date.format();

    const query = {
      query: {
        query: {
          bool: {
            must: [
              {
                term: {
                  phone: params.phone,
                },
              },
              {
                range: {
                  authdate: {
                    gte: backstop,
                  },
                },
              },
            ],
          },
        },
        size: 10000,
      },
      action: 'query',
      index: 'radius_auth_attempts',
      sort: ['-authdate'],
    };

    return axios.post('/esproxy', query);
  };

  getSessions = (params, forceReload) => {
    let date = moment(new Date());
    date.subtract(7, 'days');
    date.set('hour', 0);
    date.set('minute', 0);
    date.set('second', 0);

    const backstop = date.format();

    const query = {
      query: {
        query: {
          bool: {
            must: [
              {
                term: {
                  phone: params.phone,
                },
              },
              {
                range: {
                  event_timestamp: {
                    gte: backstop,
                  },
                },
              },
            ],
          },
        },
        size: 10000,
      },
      action: 'query',
      index: 'radius_accountings',
      sort: ['-event_timestamp'],
    };

    return axios.post('/esproxy', query);
  };

  getAppDataOverTime = (params, forceReload) => {
    const paramsString = JSON.stringify(params);
    if (this.cache.reports.application[paramsString] && !forceReload) {
      return this.cache.reports.application[paramsString];
    }

    let date = moment(new Date());
    let backstopDate = date.subtract(params.backstop.length, params.backstop.label);
    if (parseInt(backstopDate.format('YYYY')) < 2020) {
      backstopDate.set('year', 2020);
      backstopDate.set('month', 0);
      backstopDate.set('date', 1);
    }

    const query = {
      query: {
        aggs: {
          app_data_over_time: {
            date_histogram: {
              field: 'usage_timestamp',
              calendar_interval: params.backstop.interval.toLowerCase(),
              // "offset": "-1d"
            },
            aggs: {
              distinct_devices: {
                cardinality: {
                  field: 'device_id',
                },
              },
              bytes: {
                sum: {
                  field: 'bytes',
                },
              },
            },
          },
        },
        query: {
          bool: {
            must: [
              {
                range: {
                  usage_timestamp: {
                    gte: backstopDate.format(),
                  },
                },
              },
            ],
          },
        },
        size: 0,
      },
      action: 'query',
      index: 'accounting_usages',
    };

    let retVal = axios.post('/esproxy', query).then(res => {
      this.cache.reports.application[paramsString] = retVal;
      return res;
    });
    return retVal;
  };

  getCustomerDataOverTime = (params, forceReload) => {
    const paramsString = JSON.stringify(params);
    if (this.cache.reports.customer[paramsString] && !forceReload) {
      return this.cache.reports.customer[paramsString];
    }

    let date = moment(new Date());
    let backstopDate = date.subtract(params.backstop.length, params.backstop.label);
    if (parseInt(backstopDate.format('YYYY')) < 2020) {
      backstopDate.set('year', 2020);
      backstopDate.set('month', 0);
      backstopDate.set('date', 1);
    }

    const query = {
      query: {
        aggs: {
          customers: {
            terms: {
              field: 'customer_id',
            },
            aggs: {
              customer_data_over_time: {
                date_histogram: {
                  field: 'usage_timestamp',
                  calendar_interval: params.backstop.interval.toLowerCase(),
                  // "offset": "-1d"
                },
                aggs: {
                  distinct_devices: {
                    cardinality: {
                      field: 'device_id',
                    },
                  },
                  bytes: {
                    sum: {
                      field: 'bytes',
                    },
                  },
                },
              },
            },
          },
        },
        query: {
          bool: {
            must: [
              {
                range: {
                  usage_timestamp: {
                    gte: backstopDate.format(),
                  },
                },
              },
            ],
          },
        },
        size: 0,
      },
      action: 'query',
      index: 'accounting_usages',
    };

    let retVal = axios.post('/esproxy', query).then(res => {
      this.cache.reports.customer[paramsString] = retVal;
      return res;
    });
    return retVal;
  };
}

export default new ElasticSearchService();
