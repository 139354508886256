import React, { Component } from 'react';
import pageStyles from '../../styles/page.module.scss';
import styles from './AccountPage.module.scss';
import UserService from '../../services/UserService';
import PasswordService from '../../services/PasswordService';

import { connect } from 'react-redux';
import { updateDisplayStatus, updateErrorMessage, updateErrors } from '../../ducks/reducer';

export class AccountPage extends Component {
  state = {
    currentUser: {},
    current_password: '',
    new_password: '',
    confirm_new_password: '',
    editPassword: false,
    showMessage: false,
    updateSuccess: false,
    message: '',
    passwordMatch: false,
  };

  componentDidMount = async () => {
    let res = await UserService.getCurrentUser();
    this.setState({ currentUser: res.data });
  };

  toggleEditPassword = () => {
    this.setState({ editPassword: !this.state.editPassword });
  };

  goBack = () => {
    if (this.props.history) {
      this.props.history.goBack();
    } else {
      window.location.hash = '#';
    }
  };

  handleInput = (key, value) => {
    this.setState({ [key]: value });
    if (this.state.new_password !== '' && this.state.confirm_new_password !== '') {
      if (this.state.new_password === this.state.confirm_new_password) {
        this.setState({ passwordMatch: true });
      } else {
        this.setState({ passwordMatch: false });
      }
    } else {
      this.setState({ passwordMatch: false });
    }
  };

  updatePassword = async () => {
    const { currentUser, current_password, new_password, confirm_new_password } = this.state;
    if (new_password !== confirm_new_password) {
      this.props.updateDisplayStatus(true);
      this.props.updateErrorMessage('Passwords do not match. Please try again.');
      return;
    }
    if (current_password.length === 0 || new_password.length === 0 || confirm_new_password.length === 0) {
      this.props.updateDisplayStatus(true);
      this.props.updateErrorMessage('Missing required field(s)..');
      return;
    }
    let body = {
      current_password: current_password,
      password: new_password,
      email: currentUser.email,
    };
    let success = res => {
      if (res.status >= 200 && res.status <= 299) {
        this.setState({
          showMessage: true,
          updateSuccess: true,
          message: 'Password was successfully updated.',
          current_password: '',
          new_password: '',
          confirm_new_password: '',
          editPassword: false,
          passwordMatch: false,
        });
      }
    };
    let fail = err => {
      console.log(err);
      this.setState({
        showMessage: true,
        updateSuccess: false,
        message: 'Password was not updated. Please check credentials and try again.',
        current_password: '',
        new_password: '',
        confirm_new_password: '',
        passwordMatch: false,
      });
    };
    PasswordService.updatePassword(body).then(success, fail);
  };

  cancelUpdatePassword = () => {
    this.setState({
      editPassword: false,
      showMessage: false,
      message: '',
      current_password: '',
      new_password: '',
      confirm_new_password: '',
      passwordMatch: false,
    });
  };

  render() {
    const {
      currentUser,
      current_password,
      new_password,
      confirm_new_password,
      editPassword,
      showMessage,
      updateSuccess,
      message,
      passwordMatch,
    } = this.state;

    return (
      <div className={pageStyles.page} style={{ justifyContent: 'center' }}>
        <div className={styles.container}>
          <p className={styles.backButton} onClick={() => this.goBack()}>
            BACK
          </p>
          <p className={pageStyles.title}>Account Details</p>
          <div className={styles.detailsBox}>
            <div>
              <span>Name: </span>
              <span>{currentUser.first_name} </span>
              <span>{currentUser.last_name}</span>
            </div>
            <div>
              <span>Email: </span>
              <span>{currentUser.email}</span>
            </div>
            <div>
              <span>Password: </span>
              <span>********</span>
              {!editPassword && (
                <>
                  <span onClick={this.toggleEditPassword} className={styles.changePasswordButton}>
                    Change
                  </span>
                </>
              )}
              {editPassword && (
                <>
                  <span onClick={this.updatePassword} className={styles.changePasswordButton}>
                    Save
                  </span>
                </>
              )}
              {editPassword && (
                <span
                  onClick={this.cancelUpdatePassword}
                  className={styles.changePasswordButton}
                  style={{ color: 'black' }}
                >
                  Cancel
                </span>
              )}
              {editPassword && (
                <div className={styles.passwordBox}>
                  <input
                    value={current_password}
                    onChange={e => this.handleInput('current_password', e.target.value)}
                    placeholder=' current password'
                    type='password'
                  />
                  <input
                    className={passwordMatch === true ? 'password-match' : null}
                    value={new_password}
                    onChange={e => this.handleInput('new_password', e.target.value)}
                    placeholder=' new password'
                    type='password'
                  />
                  <input
                    className={passwordMatch ? 'password-match' : null}
                    value={confirm_new_password}
                    onChange={e => this.handleInput('confirm_new_password', e.target.value)}
                    placeholder=' confirm new password'
                    type='password'
                  />
                </div>
              )}
              {showMessage && (
                <div>
                  <span style={{ color: updateSuccess ? 'green' : 'red' }}>{message}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = reduxState => reduxState;

export default connect(mapStateToProps, { updateDisplayStatus, updateErrorMessage, updateErrors })(AccountPage);
