import React from 'react';
import styles from './ExportDevicesModal.module.scss';
import miscStyles from '../../../styles/misc.module.scss';

import { CSVLink } from 'react-csv';
import { Button } from 'reactstrap';

export const formatDataForCSV = (devices, deviceAuthAttempts) => {
  const headers = [
    'APN',
    'Carrier',
    'Mobilsentry',
    'Service Number',
    'Status',
    'IP Address',
    'Device Type',
    'Customer',
    'Name',
    'Username',
    'Password',
    'Email',
  ];
  const rows = devices.map(device => {
    let status = '';
    if (deviceAuthAttempts[device.id]) {
      status = deviceAuthAttempts[device.id].latestAuthDate;
    }

    return [
      device.apn_name || '',
      device.carrier_name || '',
      device.mobilsentry_name || '',
      device.service_number || '',
      status || '',
      device.address || '',
      device.device_type || '',
      device.customer_name || '',
      device.name || '',
      device.username || '',
      device.password || '',
      device.email || '',
    ];
  });

  return [headers, ...rows];
};

export const createCSVFilename = date => `Devices ${date.toLocaleString()}.csv`;

export default function ExportDevicesModal(props) {
  const { devices, filteredDevices, deviceAuthAttempts, closeExportModal } = props;

  return (
    <div className={miscStyles.modalWrapper} onClick={closeExportModal}>
      <div className={styles.exportDevicesModal} onClick={e => e.stopPropagation()}>
        <CSVLink data={formatDataForCSV(devices, deviceAuthAttempts)} filename={createCSVFilename(new Date())}>
          <Button size='sm' outline>
            Export All Devices ({devices.length})
          </Button>
        </CSVLink>
        <CSVLink data={formatDataForCSV(filteredDevices, deviceAuthAttempts)} filename={createCSVFilename(new Date())}>
          <Button size='sm' outline disabled={filteredDevices.length === 0}>
            Export Filtered Devices ({filteredDevices.length})
          </Button>
        </CSVLink>

        <i className='fas fa-times close-reconcile-modal-button' onClick={closeExportModal}></i>
      </div>
    </div>
  );
}
