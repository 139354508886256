import React, { Component } from 'react';
import './ImportCSV.scss';
import miscStyles from '../../../styles/misc.module.scss';
import DeviceService from '../../../services/DeviceService';
import utils from '../../../utils/utils.js';

import CSVReader from 'react-csv-reader';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

const possibleIPNames = [
  'ip_address',
  'ip address',
  'address',
  'i_address',
  'ipaddress',
  'iaddress',
  'inet',
  'ip',
  'IP',
  'IP ADDRESS',
  'IP_ADDRESS',
  'IP Address',
  'IP_Address',
];
const possibleServiceNums = [
  'number',
  'service number',
  'Service Number',
  'Service number',
  'service_number',
  'service_num',
  'servicenumber',
  'servicenum',
  'phone_number',
  'mobile_number',
  'phonenumber',
  'mobilenumber',
  'wireless number',
  'wireless_number',
  'wirelessnumber',
  'device number',
  'device_number',
  'line number',
  'line_number',
];

export default class ImportCSV extends Component {
  state = {
    showUploadButtons: true,
    uploadInProgress: false,
    showDevicesStatus: false,
    showIPStatus: false,
    showIPCategorySelection: false,
    showDeviceCategorySelection: false,
    devices: [],
    successful: [],
    failed: [],
    headers: [],
    data: [],
    selectedIP: '',
    selectedServiceNum: '',
    apn: null,
    autoSelectedApn: null,
    carrier: null,
    customer: null,
  };

  updateState = async category => {
    let states = {
      showUploadButtons: false,
      showDevicesStatus: false,
      showIPCategorySelection: false,
      showDeviceCategorySelection: false,
    };
    if (states.hasOwnProperty(category)) {
      states[category] = true;
      this.setState(states);
    }
  };

  closeImportCSVModal = () => {
    this.setState({ showDevicesStatus: false });
    this.props.closeImportCSVModal();
    window.location.reload();
  };

  handleSelection = (property, value) => {
    if (value) {
      if (property === 'carrier') {
        if (this.state.carrier && this.state.carrier !== value) {
          this.setState({ autoSelectedApn: null });
        }
      }
      this.setState({ [property]: value });
    }
  };

  handleDeviceUpload = async data => {
    await this.updateState('showDeviceCategorySelection');
    // Stripping out noise & blank rows
    let cleanData = utils.stripCSVNoise(data);
    let headers = cleanData.splice(0, 1)[0];
    headers = headers.map(header => header.trim().toLowerCase());
    // let required = ['service_number', 'name', 'customer_id', 'apn_id']

    let selectedServiceNum;
    let selectedIP;
    headers.forEach(header => {
      if (possibleServiceNums.includes(header)) {
        selectedServiceNum = header;
      }
      if (possibleIPNames.includes(header)) {
        selectedIP = header;
      }
    });

    let deviceDictionary = cleanData.map(row => {
      let obj = {};
      row.forEach((item, index) => {
        const cleanItem = item.trim().replace(/[^\w\s-.|]+/g, ''); // remove all non-alphanumeric characters except for spaces, dashes, periods, and pipes
        obj[headers[index]] = cleanItem;
      });
      return obj;
    });

    this.setState({
      headers: headers,
      data: deviceDictionary,
      selectedServiceNum: selectedServiceNum,
      selectedIP: selectedIP,
      showDeviceCategorySelection: true,
    });
  };

  submitDevices = () => {
    this.setState(
      {
        uploadInProgress: true,
        showDeviceCategorySelection: false,
        showDevicesStatus: true,
      },
      async () => {
        const { data, selectedServiceNum, selectedIP, apn, customer } = this.state;
        let successful = [];
        let failed = [];

        let results = data.map(device => {
          let success = res => {
            successful.push(res.data);
          };
          let fail = err => {
            console.log(err);
            failed.push(device);
          };
          const formattedNum = utils.formatServiceNum(device[selectedServiceNum]);
          let body = {
            service_number: formattedNum,
            customer_id: customer.id,
            apn_id: apn.id,
            address: device[selectedIP] || '',
            name: device.name || '',
            username: device.username || '',
            password: device.password || '',
            email: device.email || '',
            device_type: device['device type'],
            cycle_start_day: null,
          };
          let config = {
            skipErrorHandler: true,
          };
          return DeviceService.addDevice(body, config).then(success, fail);
        });

        await Promise.all(results);
        let allSuccessful = [].concat(...successful);

        this.setState({
          successful: allSuccessful,
          failed: failed,
          uploadInProgress: false,
        });
      }
    );
  };

  handleIPUpload = async data => {
    await this.updateState('showIPCategorySelection');

    let cleanData = utils.stripCSVNoise(data); // stripping out noise at top of .csv file
    let headers = cleanData.splice(0, 1)[0];
    headers = headers.map(header => header.trim().toLowerCase());

    let selectedIP;
    let selectedServiceNum;
    headers.forEach(header => {
      if (possibleIPNames.includes(header) || possibleIPNames.includes(header.toLowerCase())) {
        selectedIP = header;
      }
      if (possibleServiceNums.includes(header) || possibleServiceNums.includes(header.toLowerCase())) {
        selectedServiceNum = header;
      }
    });

    let deviceObjects = cleanData.map(row => {
      let obj = {};
      row.forEach((item, index) => {
        obj[headers[index]] = item.trim();
      });
      return obj;
    });

    this.setState({
      headers: headers,
      data: deviceObjects,
      selectedIP: selectedIP,
      selectedServiceNum: selectedServiceNum,
      showIPCategorySelection: true,
      uploadInProgress: false,
    });
  };

  submitIPs = () => {
    this.setState(
      {
        showIPCategorySelection: false,
        uploadInProgress: true,
        showIPStatus: true,
      },
      async () => {
        const { data, selectedServiceNum, selectedIP } = this.state;
        const allDevices = this.props.devices;
        let successful = []; // successfully updated devices
        let failed = []; // failed updates

        let conflicts = [];
        let nonExistentDevices = [];
        let allDevicesByAddress = {}; // dictionary of all devices with address as the key, and the whole object as the value
        let allDevicesByServiceNum = {}; // dictionary of all devices with service_num as key, and the whole object as the value

        allDevices.forEach(device => {
          // creating allDevicesByAddress dictionary and allDevicesByServiceNum dictionary
          if (device.address) {
            allDevicesByAddress[device.address] = { ...device };
          }
          if (device.service_number) {
            allDevicesByServiceNum[device.service_number] = { ...device };
          }
        });

        let devicesToUpdate = data.filter(device => {
          device.service_number = utils.formatServiceNum(device[selectedServiceNum]);
          if (device[selectedIP] === '') {
            device.address = null;
          } else {
            device.address = utils.formatIpAddress(device[selectedIP]);
          }

          if (allDevicesByServiceNum[device.service_number]) {
            // if device exists
            if (allDevicesByAddress[device.address]) {
              // if another device already has the ip address
              conflicts.push(allDevicesByAddress[device.address]);
            }
            device.id = allDevicesByServiceNum[device.service_number].id;
            return true;
          } else {
            nonExistentDevices.push(device);
            return false;
          }
        });

        let conflictResults = conflicts.map(device => {
          return DeviceService.getDeviceById(device.id).then(res => {
            if (res) {
              device.etag = res.headers.etag;
              let body = res.data;
              body.address = null;
              return DeviceService.editDevice(device.id, body, {
                headers: {
                  'If-Match': device.etag,
                },
                skipErrorHandler: true,
              });
            }
          });
        });

        // resolving conflicts first by nulling out address on the devices that have the desired ip address
        await Promise.all(conflictResults).then(async () => {
          let updateResults = devicesToUpdate.map(device => {
            let newAddress = device.address;
            return DeviceService.getDeviceById(device.id).then(res => {
              let success = res => {
                successful.push(res.data);
              };
              let fail = err => {
                console.log(err);
                failed.push(device);
              };
              if (res) {
                device.etag = res.headers.etag;
                let body = res.data;
                body.address = newAddress;
                return DeviceService.editDevice(device.id, body, {
                  headers: {
                    'If-Match': device.etag,
                  },
                  skipErrorHandler: true,
                }).then(success, fail);
              } else {
                fail(`Could not get device with the ID ${device.id}`);
              }
            });
          });
          await Promise.all(updateResults); // then updating the ip addresses
          this.setState({
            successful: successful,
            failed: failed,
            uploadInProgress: false,
          });
        });
      }
    );
  };

  showDevicesStatus = () => {
    const { uploadInProgress, successful, failed, selectedServiceNum } = this.state;

    let success = successful.map(device => {
      return (
        <div key={device.service_number} className='device-upload-status-row'>
          <div>Service Number: {device.service_number}</div>
          <div>Customer ID: {device.customer_id}</div>
          <div>APN ID: {device.apn_id}</div>
          <div>Device Model ID: {device.device_model_id}</div>
        </div>
      );
    });

    let fail = failed.map(device => {
      return (
        <div key={device[selectedServiceNum]} className='device-upload-status-row'>
          <span>Service Number: {device[selectedServiceNum]}</span>
          <span>Customer ID: {this.state.customer.id}</span>
          <span>APN ID: {this.state.apn.id}</span>
          <span>Device Model ID: {device.device_model_id}</span>
        </div>
      );
    });

    return (
      <div className='upload-status'>
        <div className='upload-status-header'>
          <h4>Upload Status</h4>
          <Button className='csv-close-button' onClick={() => this.closeImportCSVModal()}>
            Close
          </Button>
        </div>
        {uploadInProgress ? (
          <div className='progressContainer'>
            <CircularProgress />
          </div>
        ) : (
          <div className='upload-status-category-container'>
            <div style={{ color: 'green', marginBottom: '1rem' }} className='upload-status-category'>
              <h5>Successful ({successful.length}):</h5>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  margin: '0',
                }}
              >
                {success}
              </div>
            </div>
            <div style={{ color: 'red' }} className='upload-status-category'>
              <h5>Failed ({failed.length}):</h5>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  margin: '0',
                }}
              >
                {fail}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  showIPStatus = () => {
    const { uploadInProgress, successful, failed } = this.state;

    let success = successful.map(device => {
      return (
        <div key={device.service_number} className='device-upload-status-row'>
          <span>{device.service_number}</span>
          <span>{device.address}</span>
        </div>
      );
    });

    let fail = failed.map(device => {
      return (
        <div key={device.id} className='device-upload-status-row'>
          <span>{device.service_number}</span>
          <span>{device.address}</span>
        </div>
      );
    });

    return (
      <div className='upload-status'>
        <h4>Upload Status</h4>
        <Button className='csv-close-button' onClick={() => this.closeImportCSVModal()}>
          Close
        </Button>
        <div className='results-container'>
          {uploadInProgress ? (
            <div className='progressContainer'>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div style={{ color: 'green', marginBottom: '1rem' }} className='upload-status-category'>
                <h5>Successful ({successful.length}):</h5>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    margin: '0',
                  }}
                >
                  <div className='upload-ip-header'>
                    <p>Service Number:</p>
                    <p>New IP Address:</p>
                  </div>
                  {success}
                </div>
              </div>
              <div style={{ color: 'red' }} className='upload-status-category'>
                <h5>Failed ({failed.length}):</h5>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    margin: '0',
                  }}
                >
                  <div className='upload-ip-header'>
                    <p>Service Number:</p>
                    <p>Attempted IP Address:</p>
                  </div>
                  {fail}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  chooseApn = () => {
    let apnOptions = this.props.apns.filter(apn => apn.carrier_id === this.state.carrier.id);
    if (this.state.carrier && this.state.customer) {
      if (this.state.autoSelectedApn) {
        return (
          <div className='import-typeahead-title'>
            <p>APN: </p>
            <p>{this.state.autoSelectedApn.name}</p>
          </div>
        );
      } else {
        return (
          <div className='import-typeahead-title'>
            <p>APN: </p>
            <Typeahead
              options={apnOptions ? apnOptions : []}
              labelKey='name'
              id='bulk-import-apn-selector'
              onChange={apn => this.handleSelection('apn', apn[0])}
              placeholder={'Select APN'}
              selectHintOnEnter={true}
              ref={ref => (this._typeahead1 = ref)}
            />
          </div>
        );
      }
    }
  };

  chooseCarrier = () => {
    return (
      <div className='import-typeahead-title'>
        <p>Carrier: </p>
        <Typeahead
          options={this.props.carriers ? this.props.carriers : []}
          labelKey='name'
          id='bulk-import-carrier-selector'
          onChange={carrier => this.handleSelection('carrier', carrier[0])}
          placeholder={'Select Carrier'}
          selectHintOnEnter={true}
          ref={ref => (this._typeahead2 = ref)}
        />
      </div>
    );
  };

  chooseCustomer = () => {
    return (
      <div className='import-typeahead-title'>
        <p>Customer: </p>
        <Typeahead
          options={this.props.customers ? this.props.customers : []}
          labelKey='name'
          id='bulk-import-customer-selector'
          onChange={customer => this.handleSelection('customer', customer[0])}
          placeholder={'Select Customer'}
          selectHintOnEnter={true}
          ref={ref => (this._typeahead3 = ref)}
        />
      </div>
    );
  };

  showDeviceCategorySelection = () => {
    let options = this.state.headers.map(header => (
      <option value={header} key={header}>
        {header}
      </option>
    ));
    return (
      <div>
        <div>
          <label htmlFor='select-device-category'>Select Category for Service Number</label>
          <select
            value={this.state.selectedServiceNum}
            id='select-device-category'
            onChange={e => this.handleSelection('selectedServiceNum', e.target.value)}
          >
            <option disabled value=''>
              Select
            </option>
            {options}
          </select>
        </div>
        <Button disabled={!this.state.selectedServiceNum} color='success' onClick={() => this.submitDevices()}>
          Submit
        </Button>
      </div>
    );
  };

  showIPCategorySelection = () => {
    let options = this.state.headers.map(header => (
      <option value={header} key={header}>
        {header}
      </option>
    ));
    return (
      <div>
        <div>
          <label htmlFor='select-ip-category'>Select Category for IP Address</label>
          <select
            value={this.state.selectedIP}
            id='select-ip-category'
            onChange={e => this.handleSelection('selectedIP', e.target.value)}
          >
            {options}
          </select>
        </div>
        <div>
          <label htmlFor='select-service-num'>Select Category for Service Number</label>
          <select
            value={this.state.selectedServiceNum}
            name=''
            id='select-service-num'
            onChange={e => this.handleSelection('selectedServiceNum', e.target.value)}
          >
            {options}
          </select>
        </div>
        <Button onClick={() => this.submitIPs()}>Submit</Button>
      </div>
    );
  };

  render() {
    return (
      <div className={miscStyles.modalWrapper} onClick={() => this.props.closeImportCSVModal()}>
        <div className='importCSV-modal' onClick={e => e.stopPropagation()}>
          {this.state.showUploadButtons && (
            <>
              <div className='bulk-import-box'>
                <h3>Bulk Import Devices</h3>
                {this.chooseCarrier()}
                {this.state.carrier && this.chooseCustomer()}
                {this.state.customer && this.state.carrier && this.chooseApn()}
                {this.state.apn && this.state.customer && (
                  <CSVReader
                    onFileLoaded={this.handleDeviceUpload}
                    label={'Devices File:'}
                    cssClass='csv-upload-button'
                  />
                )}
                {this.state.autoSelectedApn && this.state.customer && (
                  <CSVReader
                    onFileLoaded={this.handleDeviceUpload}
                    label={'Devices File:'}
                    cssClass='csv-upload-button'
                  />
                )}
              </div>
              <div className='bulk-import-box'>
                <h3>Bulk Update IP Address</h3>
                <CSVReader
                  onFileLoaded={this.handleIPUpload}
                  label={'IP Addresses File:'}
                  cssClass='csv-upload-button'
                />
              </div>
            </>
          )}
          {this.state.showDevicesStatus && this.showDevicesStatus()}
          {this.state.showIPStatus && this.showIPStatus()}
          {this.state.showDeviceCategorySelection && this.showDeviceCategorySelection()}
          {this.state.showIPCategorySelection && this.showIPCategorySelection()}
          {!this.state.showDevicesStatus && !this.state.showIPStatus && (
            <Button onClick={() => this.closeImportCSVModal()}>Cancel</Button>
          )}
        </div>
      </div>
    );
  }
}
