import React, { Component } from 'react';
import styles from './DevicesPage.module.scss';
import pageStyles from '../../styles/page.module.scss';
import rightMenuStyles from '../../styles/rightMenu.module.scss';
import miscStyles from '../../styles/misc.module.scss';
import Device from './Device/Device';
import utils from '../../utils/utils';
import ImportCSV from '../../components/modals/ImportCSV/ImportCSV';
import DeviceService from '../../services/DeviceService';
import CustomerService from '../../services/CustomerService';
import ApnService from '../../services/ApnService';
import MobilSentryService from '../../services/MobilSentryService';
import CarrierService from '../../services/CarrierService';
import DeviceTypeService from '../../services/DeviceTypeService';
import ElasticSearchService from '../../services/ElasticSearchService';
import ReconcileIPModal from '../../components/modals/ReconcileIPModal/ReconcileIPModal';
import ExportDevicesModal from '../../components/modals/ExportDevicesModal/ExportDevicesModal';

import { connect } from 'react-redux';
import { updateDisplayStatus, updateErrorMessage, updateErrors } from '../../ducks/reducer';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import BeatLoader from 'react-spinners/BeatLoader';

export class DevicesPage extends Component {
  state = {
    devices: [],
    filteredDevices: [],
    deviceAuthAttempts: '',
    carriers: [],
    mobilsentries: [],
    customers: [],
    device_types: [],
    apns: [],
    customerApns: [],
    filterByApn: '',
    filterByCarrier: '',
    filterByMobilsentry: '',
    filterByDeviceType: '',
    filterByCustomer: '',
    customer: null,
    new_carrier: null,
    apn: null,
    device_type: '',
    name: '',
    service_number: '',
    username: '',
    password: '',
    address: null,
    email: '',
    sortDescending: true,
    mouseHover: false,
    hoveredCategory: '',
    deviceSearch: '',
    currentEdit: null,
    isLoading: true,
    displayAddMenu: false,
    displayImportCSVModal: false,
    displayReconcileIPModal: false,
    displayExportModal: false,
    page: 1,
    deviceLimit: 300,
    limitStartIdx: 0,
    limitEndIdx: '',
  };

  componentDidMount = async () => {
    try {
      await this.getCustomers();
      await this.getMobilSentries();
      await this.getApns();
      await this.getDevices();
      await this.getCarriers();
      await this.getDeviceTypes();
      this.assignDataToDevice();
      this.filterDevices();
      this.setState({ isLoading: false, limitEndIdx: this.state.deviceLimit - 1 });
      await this.getAuthAttempts();
    } catch (err) {
      console.log(err);
    }
  };

  getDevices = async () => {
    const res = await DeviceService.getDevices();
    this.setState({ devices: res.data });
  };

  getCustomers = async () => {
    const res = await CustomerService.getAllCustomers();
    this.setState({ customers: res.data });
  };

  getMobilSentries = async () => {
    let res = await MobilSentryService.getMobilSentries();
    this.setState({ mobilsentries: res.data });
  };

  getApns = async () => {
    const res = await ApnService.getApns();
    this.setState({ apns: res.data });
  };

  getCarriers = async () => {
    let res = await CarrierService.getCarriers();
    this.setState({ carriers: res.data });
  };

  getDeviceTypes = async () => {
    let res = await DeviceTypeService.getDeviceTypes();
    this.setState({ device_types: res.data });
  };

  getCustomerApns = async customer_id => {
    let res = await ApnService.getApnsByCustomerId(customer_id);
    this.setState({ customerApns: res.data }, () => {
      let customerApnOptions = this.state.customerApns;
      let autoSelectedApn = [];
      if (this.state.customerApns && this.state.new_carrier) {
        customerApnOptions = this.state.customerApns.filter(apn => apn.carrier_id === this.state.new_carrier.id);
        if (customerApnOptions.length === 1) {
          autoSelectedApn = customerApnOptions.slice(0, 1); //this will be an array of one value for the typeahead 'selected' prop
          if (!this.state.apn || this.state.apn.id !== autoSelectedApn[0].id) {
            this.handleSelection('apn', autoSelectedApn[0]);
          }
        }
      }
    });
  };

  assignDataToDevice = () => {
    const { devices, apns, carriers, customers, mobilsentries } = this.state;
    let appended = devices.map(device => {
      let assignedApn = apns.filter(apn => apn.id === device.apn_id)[0];
      let carrier = carriers.filter(carrier => carrier.id === assignedApn.carrier_id)[0] || { name: '' };
      let customer = customers.filter(customer => customer.id === device.customer_id)[0] || { name: '' };
      let mobilsentry = mobilsentries.filter(mobilsentry => mobilsentry.id === customer.mobilsentry_id)[0] || {
        name: '',
      };
      let strippedIP = null;
      if (device.address !== null) {
        strippedIP = device.address.replace(/[^0-9a-z]/gi, '');
      }
      device.customer = customer;
      device.customer_name = customer.name;
      device.assigned_apn = assignedApn;
      device.apn_name = assignedApn.name;
      device.carrier = carrier;
      device.carrier_name = carrier.name;
      device.mobilsentry = mobilsentry;
      device.mobilsentry_name = mobilsentry.db_name;
      device.alpha_numeric_ip = strippedIP;
      return device;
    });
    this.setState({ devices: appended });
  };

  clearInputs = () => {
    if (this._typeahead1) {
      this._typeahead1.getInstance().clear();
    }
    if (this._typeahead2) {
      this._typeahead2.getInstance().clear();
    }
    if (this._typeahead3) {
      this._typeahead3.getInstance().clear();
    }
    if (this._typeahead4) {
      this._typeahead4.getInstance().clear();
    }
    this.setState({
      customer: null,
      new_carrier: null,
      apn: null,
      device_type: '',
      name: '',
      service_number: '',
      username: '',
      password: '',
      address: null,
      email: '',
    });
  };

  addDevice = async () => {
    const {
      service_number,
      address,
      name,
      username,
      password,
      email,
      customer,
      device_type,
      apn,
      new_carrier,
    } = this.state;
    if (!service_number || !name || !customer || !device_type || !apn || !new_carrier) {
      this.props.updateDisplayStatus(true);
      this.props.updateErrorMessage('Missing required field(s)..');
      return;
    }
    this.toggleAddMenu();
    let serviceNum = utils.formatServiceNum(service_number);

    let body = {
      service_number: serviceNum,
      address: address, //nullable
      name: name,
      username: username,
      password: password,
      email: email,
      customer_id: customer.id,
      device_type: device_type.device_type,
      apn_id: apn.id,
      cycle_start_day: null,
      deleted: false,
    };
    try {
      await DeviceService.addDevice(body);
      this.setState({ isLoading: true });
      await this.getCustomers();
      await this.getMobilSentries();
      await this.getApns();
      await this.getDevices();
      await this.getDeviceTypes();
      await this.getCarriers();
      this.assignDataToDevice();
      this.setState({ isLoading: false });
      this.clearInputs();
    } catch (err) {
      console.log(err);
      this.props.updateDisplayStatus(true);
      this.props.updateErrorMessage('Error adding device');
      this.setState({ displayAddMenu: true });
    }
  };

  cancelAddDevice = () => {
    this.clearInputs();
    this.toggleAddMenu();
    this.setState({
      new_carrier: null,
      apn: null,
      customer: null,
      device_type: '',
      service_number: '',
      address: null,
      name: '',
      username: '',
      password: null,
      email: '',
    });
  };

  toggleAddMenu = () =>
    this.setState({
      displayAddMenu: !this.state.displayAddMenu,
    });

  handleInput = (key, value) => {
    this.setState({ [key]: value });
  };

  handleSelection = async (property, value) => {
    if (value) {
      this.setState({
        [property]: value,
      });
      if (property === 'customer') {
        this.setState({
          new_carrier: null,
          apn: null,
          device_type: '',
          name: '',
          service_number: '',
          username: '',
          password: '',
          address: null,
          email: '',
        });
      }
      if (property === 'new_carrier') {
        this.setState(
          {
            apn: null,
            device_type: '',
            name: '',
            service_number: '',
            username: '',
            password: '',
            address: null,
            email: '',
          },
          async () => {
            this._typeahead4.getInstance().clear();
            await this.getCustomerApns(this.state.customer.id);
          }
        );
      }
    }
  };

  handleSort = async property => {
    const { filteredDevices, sortDescending } = this.state;

    this.setState({ sortDescending: !sortDescending }, () => {
      let sorted = utils.handleSort(property, filteredDevices, sortDescending);
      this.setState({ devices: sorted });
    });
  };

  handleSearch = input => {
    input = input.replace(/[^0-9a-z]/gi, ''); //strips non-alphanumeric chars
    this.setState({ deviceSearch: input, page: 1, limitStartIdx: 0, limitEndIdx: this.state.deviceLimit - 1 }, () => {
      this.filterDevices();
    });
  };

  handleMouseEnter = category => {
    this.setState({
      mouseHover: true,
      hoveredCategory: category,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      mouseHover: false,
      hoveredCategory: '',
    });
  };

  handleFilterBy = (id, category) => {
    let property = 'filterBy' + category;

    this.setState(
      {
        [property]: id,
        page: 1,
        limitStartIdx: 0,
        limitEndIdx: this.state.deviceLimit - 1,
      },
      () => {
        this.filterDevices();
      }
    );
  };

  clearFilters = () => {
    this.setState(
      {
        filterByApn: '',
        filterByCarrier: '',
        filterByMobilsentry: '',
        filterByDeviceType: '',
        filterByCustomer: '',
        page: 1,
        limitStartIdx: 0,
        limitEndIdx: this.state.deviceLimit - 1,
      },
      () => {
        this.filterDevices();
      }
    );
  };

  clearSearch = () => {
    if (!this.state.deviceSearch) return;

    this._deviceSearch.value = '';
    this.setState({ deviceSearch: '', page: 1, limitStartIdx: 0, limitEndIdx: this.state.deviceLimit - 1 }, () => {
      this.filterDevices();
    });
  };

  setCurrentEdit = id => {
    this.setState({ currentEdit: id });
  };

  filterDevices = () => {
    const {
      devices,
      deviceSearch,
      filterByApn,
      filterByCarrier,
      filterByMobilsentry,
      filterByCustomer,
      filterByDeviceType,
    } = this.state;
    let filteredDevices = [...devices];

    if (filterByApn) {
      let id = parseInt(filterByApn);
      filteredDevices = filteredDevices.filter(device => device.apn_id === id);
    }

    if (filterByCarrier) {
      let id = parseInt(filterByCarrier);
      filteredDevices = filteredDevices.filter(device => device.carrier.id === id);
    }

    if (filterByMobilsentry) {
      let id = parseInt(filterByMobilsentry);
      filteredDevices = filteredDevices.filter(device => device.mobilsentry.id === id);
    }

    if (filterByCustomer) {
      let id = parseInt(filterByCustomer);
      filteredDevices = filteredDevices.filter(device => device.customer_id === id);
    }

    if (filterByDeviceType) {
      filteredDevices = filteredDevices.filter(device => device.device_type === filterByDeviceType);
    }

    if (deviceSearch) {
      filteredDevices = filteredDevices.filter(device => {
        for (let key in device) {
          if (typeof device[key] === 'string') {
            if (device[key].toLowerCase().includes(deviceSearch.toLowerCase())) {
              return true;
            }
          } else if (typeof device[key] === 'number') {
            if (device[key].toString().includes(deviceSearch.toString())) {
              return true;
            }
          }
        }
        return false;
      });
    }

    this.setState({ filteredDevices: filteredDevices });
  };

  limitDevices = list => {
    const { limitStartIdx, limitEndIdx } = this.state;
    let limitedDevices = list.slice(limitStartIdx, limitEndIdx);

    return limitedDevices;
  };

  closeImportCSVModal = () => {
    this.setState({ displayImportCSVModal: false });
  };

  closeReconcileIPModal = () => {
    this.setState({ displayReconcileIPModal: false });
  };

  closeExportModal = () => {
    this.setState({ displayExportModal: false });
  };

  getAuthAttempts = async () => {
    // this method filters the devices down to only AT&T devices and then gets their auth attempt data from ElasticSearch. It then creates a map with the devices and their respective auth data. The reason this method lives in the DevicesPage component instead of in the individual Device component is because this way we can keep track of when all auth data promises have resolved, and conditionally render the csv export button once all data is retrieved. This should make it so the user can't hit the export button until all data has arrived and avoid inaccurate csv file exports.
    const { devices } = this.state;
    let authAttemptsMap = {};

    let attempts = devices
      .filter(device => device.carrier.id === 1) // id 1 is AT&T
      .map(device => {
        const params = {
          phone: device.service_number,
        };

        return ElasticSearchService.getAuthAttempts(params).then(res => {
          if (res.data.hits.hits.length > 0) {
            const latestAuth = res.data.hits.hits[0]._source;
            let authdate = new Date(latestAuth.authdate);
            const year = authdate.getFullYear().toString().split('').slice(2).join('');
            let month = authdate.getMonth() + 1;
            let day = authdate.getDate();
            let hour = authdate.getHours();
            let minutes = authdate.getMinutes();
            const daytime = hour < 12 ? 'am' : 'pm';

            if (month < 10) {
              month = `0${month}`;
            }

            if (day < 10) {
              day = `0${day}`;
            }

            if (hour > 12) {
              hour = hour - 12;
            }

            if (minutes < 10) {
              minutes = `0${minutes}`;
            }

            const authdateString = `${month}/${day}/${year} at ${hour}:${minutes}${daytime}`;

            const authData = {
              latestAuth: latestAuth,
              latestAuthDate: authdateString,
            };

            authAttemptsMap[device.id] = authData;

            return authData;
          }
        });
      });

    await Promise.all(attempts).then(res => {
      this.setState({ deviceAuthAttempts: authAttemptsMap });
    });
  };

  getMaxPage = () => {
    const { filteredDevices, deviceLimit } = this.state;

    if (filteredDevices.length === 0) {
      return 1;
    } else {
      return Math.ceil(filteredDevices.length / deviceLimit);
    }
  };

  incrementPage = () => {
    const { page, deviceLimit, limitStartIdx, limitEndIdx } = this.state;

    if (page !== this.getMaxPage()) {
      this.setState({
        page: page + 1,
        limitStartIdx: limitStartIdx + deviceLimit,
        limitEndIdx: limitEndIdx + deviceLimit,
      });
    }
  };

  decrementPage = () => {
    const { page, deviceLimit, limitStartIdx, limitEndIdx } = this.state;

    if (page !== 1) {
      this.setState({
        page: page - 1,
        limitStartIdx: limitStartIdx - deviceLimit,
        limitEndIdx: limitEndIdx - deviceLimit,
      });
    }
  };

  apnOptions = () => {
    let options = this.state.apns.map(apn => {
      return (
        <option key={apn.id} value={apn.id}>
          {apn.name}
        </option>
      );
    });
    return options;
  };

  carrierOptions = () => {
    let options = this.state.carriers.map(carrier => {
      return (
        <option key={carrier.id} value={carrier.id}>
          {carrier.name}
        </option>
      );
    });
    return options;
  };

  mobilsentryOptions = () => {
    let options = this.state.mobilsentries.map(mobilsentry => {
      return (
        <option key={mobilsentry.id} value={mobilsentry.id}>
          {mobilsentry.db_name}
        </option>
      );
    });
    return options;
  };

  deviceTypeOptions = () => {
    let options = this.state.device_types.map(device_type => {
      return (
        <option key={device_type.device_type} value={device_type.device_type}>
          {device_type.device_type}
        </option>
      );
    });
    return options;
  };

  customerOptions = () => {
    let options = this.state.customers.map(customer => {
      return (
        <option key={customer.id} value={customer.id}>
          {customer.name}
        </option>
      );
    });
    return options;
  };

  displayDevices = () => {
    const { filteredDevices, deviceAuthAttempts } = this.state;
    let limitedDevices = this.limitDevices(filteredDevices);

    let deviceList = limitedDevices.map(device => {
      let customer = this.state.customers.filter(customer => customer.id === device.customer_id)[0];
      customer = customer || { name: 'no customer found' };
      let assignedApn = device.assigned_apn || { name: 'no apn found' };
      const dateCreated = moment(device.created_at).format('lll');
      let address = device.address || null;
      let latestAuth = '';
      let latestAuthDate = '';

      if (this.state.deviceAuthAttempts[device.id]) {
        latestAuth = this.state.deviceAuthAttempts[device.id].latestAuth;
        latestAuthDate = this.state.deviceAuthAttempts[device.id].latestAuthDate;
      }

      return (
        <Device
          key={device.id}
          id={device.id}
          apn={assignedApn}
          carrier={device.carrier}
          carrier_id={device.carrier_id}
          device_type={device.device_type}
          mobilsentry={device.mobilsentry}
          allMobilsentries={this.state.mobilsentries}
          service_number={device.service_number}
          address={address}
          name={device.name}
          username={device.username}
          password={device.password}
          email={device.email}
          customer={customer}
          allCustomers={this.state.customers}
          allCarriers={this.state.carriers}
          allApns={this.state.apns}
          allDeviceTypes={this.state.device_types}
          cycle_start_day={device.cycle_start_day}
          deleted={device.deleted}
          created_at={dateCreated}
          updated_at={device.updated_at}
          currentEdit={this.state.currentEdit}
          setCurrentEdit={this.setCurrentEdit}
          setLoadStatus={this.setLoadStatus}
          deviceAuthAttempts={deviceAuthAttempts}
          latestAuth={latestAuth}
          latestAuthDate={latestAuthDate}
        />
      );
    });

    return deviceList;
  };

  displayDevicesTable = () => {
    const { mouseHover, hoveredCategory, sortDescending } = this.state;

    return (
      <div className={`${styles.table}`}>
        <div className={styles.header}>
          <div className={styles.columnTitleRow}>
            <div
              className={miscStyles.hoverCursor}
              style={{ width: '12rem' }}
              onClick={() => this.handleSort('apn_name')}
              onMouseEnter={() => this.handleMouseEnter('apn_name')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              APN
              {utils.displayArrow('apn_name', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              style={{ width: '6rem' }}
              onClick={() => this.handleSort('carrier_name')}
              onMouseEnter={() => this.handleMouseEnter('carrier_name')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Carrier
              {utils.displayArrow('carrier_name', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              style={{ width: '12rem' }}
              onClick={() => this.handleSort('mobilsentry_name')}
              onMouseEnter={() => this.handleMouseEnter('mobilsentry')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              MobilSentry
              {utils.displayArrow('mobilsentry', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              style={{ width: '10rem' }}
              onClick={() => this.handleSort('service_number')}
              onMouseEnter={() => this.handleMouseEnter('service_number')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Service Number
              {utils.displayArrow('service_number', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div style={{ width: '12rem' }}>Status</div>
            <div
              className={miscStyles.hoverCursor}
              style={{ width: '8rem' }}
              onClick={() => this.handleSort('address')}
              onMouseEnter={() => this.handleMouseEnter('address')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              IP Address
              {utils.displayArrow('address', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              style={{ width: '8rem' }}
              onClick={() => this.handleSort('device_type')}
              onMouseEnter={() => this.handleMouseEnter('device_type')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Device Type
              {utils.displayArrow('device_type', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              style={{ width: '13rem' }}
              onClick={() => this.handleSort('customer_name')}
              onMouseEnter={() => this.handleMouseEnter('customer_name')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Customer
              {utils.displayArrow('customer_name', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              style={{ width: '15rem' }}
              onClick={() => this.handleSort('name')}
              onMouseEnter={() => this.handleMouseEnter('name')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Name
              {utils.displayArrow('name', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              style={{ width: '10rem' }}
              onClick={() => this.handleSort('username')}
              onMouseEnter={() => this.handleMouseEnter('username')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Username
              {utils.displayArrow('username', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              style={{ width: '8rem' }}
              onClick={() => this.handleSort('password')}
              onMouseEnter={() => this.handleMouseEnter('password')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Password
              {utils.displayArrow('password', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              style={{ width: '15rem' }}
              onClick={() => this.handleSort('email')}
              onMouseEnter={() => this.handleMouseEnter('email')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Email
              {utils.displayArrow('email', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div style={{ width: '7rem' }}>Config File</div>
            <div style={{ width: '5rem' }}></div>
          </div>

          <div className={styles.filterRow}>
            <div style={{ width: '12rem' }}>
              <select
                style={{ width: '80%' }}
                value={this.state.filterByApn}
                name='select-apn'
                id=''
                onChange={e => this.handleFilterBy(e.target.value, 'Apn')}
              >
                <option value='' defaultValue>
                  All
                </option>
                {this.apnOptions()}
              </select>
            </div>
            <div style={{ width: '6rem' }}>
              <select
                value={this.state.filterByCarrier}
                name='select-carrier'
                id=''
                onChange={e => this.handleFilterBy(e.target.value, 'Carrier')}
              >
                <option value='' defaultValue>
                  All
                </option>
                {this.carrierOptions()}
              </select>
            </div>
            <div style={{ width: '12rem' }}>
              <select
                style={{ width: '80%' }}
                value={this.state.filterByMobilsentry}
                name='select-mobilsentry'
                id=''
                onChange={e => this.handleFilterBy(e.target.value, 'Mobilsentry')}
              >
                <option value='' defaultValue>
                  All
                </option>
                {this.mobilsentryOptions()}
              </select>
            </div>
            <div style={{ width: '10rem' }}></div>
            <div style={{ width: '12rem' }}></div>
            <div style={{ width: '8rem' }}></div>
            <div style={{ width: '8rem' }}>
              <select
                value={this.state.filterByDeviceType}
                name='select-device-type'
                id=''
                onChange={e => this.handleFilterBy(e.target.value, 'DeviceType')}
              >
                <option value='' defaultValue>
                  All
                </option>
                {this.deviceTypeOptions()}
              </select>
            </div>
            <div style={{ width: '13rem' }}>
              <select
                style={{ width: '80%' }}
                value={this.state.filterByCustomer}
                name='select-customer'
                id=''
                onChange={e => this.handleFilterBy(e.target.value, 'Customer')}
              >
                <option value='' defaultValue>
                  All
                </option>
                {this.customerOptions()}
              </select>
            </div>
            <div style={{ width: '15rem' }}></div>
            <div style={{ width: '10rem' }}></div>
            <div style={{ width: '8rem' }}></div>
            <div style={{ width: '15rem' }}></div>
            <div style={{ width: '7rem' }}></div>
            <div style={{ width: '5rem' }}>
              <p
                className={`${styles.clearFiltersButton} ${miscStyles.hoverCursor}`}
                onClick={() => this.clearFilters()}
              >
                CLEAR
              </p>
            </div>
          </div>
        </div>

        <div className={styles.body}>{this.displayDevices()}</div>
      </div>
    );
  };

  addDeviceMenu = () => {
    const { customer, customerApns, new_carrier, apn, device_type, name, service_number } = this.state;
    let customerApnOptions = customerApns;

    return (
      <div
        className={
          this.state.displayAddMenu
            ? `${rightMenuStyles.rightMenu} ${rightMenuStyles.slideRightMenu}`
            : `${rightMenuStyles.rightMenu}`
        }
      >
        <div className={rightMenuStyles.addMenuInputs}>
          <div>
            <Typeahead
              className={rightMenuStyles.rightMenuTypeahead}
              options={this.state.customers ? this.state.customers : []}
              labelKey='name'
              id='add-device-customer-selector'
              onChange={customer => this.handleSelection('customer', customer[0])}
              placeholder={'Customer'}
              selectHintOnEnter={true}
              ref={ref => (this._typeahead3 = ref)}
            />
            <p className={rightMenuStyles.requiredStar}>*</p>
          </div>
          {this.state.customer && (
            <div>
              <Typeahead
                className={rightMenuStyles.rightMenuTypeahead}
                options={this.state.carriers ? this.state.carriers : []}
                labelKey='name'
                id='add-device-carrier-selector'
                onChange={carrier => this.handleSelection('new_carrier', carrier[0])}
                placeholder={'Select Carrier'}
                selectHintOnEnter={true}
                ref={ref => (this._typeahead1 = ref)}
              />
              <p className={rightMenuStyles.requiredStar}>*</p>
            </div>
          )}
          {customer && new_carrier && customerApns && (
            <div>
              <Typeahead
                className={rightMenuStyles.rightMenuTypeahead}
                options={this.state.apn ? [this.state.apn] : customerApnOptions}
                labelKey='name'
                id='add-device-apn-selector'
                onChange={apn => this.handleSelection('apn', apn[0])}
                placeholder={'Select APN'}
                selectHintOnEnter={true}
                ref={ref => (this._typeahead2 = ref)}
                selected={this.state.apn ? [this.state.apn] : []}
                disabled={this.state.apn ? true : false}
              />
              <p className={rightMenuStyles.requiredStar}>*</p>
            </div>
          )}
          {this.state.customer && this.state.new_carrier && (
            <div>
              <Typeahead
                className={rightMenuStyles.rightMenuTypeahead}
                options={this.state.device_types ? this.state.device_types : []}
                labelKey='device_type'
                id='add-device-device-type-selector'
                onChange={device_type => this.handleSelection('device_type', device_type[0])}
                placeholder={'Device Type'}
                selectHintOnEnter={true}
                ref={ref => (this._typeahead4 = ref)}
              />
              <p className={rightMenuStyles.requiredStar}>*</p>
            </div>
          )}
          {this.state.customer && this.state.new_carrier && (
            <>
              <div>
                <input
                  type='text'
                  value={this.state.name}
                  placeholder=' Name'
                  onChange={e => this.handleInput('name', e.target.value)}
                />
                <p className={rightMenuStyles.requiredStar}>*</p>
              </div>
              <div>
                <input
                  type='text'
                  value={this.state.service_number}
                  placeholder=' Service Number'
                  onChange={e => this.handleInput('service_number', e.target.value)}
                />
                <p className={rightMenuStyles.requiredStar}>*</p>
              </div>
              {this.state.new_carrier && this.state.new_carrier.id === 1 && (
                <>
                  <div>
                    <input
                      type='text'
                      value={this.state.username}
                      placeholder=' Username'
                      onChange={e => this.handleInput('username', e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type='text'
                      value={this.state.password}
                      placeholder=' Password'
                      onChange={e => this.handleInput('password', e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type='text'
                      value={this.state.address}
                      placeholder=' IP Address'
                      onChange={e => this.handleInput('address', e.target.value)}
                    />
                  </div>
                </>
              )}
              <div>
                <input
                  type='text'
                  value={this.state.email}
                  placeholder=' Email'
                  onChange={e => this.handleInput('email', e.target.value)}
                />
              </div>
            </>
          )}
          <div>
            <Button onClick={() => this.cancelAddDevice()} size='sm' style={{ margin: '1rem' }}>
              Cancel
            </Button>
            <Button
              onClick={() => this.addDevice()}
              size='sm'
              color='success'
              style={{ margin: '1rem' }}
              disabled={!customer || !new_carrier || !apn || !device_type || !name || !service_number}
            >
              Submit
            </Button>
          </div>
        </div>
        <div>
          <p style={{ color: 'white' }}>* Required</p>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={pageStyles.page}>
        <div className={pageStyles.header}>
          <p className={pageStyles.title}>Devices</p>
          <div className={pageStyles.buttonInputContainer}>
            {this.state.deviceAuthAttempts ? (
              <>
                <Tooltip title='Reconcile IP Addresses'>
                  <i
                    className={`${styles.reconcileDevicesButton} fas fa-exchange-alt`}
                    onClick={() => this.setState({ displayReconcileIPModal: true })}
                  ></i>
                </Tooltip>
                <Tooltip title='Export Devices'>
                  <i
                    className={`${styles.exportDevicesButton} fas fa-file-csv`}
                    style={{ marginRight: '0.5rem', fontSize: '1.1rem' }}
                    onClick={() => this.setState({ displayExportModal: true })}
                  ></i>
                </Tooltip>
              </>
            ) : (
              <div className={styles.exportButtonLoaderContainer}>
                <BeatLoader size={6} loading={true} color={'#16a2b8'} />
              </div>
            )}
            <div className={pageStyles.searchBox}>
              <input
                type='search'
                placeholder=' Search'
                ref={el => (this._deviceSearch = el)}
                onChange={e => this.handleSearch(e.target.value)}
              />
              <p onClick={() => this.clearSearch()}>&#10005;</p>
            </div>
            <Button onClick={() => this.toggleAddMenu()} size='sm'>
              Add Device
            </Button>
            <Button onClick={() => this.setState({ displayImportCSVModal: true })} size='sm' outline>
              Import
            </Button>
          </div>
        </div>

        <div className={pageStyles.body}>
          {this.state.isLoading ? (
            <div className={miscStyles.progressContainer}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className={styles.pageSelectionContainer}>
                <i className={`${miscStyles.hoverCursor} fas fa-angle-left`} onClick={this.decrementPage}></i>
                <span>{`Page ${this.state.page} of ${this.getMaxPage()}`}</span>
                <i className={`${miscStyles.hoverCursor} fas fa-angle-right`} onClick={this.incrementPage}></i>
              </div>
              <div className={styles.tableContainer}>{this.displayDevicesTable()}</div>
            </>
          )}

          {this.addDeviceMenu()}

          {this.state.displayImportCSVModal && !this.state.isLoading && (
            <ImportCSV
              apns={this.state.apns}
              carriers={this.state.carriers}
              customers={this.state.customers}
              devices={this.state.devices}
              closeImportCSVModal={this.closeImportCSVModal}
              deviceTypes={this.state.device_types}
            />
          )}

          {this.state.displayReconcileIPModal && !this.state.isLoading && (
            <ReconcileIPModal devices={this.state.devices} closeReconcileIPModal={this.closeReconcileIPModal} />
          )}

          {this.state.displayExportModal && !this.state.isLoading && (
            <ExportDevicesModal
              devices={this.state.devices}
              filteredDevices={this.state.filteredDevices}
              deviceAuthAttempts={this.state.deviceAuthAttempts}
              closeExportModal={this.closeExportModal}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = reduxState => reduxState;

export default connect(mapStateToProps, { updateDisplayStatus, updateErrorMessage, updateErrors })(DevicesPage);
