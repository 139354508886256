import React, { Component } from 'react';
import axios from 'axios';
import './App.scss';
import Header from './components/Header/Header';
import routes from './routes';
import GeneralMessageModal from './components/modals/GeneralMessageModal/GeneralMessageModal';
import LoginPage from './views/LoginPage/LoginPage';

import { connect } from 'react-redux';
import { updateDisplayStatus, updateErrorMessage, updateErrors } from './ducks/reducer';
import Swal from 'sweetalert2';

class App extends Component {
  state = {
    loggedIn: true,
    loginFail: false,
    user_name: '',
    password: '',
  };

  UNSAFE_componentWillMount = () => {
    let baseUrl = '/api';
    axios.defaults.baseURL = baseUrl;
    axios.defaults.withCredentials = true;

    axios.interceptors.response.use(
      response => {
        if (response.data && response.data.d) {
          response.data = response.data.d;
        }
        return response;
      },
      async error => {
        if (error.response && !error.config.skipErrorHandler) {
          let response = error.response;
          if (response.status === 403) {
            this.setState({ loggedIn: false });
            window.location.hash = '/login';
            return Promise.reject(response);
          } else if (response.status === 412) {
            this.props.updateDisplayStatus(true);
            this.props.updateErrorMessage('Someone else is editing this item. Please try again.');
            return Promise.reject(response);
          } else if (response.status === 400) {
            let errors = response.data.errors;
            if (this.state.loggedIn) {
              this.props.updateDisplayStatus(true);
              this.props.updateErrors(errors);
            }
            console.log(response);
            return Promise.reject(response);
          } else if (response.status === 500) {
            this.props.updateDisplayStatus(true);
            this.props.updateErrorMessage('Internal Server Error (500)');
            console.log(response);
            return Promise.reject(response);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
  };

  handleInput = (key, value) => {
    this.setState({ [key]: value });
  };

  handleLogin = async () => {
    const credentials = {
      user_name: this.state.user_name,
      password: this.state.password,
    };
    try {
      await axios.post('/login', credentials);
      Swal.fire({
        position: 'center',
        type: 'success',
        title: 'Logged in.',
        showConfirmButton: false,
        timer: 1000,
      }).then(() => {
        this.setState({ loggedIn: true }, () => {
          window.location.hash = '/customers';
        });
      });
    } catch (error) {
      this.setState({ loginFail: true });
      console.log(error);
    }
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleLogin();
    }
  };

  handleCloseModal = () => {
    this.props.updateDisplayStatus(false);
    this.props.updateErrorMessage('');
    this.props.updateErrors([]);
  };

  render() {
    return (
      <div className='App'>
        {this.state.loggedIn ? (
          <>
            <Header />
            {routes}
          </>
        ) : (
          <LoginPage
            handleInput={this.handleInput}
            handleLogin={this.handleLogin}
            handleKeyPress={this.handleKeyPress}
            loginFail={this.state.loginFail}
          />
        )}
        {this.props.displayMessageModal && this.state.loggedIn && (
          <>
            <GeneralMessageModal
              message={this.props.errorMessage}
              errors={this.props.errorsArr}
              handleCloseModal={this.handleCloseModal}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = reduxState => reduxState;

export default connect(mapStateToProps, { updateDisplayStatus, updateErrorMessage, updateErrors })(App);
