import axios from 'axios';

export class CategoryService {
  cache = {
    categories: {},
    services: {},
    serviceDomains: {},
  };

  getCategories = (params, forceReload) => {
    let paramsString = JSON.stringify(params);
    if (this.cache.categories[paramsString] && !forceReload) {
      return this.cache.categories[paramsString];
    }
    let options = { params: params };
    let retVal = axios.get('/categories', options).then(res => {
      this.cache.categories[paramsString] = retVal;
      return res;
    });
    return retVal;
  };

  getServices = (params, forceReload) => {
    let paramsString = JSON.stringify(params);
    if (this.cache.services[paramsString] && !forceReload) {
      return this.cache.services[paramsString];
    }
    let options = { params: params };
    let retVal = axios.get('/services', options).then(res => {
      this.cache.services[paramsString] = retVal;
      return res;
    });
    return retVal;
  };

  getServiceById = service_id => {
    let retVal = axios.get(`/services/${service_id}`).then(res => {
      return res;
    });
    return retVal;
  };

  addService = body => {
    return axios.post('/services', body).then(res => {
      this.clearCache();
      return res;
    });
  };

  editService = (service_id, body, config) => {
    return axios.put(`/services/${service_id}`, body, config).then(res => {
      this.clearCache();
      return res;
    });
  };

  getServiceDomains = (params, forceReload) => {
    let paramsString = JSON.stringify(params);
    if (this.cache.serviceDomains[paramsString] && !forceReload) {
      return this.cache.serviceDomains[paramsString];
    }
    let options = { params: params };
    let retVal = axios.get('/service_domains', options).then(res => {
      this.cache.serviceDomains[paramsString] = retVal;
      return res;
    });
    return retVal;
  };

  getServiceDomainById = id => {
    let retVal = axios.get(`/service_domains/${id}`).then(res => {
      return res;
    });
    return retVal;
  };

  addServiceDomain = body => {
    return axios.post('/service_domains', body).then(res => {
      this.clearCache();
      return res;
    });
  };

  editServiceDomain = (id, body, config) => {
    return axios.put(`/service_domains/${id}`, body, config).then(res => {
      this.clearCache();
      return res;
    });
  };

  deleteServiceDomain = (id, config) => {
    return axios.delete(`/service_domains/${id}`, config).then(res => {
      this.clearCache();
      return res;
    });
  };

  clearCache = () => {
    this.cache = {
      categories: {},
      services: {},
      serviceDomains: {},
    };
  };
}

export default new CategoryService();
