import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UsersPage from './views/UsersPage/UsersPage';
import CustomersPage from './views/CustomersPage/CustomersPage';
import DevicesPage from './views/DevicesPage/DevicesPage';
import ServicesPage from './views/ServicesPage/ServicesPage';
import CategorizationPage from './views/CategorizationPage/CategorizationPage';
import SubnetsPage from './views/SubnetsPage/SubnetsPage';
import AccountPage from './views/AccountPage/AccountPage';
import RadiusPage from './views/RadiusPage/RadiusPage';
import ReportsPage from './views/ReportsPage/ReportsPage';

export default (
  <Switch>
    <Route component={UsersPage} path='/users' />
    <Route component={CustomersPage} exact path='/customers' />
    <Route component={DevicesPage} path='/devices' />
    <Route component={ServicesPage} path='/services' />
    <Route component={CategorizationPage} path='/categorization' />
    <Route component={SubnetsPage} path='/subnets' />
    <Route component={UsersPage} path='/users' />
    <Route component={AccountPage} path='/account' />
    <Route component={RadiusPage} path='/radius/:service_number' />
    <Route component={ReportsPage} path='/reports' />

    {/* Catch all route... Defaults to Customers Page if user enters a different url than the ones above. */}
    <Route component={CustomersPage} />
  </Switch>
);
