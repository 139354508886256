import axios from 'axios';

export class ApnService {
  cache = {
    apns: {},
    customers: {},
  };

  getApns = (params, forceReload) => {
    let paramsString = JSON.stringify(params);
    if (this.cache.apns[paramsString] && !forceReload) {
      return this.cache.apns[paramsString];
    }
    let options = { params: params };
    let retVal = axios.get('/apns', options).then(res => {
      this.cache.apns[paramsString] = retVal;
      return res;
    });
    return retVal;
  };

  getApnsByCustomerId = (customer_id, params, forceReload) => {
    if (this.cache.customers[customer_id] && !forceReload) {
      return this.cache.customers[customer_id];
    }
    let options = { params: params };
    let retVal = axios.get(`/customers/${customer_id}/apns`, options).then(res => {
      this.cache.customers[customer_id] = retVal;
      return res;
    });
    return retVal;
  };

  addApn = (customer_id, addedApn) => {
    return axios.post(`/customers/${customer_id}/apns`, addedApn).then(res => {
      this.clearCache();
      return res;
    });
  };

  removeApn = (customer_id, apn_id) => {
    return axios.delete(`/customers/${customer_id}/apns/${apn_id}`).then(res => {
      this.clearCache();
      return res;
    });
  };

  clearCache = () => {
    this.cache = {
      apns: {},
      customers: {},
    };
  };
}

export default new ApnService();
