import React, { Component } from 'react';
import pageStyles from '../../styles/page.module.scss';
import tableStyles from '../../styles/table.module.scss';
import rightMenuStyles from '../../styles/rightMenu.module.scss';
import miscStyles from '../../styles/misc.module.scss';
import styles from './UsersPage.module.scss';
import User from './User/User';
import utils from '../../utils/utils';
import { formatRoleName } from './User/functions';
import UserService from '../../services/UserService';

import { connect } from 'react-redux';
import { updateDisplayStatus, updateErrorMessage, updateErrors } from '../../ducks/reducer';
import { Button } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

export class UsersPage extends Component {
  state = {
    users: [],
    allRoles: [],
    displayMenu: false,
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    userSearch: '',
    filterByRole: '',
    sortDescending: true,
    mouseHover: false,
    hoveredCategory: '',
    currentEdit: null,
    isLoading: true,
    isAdmin: false,
  };

  componentDidMount = async () => {
    try {
      let loggedInUser = await UserService.getCurrentUser();
      let loggedInUserRoles = loggedInUser.data.roles;
      let isAdmin = false;
      loggedInUserRoles.forEach(role => {
        if (role.id === 1 && role.name === 'role:Admin') {
          isAdmin = true;
        }
      });
      if (!isAdmin) {
        this.props.updateDisplayStatus(true);
        this.props.updateErrorMessage('You must be an Administrator to access Users');
        window.location.hash = '#';
        return;
      }
      await this.getUsers();
      await this.getAllRoles();
      await this.assignRolesToUser();
      this.setState({
        isLoading: false,
        loggedInUser: loggedInUser.data.id,
        isAdmin: isAdmin,
      });
    } catch (err) {
      console.log(err);
    }
  };

  getUsers = async (params, reload) => {
    let res = await UserService.getAllUsers(params, reload);
    this.setState({ users: res.data });
  };

  addUser = async () => {
    const { first_name, last_name, email, password } = this.state;
    if (!first_name || !last_name || !email || !password) {
      this.props.updateDisplayStatus(true);
      this.props.updateErrorMessage('Missing required field(s)..');
      return;
    }
    let body = { first_name, last_name, email, password };
    try {
      await UserService.addUser(body);
      await this.getUsers(undefined, true);
      await this.assignRolesToUser();
      this.setState({
        displayMenu: false,
        first_name: '',
        last_name: '',
        email: '',
        password: '',
      });
    } catch (err) {
      console.log(err.data.error);
    }
  };

  getAllRoles = async () => {
    let res = await UserService.getAllRoles();
    this.setState({ allRoles: res.data });
  };

  assignRolesToUser = async () => {
    let allRoles = this.state.allRoles;
    let promises = [];
    this.state.users.forEach(user => {
      promises.push(UserService.getRolesById(user.id));
    });
    let roles = await Promise.all(promises);
    let updatedUsers = this.state.users;
    updatedUsers.forEach((user, idx) => {
      let assignedRoles = roles[idx];
      let unassigned = [];
      allRoles.forEach(role => {
        let assigned = false;
        assignedRoles.data.forEach(aRole => {
          if (aRole.id === role.id) {
            assigned = true;
          }
        });
        if (!assigned) {
          unassigned.push(role);
        }
      });
      user.unassigned_roles = unassigned;
      user.assigned_roles = assignedRoles.data;
    });
    this.setState({ users: updatedUsers });
  };

  cancelAddUser = () => {
    this.setState({
      displayMenu: false,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
    });
  };

  clearFilters = () => {
    this.setState({ filterByRole: '' });
  };

  handleInput = async (key, value) => {
    this.setState({ [key]: value });
  };

  handleSearch = input => {
    this.setState({ userSearch: input });
  };

  handleSort = async property => {
    this.setState({ sortDescending: !this.state.sortDescending }, () => {
      let usersList = this.state.users;
      let sortDescending = this.state.sortDescending;
      let sorted = utils.handleSort(property, usersList, sortDescending);
      this.setState({ users: sorted });
    });
  };

  handleFilterByRole = id => {
    this.setState({ filterByRole: id });
  };

  handleMouseEnter = category => {
    this.setState({
      mouseHover: true,
      hoveredCategory: category,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      mouseHover: false,
      hoveredCategory: '',
    });
  };

  setCurrentEdit = id => {
    this.setState({ currentEdit: id });
  };

  clearSearch = () => {
    this._userSearch.value = '';
    this.setState({ userSearch: '' });
  };

  filterUsers = () => {
    const { users, userSearch, filterByRole } = this.state;
    let filteredUsers = [...users];
    let filterId = parseInt(filterByRole);
    if (filterByRole) {
      filteredUsers = filteredUsers.filter(user => {
        let roles = user.assigned_roles;
        let roleIds = roles.map(role => role.id);
        if (roleIds.includes(filterId)) {
          return true;
        } else return false;
      });
      return filteredUsers;
    }

    if (userSearch) {
      filteredUsers = filteredUsers.filter(user => {
        for (let key in user) {
          if (typeof user[key] === 'string') {
            if (user[key].toLowerCase().includes(userSearch.toLowerCase())) {
              return true;
            }
          } else if (typeof user[key] === 'number') {
            if (user[key].toString().includes(userSearch.toString())) {
              return true;
            }
          }
        }
        return false;
      });
    }
    return filteredUsers;
  };

  displayUserRows = () => {
    let users = this.filterUsers();
    let userRows = users.map(user => {
      let assigned_roles = user.assigned_roles || [];
      let unassigned_roles = user.unassigned_roles || [];
      return (
        <User
          key={user.id}
          user_id={user.id}
          first_name={user.first_name}
          last_name={user.last_name}
          email={user.email}
          assigned_roles={assigned_roles}
          unassigned_roles={unassigned_roles}
          loggedInUser={this.state.loggedInUser}
          isAdmin={this.state.isAdmin}
          getUsers={this.getUsers}
          deleteUser={this.deleteUser}
          currentEdit={this.state.currentEdit}
          setCurrentEdit={this.setCurrentEdit}
          getAllRoles={this.getAllRoles}
          assignRolesToUser={this.assignRolesToUser}
        />
      );
    });
    return userRows;
  };

  roleOptions = () => {
    let options = this.state.allRoles.map(role => {
      let name = formatRoleName(role.name);
      return (
        <option key={role.id} value={role.id}>
          {name}
        </option>
      );
    });
    return options;
  };

  usersTable = () => {
    return (
      <div className={`${tableStyles.table}`}>
        <div className={tableStyles.header}>
          <div className={tableStyles.columnTitleRow}>
            <div
              className={miscStyles.hoverCursor}
              onClick={() => this.handleSort('first_name')}
              onMouseEnter={() => this.handleMouseEnter('first_name')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              First Name
              {utils.displayArrow(
                'first_name',
                this.state.mouseHover,
                this.state.hoveredCategory,
                this.state.sortDescending
              )}
            </div>
            <div
              className={miscStyles.hoverCursor}
              onClick={() => this.handleSort('last_name')}
              onMouseEnter={() => this.handleMouseEnter('last_name')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Last Name
              {utils.displayArrow(
                'last_name',
                this.state.mouseHover,
                this.state.hoveredCategory,
                this.state.sortDescending
              )}
            </div>
            <div
              className={miscStyles.hoverCursor}
              style={{ flex: '2.5' }}
              onClick={() => this.handleSort('email')}
              onMouseEnter={() => this.handleMouseEnter('email')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Email
              {utils.displayArrow(
                'email',
                this.state.mouseHover,
                this.state.hoveredCategory,
                this.state.sortDescending
              )}
            </div>
            <div>Roles</div>
            <div></div>
          </div>

          <div className={tableStyles.filterRow}>
            <div></div>
            <div></div>
            <div style={{ flex: '2.5' }}></div>
            <div>
              <select
                value={this.state.filterByRole}
                name='role-filter'
                id='role-filter'
                onChange={e => this.handleFilterByRole(e.target.value)}
              >
                <option value='' defaultValue>
                  All Roles
                </option>
                {this.roleOptions()}
              </select>
            </div>
            <div
              className={`${tableStyles.clearFiltersButton} ${miscStyles.hoverCursor}`}
              onClick={() => this.clearFilters()}
            >
              CLEAR
            </div>
          </div>
        </div>

        <div className={tableStyles.body}>{this.displayUserRows()}</div>
      </div>
    );
  };

  addUserMenu = () => {
    const { first_name, last_name, email, password } = this.state;
    return (
      <div
        className={
          this.state.displayMenu
            ? `${rightMenuStyles.rightMenu} ${rightMenuStyles.slideRightMenu}`
            : `${rightMenuStyles.rightMenu}`
        }
        style={{ zIndex: '3' }}
      >
        <div className={rightMenuStyles.addMenuInputs}>
          <input
            value={this.state.first_name}
            type='text'
            placeholder=' First Name'
            onChange={e => this.handleInput('first_name', e.target.value)}
          />
          <input
            value={this.state.last_name}
            type='text'
            placeholder=' Last Name'
            onChange={e => this.handleInput('last_name', e.target.value)}
          />
          <input
            value={this.state.email}
            type='text'
            placeholder=' Email'
            onChange={e => this.handleInput('email', e.target.value)}
          />
          <input
            value={this.state.password}
            type='password'
            placeholder=' Password'
            onChange={e => this.handleInput('password', e.target.value)}
          />
          <div>
            <Button onClick={() => this.cancelAddUser()} style={{ margin: '1rem' }} size='sm'>
              Cancel
            </Button>
            <Button
              onClick={() => this.addUser()}
              disabled={!first_name || !last_name || !email || !password}
              style={{ margin: '1rem' }}
              color='success'
              size='sm'
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={pageStyles.page}>
        <div className={pageStyles.header}>
          <p className={pageStyles.title}>Users</p>
          <div className={pageStyles.buttonInputContainer}>
            <div className={pageStyles.searchBox}>
              <input
                type='search'
                placeholder=' Search'
                ref={el => (this._userSearch = el)}
                onChange={e => this.handleSearch(e.target.value)}
              />
              <p onClick={() => this.clearSearch()}>&#10005;</p>
            </div>
            <Button
              onClick={() => {
                this.setState({ displayMenu: true });
              }}
              color='secondary'
              size='sm'
            >
              Add User
            </Button>
          </div>
        </div>
        <div className={pageStyles.body}>
          {this.state.isLoading ? (
            <div className={miscStyles.progressContainer}>
              <CircularProgress />
            </div>
          ) : (
            <div className={styles.tableContainer}>{this.usersTable()}</div>
          )}
        </div>

        {this.addUserMenu()}
      </div>
    );
  }
}

const mapStateToProps = reduxState => reduxState;

export default connect(mapStateToProps, { updateDisplayStatus, updateErrorMessage, updateErrors })(UsersPage);
