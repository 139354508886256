import axios from 'axios';

export class UserService {
  cache = {
    users: {},
    roles: {},
    usersById: {},
    currentUser: null,
  };

  getAllUsers = (params, forceReload) => {
    let paramsString = JSON.stringify(params);
    if (this.cache.users[paramsString] && !forceReload) {
      return this.cache.users[paramsString];
    }
    let options = { params: params };
    let retVal = axios.get('/users', options).then(res => {
      this.cache.users[paramsString] = retVal;
      return res;
    });
    return retVal;
  };

  getUserById = user_id => {
    return axios.get(`/users/${user_id}`);
  };

  getCurrentUser = () => {
    if (this.cache.currentUser) {
      return this.cache.currentUser;
    }
    let retVal = axios.get('/whoami').then(res => {
      this.cache.currentUser = retVal;
      return res;
    });
    return retVal;
  };

  addUser = body => {
    return axios.post('/users', body);
  };

  editUser = (id, body, config) => {
    return axios.put(`/users/${id}`, body, config);
  };

  getAllRoles = (params, forceReload) => {
    let paramsString = JSON.stringify(params);
    if (this.cache.roles[paramsString] && !forceReload) {
      return this.cache.roles[paramsString];
    }
    let options = { params: params };
    let retVal = axios.get('/roles', options).then(res => {
      this.cache.roles[paramsString] = retVal;
      return res;
    });
    return retVal;
  };

  getRolesById = (user_id, params, forceReload) => {
    if (this.cache.usersById[user_id] && !forceReload) {
      return this.cache.usersById[user_id];
    }
    let options = { params: params };
    let retVal = axios.get(`/users/${user_id}/roles`, options).then(res => {
      this.cache.usersById[user_id] = retVal;
      return res;
    });
    return retVal;
  };

  addRole = (user_id, addedRole) => {
    return axios.post(`/users/${user_id}/roles`, addedRole);
  };

  removeRole = (user_id, role_id) => {
    return axios.delete(`/users/${user_id}/roles/${role_id}`);
  };

  clearCache = () => {
    this.cache = {
      users: {},
      roles: {},
      usersById: {},
      currentUser: null,
    };
  };
}

export default new UserService();
