import React, { useState, useEffect } from 'react';
import './ReportsPage.scss';
import CustomerService from '../../services/CustomerService';
import ElasticSearchService from '../../services/ElasticSearchService';
import DataUsageChart from './Charts/DataUsageChart';
import DeviceChart from './Charts/DeviceChart';

import moment from 'moment';
import BeatLoader from 'react-spinners/BeatLoader';
import BarLoader from 'react-spinners/BarLoader';

export const getAllCustomers = async () => {
  let res = await CustomerService.getAllCustomers();
  return res.data;
};

export const getAppDataOverTime = async params => {
  let res = await ElasticSearchService.getAppDataOverTime(params);
  return res.data.aggregations.app_data_over_time.buckets;
};

export const getCustomerDataOverTime = async params => {
  let res = await ElasticSearchService.getCustomerDataOverTime(params);
  return res.data.aggregations.customers.buckets;
};

export const formatAppDataForChart = appData => {
  appData = appData || [];
  let appUsageData = [
    {
      id: 'App',
      data: [],
    },
  ];
  let appDeviceData = [
    {
      id: 'App',
      data: [],
    },
  ];

  appData.forEach(entry => {
    const date = moment(new Date(entry.key_as_string)).format('MM-DD-YYYY');
    const deviceCount = entry.distinct_devices.value;
    const gigabytes = convertBytesToGigabytes(entry.bytes.value);

    appUsageData[0].data.push({
      x: date,
      y: gigabytes.toString(),
    });
    appDeviceData[0].data.push({
      x: date,
      y: deviceCount.toString(),
    });
  });

  return [appUsageData, appDeviceData];
};

export const formatCustomerDataForChart = (customerData, customers) => {
  customerData = customerData || [];
  let customerUsageData = [];
  let customerDeviceData = [];
  customerData.forEach(entry => {
    let customer = customers[entry.key] || { name: 'Unknown' };
    let usageData = {
      id: customer.name,
      data: [],
    };
    let deviceData = {
      id: customer.name,
      data: [],
    };

    entry.customer_data_over_time.buckets.forEach(bucket => {
      const date = moment(new Date(bucket.key_as_string)).format('MM-DD-YYYY');
      const deviceCount = bucket.distinct_devices.value;
      const gigabytes = convertBytesToGigabytes(bucket.bytes.value);

      usageData.data.push({
        x: date,
        y: gigabytes.toString(),
      });
      deviceData.data.push({
        x: date,
        y: deviceCount.toString(),
      });
    });

    customerUsageData.push(usageData);
    customerDeviceData.push(deviceData);
  });

  const sortedCustomerUsageData = customerUsageData.sort((a, b) => {
    if (a.id < b.id) return -1;
    else if (a.id > b.id) return 1;
    return 0;
  });
  const sortedCustomerDeviceData = customerDeviceData.sort((a, b) => {
    if (a.id < b.id) return -1;
    else if (a.id > b.id) return 1;
    return 0;
  });

  return [sortedCustomerUsageData, sortedCustomerDeviceData];
};

export const formatXAxisLabel = interval => {
  let intervalArr = interval.split('');
  intervalArr[0] = intervalArr[0].toUpperCase();
  return intervalArr.join('');
};

export const convertBytesToGigabytes = bytes => Math.round(bytes / 1024 / 1024 / 1024);

export default function ReportsPage() {
  const [appUsageData, setAppUsageData] = useState('');
  const [appDeviceData, setAppDeviceData] = useState('');
  const [customerUsageData, setCustomerUsageData] = useState('');
  const [customerDeviceData, setCustomerDeviceData] = useState('');
  const [selectedReport, setSelectedReport] = useState('Customer Data Usage');
  const [customerFilter, setCustomerFilter] = useState('');
  const [backstop, setBackstop] = useState({ length: 30, label: 'days', interval: 'Day' });
  const [displayBarLoader, setDisplayBarLoader] = useState(false);
  const [displayBeatLoader, setDisplayBeatLoader] = useState(true);

  const handleSelectInterval = (length, label, interval) => {
    if (displayBarLoader || displayBeatLoader) return;
    if (length !== backstop.length || label !== backstop.label || interval !== backstop.interval) {
      setDisplayBarLoader(true);
    }
    setBackstop({ length: length, label: label, interval: interval });
  };

  useEffect(() => {
    const params = {
      backstop: backstop,
    };

    const getData = async () => {
      try {
        const customers = await getAllCustomers();
        const customersObj = {};
        customers.forEach(customer => {
          customersObj[customer.id] = customer;
        });
        const appData = await getAppDataOverTime(params);
        const customerData = await getCustomerDataOverTime(params);
        const [appUsageData, appDeviceData] = formatAppDataForChart(appData);
        const [customerUsageData, customerDeviceData] = formatCustomerDataForChart(customerData, customersObj);

        setAppUsageData(appUsageData);
        setAppDeviceData(appDeviceData);
        setCustomerUsageData(customerUsageData);
        setCustomerDeviceData(customerDeviceData);
        setDisplayBarLoader(false);
        setDisplayBeatLoader(false);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, [backstop]);

  const { length, label, interval } = backstop;
  let filteredCustomerUsageData = [...customerUsageData].reverse();
  let filteredCustomerDeviceData = [...customerDeviceData].reverse();

  if (customerFilter) {
    filteredCustomerUsageData = filteredCustomerUsageData.filter(customer => customer.id === customerFilter);
    filteredCustomerDeviceData = filteredCustomerDeviceData.filter(customer => customer.id === customerFilter);
  }

  return (
    <div className='reports-page'>
      <div className='reports-page-left-menu'>
        <strong>Reports</strong>
        <div className='report-selection-container'>
          <div className='report-category-container'>
            <p className='report-category-title'>Application</p>
            <p
              className={selectedReport === 'Application Data Usage' ? 'selected-report report-title' : 'report-title'}
              onClick={() => setSelectedReport('Application Data Usage')}
            >
              Data Usage
            </p>
            <p
              className={
                selectedReport === 'Application Distinct Devices' ? 'selected-report report-title' : 'report-title'
              }
              onClick={() => setSelectedReport('Application Distinct Devices')}
            >
              Distinct Devices
            </p>
          </div>
          <div className='report-category-container'>
            <p className='report-category-title'>Customer</p>
            <p
              className={selectedReport === 'Customer Data Usage' ? 'selected-report report-title' : 'report-title'}
              onClick={() => setSelectedReport('Customer Data Usage')}
            >
              Data Usage
            </p>
            <p
              className={
                selectedReport === 'Customer Distinct Devices' ? 'selected-report report-title' : 'report-title'
              }
              onClick={() => setSelectedReport('Customer Distinct Devices')}
            >
              Distinct Devices
            </p>
          </div>
        </div>
      </div>
      <div className='report-container'>
        <div className='report-header'>
          <div className='report-header-left-container'>
            <p className='report-title'>{selectedReport}</p>
            <div className='timeframe-container'>
              <p
                className={length === 7 && label === 'days' ? 'selected-timeframe' : ''}
                onClick={() => handleSelectInterval(7, 'days', 'Day')}
              >
                7 Days
              </p>
              <p>|</p>
              <p
                className={length === 30 && label === 'days' ? 'selected-timeframe' : ''}
                onClick={() => handleSelectInterval(30, 'days', 'Day')}
              >
                30 Days
              </p>
              <p>|</p>
              <p
                className={length === 45 && label === 'days' ? 'selected-timeframe' : ''}
                onClick={() => handleSelectInterval(45, 'days', 'Day')}
              >
                45 Days
              </p>
              <p>|</p>
              <p
                className={length === 3 && label === 'months' ? 'selected-timeframe' : ''}
                onClick={() => handleSelectInterval(3, 'months', 'Week')}
              >
                3 Months
              </p>
              <p>|</p>
              <p
                className={length === 12 && label === 'months' ? 'selected-timeframe' : ''}
                onClick={() => handleSelectInterval(12, 'months', 'Month')}
              >
                12 Months
              </p>
            </div>
          </div>
          <div className='report-header-right-container'>
            {(selectedReport === 'Customer Data Usage' || selectedReport === 'Customer Distinct Devices') && (
              <select
                name='select-customer'
                id='select-customer'
                value={customerFilter}
                onChange={e => setCustomerFilter(e.target.value)}
              >
                <option value='' defaultValue>
                  All Customers
                </option>
                {customerUsageData &&
                  customerUsageData.map(customer => {
                    return (
                      <option key={customer.id} value={customer.id}>
                        {customer.id}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
        </div>
        <div className='report-content'>
          {displayBarLoader && (
            <div className='bar-loader-container'>
              <BarLoader height={4} width={'100%'} color='#f8990b' />
            </div>
          )}

          {displayBeatLoader && <BeatLoader size={15} loading={true} color={'#16a2b8'} />}

          {selectedReport === 'Application Data Usage' && appUsageData && (
            <DataUsageChart data={appUsageData} xAxisLabel={interval} colors={['#16a2b8']} />
          )}

          {selectedReport === 'Application Distinct Devices' && appDeviceData && (
            <DeviceChart data={appDeviceData} xAxisLabel={interval} colors={['#980043']} />
          )}

          {selectedReport === 'Customer Data Usage' && customerUsageData && filteredCustomerDeviceData.length !== 0 && (
            <DataUsageChart data={filteredCustomerUsageData} xAxisLabel={interval} colors={{ scheme: 'paired' }} />
          )}

          {selectedReport === 'Customer Distinct Devices' &&
            customerDeviceData &&
            filteredCustomerDeviceData.length !== 0 && (
              <DeviceChart data={filteredCustomerDeviceData} xAxisLabel={interval} colors={{ scheme: 'paired' }} />
            )}

          {selectedReport === 'Customer Data Usage' && customerFilter && filteredCustomerUsageData.length === 0 && (
            <i style={{ color: 'gray' }}>No History To Display</i>
          )}

          {selectedReport === 'Customer Distinct Devices' &&
            customerFilter &&
            filteredCustomerDeviceData.length === 0 && <i style={{ color: 'gray' }}>No History To Display</i>}
        </div>
      </div>
    </div>
  );
}
