import React, { Component } from 'react';
import axios from 'axios';
import styles from './Header.module.scss';
import UserService from '../../services/UserService';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

export class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      currentUser: {},
      userIsAdmin: false,
    };
  }

  componentDidMount = async () => {
    try {
      let loggedInUser = await UserService.getCurrentUser();
      let loggedInUserRoles = loggedInUser.data.roles;
      let isAdmin = false;
      loggedInUserRoles.forEach(role => {
        if (role.id === 1 && role.name === 'role:Admin') {
          isAdmin = true;
        }
      });
      this.setState({
        currentUser: loggedInUser.data,
        userIsAdmin: isAdmin,
      });
    } catch (err) {
      console.log(err);
    }
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleLogout = async () => {
    await axios.get('/logout');
    UserService.clearCache();
    Swal.fire({
      position: 'top-end',
      type: 'success',
      title: 'Logged out.',
      showConfirmButton: false,
      timer: 1000,
    }).then(() => {
      window.location.reload();
    });
  };

  render() {
    return (
      <div>
        <Navbar className={styles.navbar} light expand='md'>
          <NavbarBrand style={{ color: 'white', fontWeight: 'bold' }} href='/'>
            Mobilephire
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className='ml-auto' navbar>
              {this.state.userIsAdmin && (
                <NavItem>
                  <Link style={{ color: 'white', fontWeight: 'bold' }} to='/users' className='nav-link'>
                    Users
                  </Link>
                </NavItem>
              )}
              <NavItem>
                <Link style={{ color: 'white', fontWeight: 'bold' }} to='/customers' className='nav-link'>
                  Customers
                </Link>
              </NavItem>
              <NavItem>
                <Link style={{ color: 'white', fontWeight: 'bold' }} to='/devices' className='nav-link'>
                  Devices
                </Link>
              </NavItem>
              <NavItem>
                <Link style={{ color: 'white', fontWeight: 'bold' }} to='/subnets' className='nav-link'>
                  Subnets
                </Link>
              </NavItem>
              <NavItem>
                <Link style={{ color: 'white', fontWeight: 'bold' }} to='/services' className='nav-link'>
                  Services
                </Link>
              </NavItem>
              <NavItem>
                <Link style={{ color: 'white', fontWeight: 'bold' }} to='/categorization' className='nav-link'>
                  Categorization
                </Link>
              </NavItem>
              <NavItem>
                <Link style={{ color: 'white', fontWeight: 'bold' }} to='/reports' className='nav-link'>
                  Reports
                </Link>
              </NavItem>
              <UncontrolledDropdown nav inNavbar className='nav-button'>
                <DropdownToggle nav caret style={{ color: 'white', fontWeight: 'bold' }}>
                  {this.state.currentUser.first_name}
                </DropdownToggle>
                <DropdownMenu right>
                  <Link to='/account' style={{ textDecoration: 'none' }}>
                    <DropdownItem>Account</DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => this.handleLogout()}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(Header);
