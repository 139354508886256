import React, { Component } from 'react';
import './PopoverBox.scss';

import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

export default class PopoverBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
    };
  }

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  render() {
    const { title, body, target, placement, trigger } = this.props;
    return (
      <>
        <Popover
          boundariesElement='window'
          placement={placement}
          isOpen={this.state.popoverOpen}
          target={target}
          toggle={this.toggle}
          trigger={trigger}
        >
          <PopoverHeader>{title}</PopoverHeader>
          <PopoverBody>{body}</PopoverBody>
        </Popover>
      </>
    );
  }
}
