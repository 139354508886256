import axios from 'axios';

export class DeviceService {
  cache = {
    devices: {},
    devicesById: {},
  };

  getDevices = (params, forceReload) => {
    let paramsString = JSON.stringify(params);
    if (this.cache.devices[paramsString] && !forceReload) {
      return this.cache.devices[paramsString];
    }
    let options = { params: params };
    let retVal = axios.get('/devices', options).then(res => {
      this.cache.devices[paramsString] = retVal;
      return res;
    });
    return retVal;
  };

  getDeviceById = (device_id, params, forceReload) => {
    if (this.cache.devicesById[device_id] && !forceReload) {
      return this.cache.devicesById[device_id];
    }
    let options = { params: params };
    let retVal = axios.get(`/devices/${device_id}`, options).then(res => {
      this.cache.devicesById[device_id] = retVal;
      return res;
    });
    return retVal;
  };

  addDevice = (body, config) => {
    return axios.post('/devices', body, config).then(res => {
      this.clearCache();
      return res;
    });
  };

  editDevice = (device_id, body, config) => {
    return axios.put(`/devices/${device_id}`, body, config).then(res => {
      this.clearCache();
      return res;
    });
  };

  sendConfigFile = body => axios.post('/shared_mobile_configs', body);

  clearCache = () => {
    this.cache = {
      devices: {},
      devicesById: {},
    };
  };
}

export default new DeviceService();
