import React, { useState, useEffect } from 'react';
import styles from './RadiusPage.module.scss';
import pageStyles from '../../styles/page.module.scss';
import miscStyles from '../../styles/misc.module.scss';
import ElasticSearchService from '../../services/ElasticSearchService';
import utils from '../../utils/utils';

import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment-duration-format';
import CircularProgress from '@material-ui/core/CircularProgress';

export const getAuthAttempts = serviceNumber => {
  const params = {
    phone: serviceNumber,
  };
  return ElasticSearchService.getAuthAttempts(params)
    .then(res => {
      return res.data.hits.hits;
    })
    .catch(err => console.log(err));
};

export const getUniqueSessions = serviceNumber => {
  const params = {
    phone: serviceNumber,
  };
  return ElasticSearchService.getSessions(params)
    .then(res => {
      const hits = res.data.hits.hits;
      let sessions = {};
      let sessionOrder = [];
      hits.forEach(hit => {
        const session = hit._source;
        if (!sessions[session.session_id]) {
          sessions[session.session_id] = [];
          sessionOrder.push(session.session_id);
        }
        sessions[session.session_id].push(session);
      });
      let uniqueSessions = sessionOrder.map(id => {
        const latestRecord = sessions[id][0];
        const firstRecord = sessions[id][sessions[id].length - 1];
        const sessionStart = firstRecord.event_timestamp;
        latestRecord.session_start = sessionStart;
        return latestRecord;
      });

      return uniqueSessions;
    })
    .catch(err => console.log(err));
};

export const formatDate = dateObj => {
  const year = dateObj.getFullYear().toString().split('').slice(2).join('');
  let month = dateObj.getMonth() + 1;
  let day = dateObj.getDate();
  let hour = dateObj.getHours();
  let minutes = dateObj.getMinutes();
  const daytime = hour < 12 ? 'am' : 'pm';

  // if (month < 10) {
  //    month = `0${month}`;
  // }

  if (day < 10) {
    day = `0${day}`;
  }

  if (hour > 12) {
    hour = hour - 12;
  }

  if (hour === 0) {
    hour = 12;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${month}/${day}/${year} at ${hour}:${minutes}${daytime}`;
};

export const formatDuration = duration => {
  if (duration.split(':').length === 2) {
    return `00:${duration}`;
  } else if (duration.split(':').length === 1) {
    return `00:00:${duration}`;
  } else {
    return duration;
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export default function RadiusPage(props) {
  const [authAttempts, setAuthAttempts] = useState('');
  const [sessions, setSessions] = useState('');

  useEffect(() => {
    (async function () {
      const serviceNumber = props.match.params.service_number;
      let authAttempts = await getAuthAttempts(serviceNumber);
      let sessions = await getUniqueSessions(serviceNumber);
      setAuthAttempts(authAttempts);
      setSessions(sessions);
    })();
  }, []);

  const formattedServiceNum = utils.readableServiceNum(props.match.params.service_number.toString());

  const displayAuthAttempts = () => {
    let authAttemptRows = authAttempts.map(auth => {
      const authAttempt = auth._source;
      let authdate = new Date(authAttempt.authdate);
      const authAttemptDateTime = formatDate(authdate);

      const usernameMatch = authAttempt.username === authAttempt.device_username;
      const passwordMatch = authAttempt.password === authAttempt.device_password;

      return (
        <div className={styles.authAttemptsRow} key={authAttempt.authdate}>
          <span className={styles.authAttemptDate}>{authAttemptDateTime}</span>
          {authAttempt.reply === 'Access-Accept' ? (
            <i className={`fas fa-check ${styles.authAttemptStatus}`} style={{ color: 'green' }}></i>
          ) : (
            <i className={`fas fa-times ${styles.authAttemptStatus}`} style={{ color: 'crimson' }}></i>
          )}
          <span className={styles.authAttemptedUsername} style={{ color: usernameMatch ? 'green' : 'crimson' }}>
            {authAttempt.username}
          </span>
          <span className={styles.authDeviceUsername}>{authAttempt.device_username}</span>
          <span className={styles.authAttemptedPassword} style={{ color: passwordMatch ? 'green' : 'crimson' }}>
            {authAttempt.password}
          </span>
          <span className={styles.authDevicePassword}>{authAttempt.device_password}</span>
        </div>
      );
    });

    return authAttemptRows;
  };

  const displaySessions = () => {
    let sessionRows = sessions.map(session => {
      const startTime = new Date(session.session_start);
      const sessionDateTime = formatDate(startTime);
      const duration = formatDuration(moment.duration(session.duration, 'seconds').format('hh:mm:ss'));

      const dataIn = formatBytes(session.bytes_in);
      const dataOut = formatBytes(session.bytes_out);

      let terminationCause;
      if (session.termination_cause === 'User-Request') {
        terminationCause = 'User';
      } else terminationCause = '-';

      return (
        <div key={startTime} className={styles.sessionRow}>
          <span className={styles.sessionStart}>{sessionDateTime}</span>
          <span className={styles.sessionDuration}>{duration} hrs</span>
          <span className={styles.sessionTransferredData}>
            {dataIn.toLocaleString()} | {dataOut.toLocaleString()}
          </span>
          <span className={styles.sessionTermination}>{terminationCause}</span>
        </div>
      );
    });

    return sessionRows;
  };

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.header}>
        <div className={styles.headerContainer}>
          <p className={styles.headerServiceNum}>{formattedServiceNum}</p>
          <Link to='/devices'>
            <p className={styles.backButton}>BACK</p>
          </Link>
        </div>
      </div>
      <div className={pageStyles.body}>
        <div className={styles.panelContainer}>
          <div className={styles.panel}>
            <div className={styles.panelHeader}>
              <p>Authentication Activity</p>
            </div>
            <div className={styles.authAttemptsHeaderRow}>
              <span className={styles.authAttemptDate}>Date / Time</span>
              <span className={styles.authAttemptStatus}>Status</span>
              <span className={styles.authAttemptedUsername}>Attempted Username</span>
              <span className={styles.authDeviceUsername}>Device Username</span>
              <span className={styles.authAttemptedPassword}>Attempted Password</span>
              <span className={styles.authDevicePassword}>Device Password</span>
            </div>
            <div className={styles.authAttemptsContainer}>
              {authAttempts ? (
                displayAuthAttempts()
              ) : (
                <div className={miscStyles.progressContainer}>
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
          <div className={styles.panel}>
            <div className={styles.panelHeader}>
              <p>Session History</p>
            </div>
            <div className={styles.sessionHeaderRow}>
              <span className={styles.sessionStart}>Started</span>
              <span className={styles.sessionDuration}>Duration</span>
              <div className={styles.sessionTransferredData}>
                <span>Data</span>
                <i className='fas fa-arrow-down'></i>
                <span>|</span>
                <span>Data</span>
                <i className='fas fa-arrow-up'></i>
              </div>
              <span className={styles.sessionTermination}>Termination</span>
            </div>
            <div className={styles.sessionsContainer}>
              {sessions ? (
                displaySessions()
              ) : (
                <div className={miscStyles.progressContainer}>
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
