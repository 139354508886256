import React, { Component } from 'react';
import styles from './CategorizationPage.module.scss';
import pageStyles from '../../styles/page.module.scss';
import miscStyles from '../../styles/misc.module.scss';
import CustomerService from '../../services/CustomerService';
import CategoryService from '../../services/CategoryService';
import DeviceService from '../../services/DeviceService';
import ElasticSearchService from '../../services/ElasticSearchService';
import utils from '../../utils/utils';
import PopoverBox from '../../components/PopoverBox/PopoverBox';

import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from 'reactstrap';

export class CategorizationPage extends Component {
  state = {
    categories: {},
    customers: {},
    devices: {},
    services: {},
    serviceDomains: {},
    selectedCustomer: 'none',
    selectedDevice: 'none',
    selectedDomain: 'none',
    selectedCategory: null,
    selectedService: null,
    allCustomerUsage: {},
    allDeviceUsage: {},
    allDomainUsage: {},
    uncategorizedCustomerUsage: [],
    uncategorizedDeviceUsage: [],
    uncategorizedDomainUsage: [],
    deviceUsageLoading: false,
    domainUsageLoading: false,
    currentEditDomain: null,
    newCategoryId: '',
    newServiceId: '',
    newDomainName: '',
    similarDomainNames: [],
    displayNewServiceInput: false,
    createNewServiceName: '',
    customerSearch: '',
    deviceSearch: '',
    domainSearch: '',
  };

  componentDidMount = async () => {
    try {
      await this.getCategories();
      await this.getCustomers();
      await this.getDevices();
      await this.getServices();
      await this.getServiceDomains();
      this.getCustomerUsage();
    } catch (err) {
      console.log(err);
    }
  };

  getCategories = async () => {
    let res = await CategoryService.getCategories();
    let categoriesObj = {};
    res.data.forEach(category => {
      categoriesObj[category.id] = category;
    });
    this.setState({ categories: categoriesObj });
  };

  getCustomers = async () => {
    let res = await CustomerService.getAllCustomers();
    let customersObj = {};
    res.data.forEach(customer => {
      customersObj[customer.id] = customer;
    });
    this.setState({ customers: customersObj });
  };

  getDevices = async () => {
    let res = await DeviceService.getDevices();
    let devicesObj = {};
    res.data.forEach(device => {
      devicesObj[device.address] = device;
    });
    this.setState({ devices: devicesObj });
  };

  getServices = async () => {
    let res = await CategoryService.getServices();
    let servicesObj = {};
    res.data.forEach(service => {
      servicesObj[service.id] = service;
    });
    this.setState({ services: servicesObj });
  };

  getServiceDomains = async (params, forceReload) => {
    let res = await CategoryService.getServiceDomains(params, forceReload);
    let serviceDomainObj = {};
    res.data.forEach(serviceDomain => {
      serviceDomainObj[serviceDomain.id] = serviceDomain;
    });
    this.setState({ serviceDomains: serviceDomainObj });
  };

  getCustomerUsage = () => {
    const { customers } = this.state;

    let allUsageByCustomer = ElasticSearchService.getUsage({
      field: 'customer_id',
      uncategorized: false,
    }).then(res => res.data.aggregations.usage.buckets);

    let uncategorizedByCustomer = ElasticSearchService.getUsage({
      field: 'customer_id',
      uncategorized: true,
    }).then(res => res.data.aggregations.usage.buckets);

    return Promise.all([allUsageByCustomer, uncategorizedByCustomer]).then(res => {
      let allUsageObj = {};
      let totalUsage = 0;
      let totalUncategorizedUsage = 0;

      res[0].forEach(customerUsage => {
        if (customers[customerUsage.key]) {
          let bytes = customerUsage.bytes.value;
          totalUsage += bytes;
          allUsageObj[customerUsage.key] = customerUsage;
        }
      });

      let filteredUncategorized = res[1].filter(uncategorizedUsage => {
        if (!customers[uncategorizedUsage.key]) {
          return false;
        } else {
          let bytes = uncategorizedUsage.bytes.value;
          totalUncategorizedUsage += bytes;
          return true;
        }
      });

      allUsageObj.totalUsage = this.convertToMegabytes(totalUsage);
      allUsageObj.totalUncategorizedUsage = this.convertToMegabytes(totalUncategorizedUsage);

      this.setState({
        allCustomerUsage: allUsageObj,
        uncategorizedCustomerUsage: filteredUncategorized,
      });
    });
  };

  getDeviceUsage = () => {
    const { selectedCustomer, devices } = this.state;
    let customerId = selectedCustomer;

    let allUsageByDevice = ElasticSearchService.getUsage({
      field: 'device_address',
      customerId: customerId,
      uncategorized: false,
    }).then(res => res.data.aggregations.usage.buckets);

    let uncategorizedByDevice = ElasticSearchService.getUsage({
      field: 'device_address',
      customerId: customerId,
      uncategorized: true,
    }).then(res => res.data.aggregations.usage.buckets);

    this.setState({ deviceUsageLoading: true });
    return Promise.all([allUsageByDevice, uncategorizedByDevice])
      .then(res => {
        let allUsageObj = {};
        let totalUsage = 0;
        let totalUncategorizedUsage = 0;
        let unknownDeviceUsage = 0;

        res[0].forEach(deviceUsage => {
          let bytes = deviceUsage.bytes.value;
          if (devices[deviceUsage.key]) {
            totalUsage += bytes;
            allUsageObj[deviceUsage.key] = deviceUsage;
          }
        });

        let uncategorizedArr = res[1].filter(uncategorizedUsage => {
          let bytes = uncategorizedUsage.bytes.value;
          if (!devices[uncategorizedUsage.key]) {
            unknownDeviceUsage += bytes;
            return false;
          } else {
            let device = this.state.devices[uncategorizedUsage.key];
            totalUncategorizedUsage += bytes;
            return Object.assign(uncategorizedUsage, device);
          }
        });

        allUsageObj.totalUsage = this.convertToMegabytes(totalUsage);
        allUsageObj.totalUncategorizedUsage = this.convertToMegabytes(totalUncategorizedUsage);
        allUsageObj.totalUnknownDeviceUsage = this.convertToMegabytes(unknownDeviceUsage);
        this.setState({
          allDeviceUsage: allUsageObj,
          uncategorizedDeviceUsage: uncategorizedArr,
        });
      })
      .finally(() => {
        this.setState({ deviceUsageLoading: false });
      });
  };

  getDomainUsage = () => {
    const { selectedCustomer, selectedDevice, devices } = this.state;

    let device = devices[selectedDevice];
    let customerId = selectedCustomer;
    let deviceId = null;

    if (selectedCustomer === 'all') {
      customerId = null;
    }

    if (selectedDevice === 'all' || selectedDevice === 'unknown') {
      deviceId = null;
    }

    if (device) {
      deviceId = device.id;
    }

    let allUsageByDomain = ElasticSearchService.getUsage({
      field: 'reversed_domain',
      uncategorized: false,
      customerId: customerId,
      deviceId: deviceId,
    }).then(res => res.data.aggregations.usage.buckets);

    let uncategorizedByDomain = ElasticSearchService.getUsage({
      field: 'reversed_domain',
      uncategorized: true,
      customerId: customerId,
      deviceId: deviceId,
    }).then(res => res.data.aggregations.usage.buckets);

    this.setState({ domainUsageLoading: true });
    return Promise.all([allUsageByDomain, uncategorizedByDomain])
      .then(res => {
        let allUsageObj = {};
        res[0].forEach(domainUsage => {
          allUsageObj[domainUsage.key] = domainUsage;
        });
        this.setState({
          allDomainUsage: allUsageObj,
          uncategorizedDomainUsage: res[1],
        });
      })
      .finally(() => {
        this.setState({ domainUsageLoading: false });
      });
  };

  handleSelection = (key, value) => {
    if (key === 'selectedCustomer' && value != this.state.selectedCustomer) {
      this.setState(
        { selectedDevice: 'none', uncategorizedDeviceUsage: [], uncategorizedDomainUsage: [], [key]: value },
        () => {
          this.getDeviceUsage();
          return;
        }
      );
    } else if (key === 'selectedDevice' && value != this.state.selectedDevice) {
      if (value === 'unknown') {
        this.setState({ selectedDomain: 'none', uncategorizedDomainUsage: [] }, () => {
          this.getDomainUsage();
          return;
        });
      } else {
        this.setState({ uncategorizedDomainUsage: [], [key]: value }, () => {
          this.getDomainUsage();
          return;
        });
      }
    } else if (key === 'newCategoryId') {
      this.setState({ newServiceId: '' });
    }

    if (value === 'all') {
      this.setState({ selectedDomain: 'none' });
    }

    this.setState({ [key]: value });
  };

  handleSelectDomain = event => {
    const { similarDomainNames } = this.state;
    let newDomainsList = Array.from(similarDomainNames);

    if (event.target.checked) {
      newDomainsList.push(event.target.value);
    } else {
      newDomainsList.splice(newDomainsList.indexOf(event.target.value), 1);
    }

    this.setState({
      similarDomainNames: newDomainsList,
    });
  };

  handleSelectAllDomains = (event, similarDomains) => {
    if (event.target.checked) {
      let names = similarDomains.map(domain => domain.key.split('').reverse().join(''));
      this.setState({ similarDomainNames: names });
    } else {
      this.setState({ similarDomainNames: [] });
    }
  };

  handleSearch = (key, value) => {
    this.setState({ [key]: value });
  };

  clearSearch = ref => {
    let refString = `_${ref}`;
    this[refString].value = '';
    this.setState({ [ref]: '' });
  };

  clearAllSearches = () => {
    this._customerSearch.value = '';
    this._deviceSearch.value = '';
    this._domainSearch.value = '';
    this.setState({
      customerSearch: '',
      deviceSearch: '',
      domainSearch: '',
    });
  };

  convertToMegabytes = bytes => Math.round(bytes / 1024 / 1024);

  getPercentage = (partialValue, totalValue) => {
    let percent = (partialValue * 100) / totalValue;
    if (percent) {
      percent = Math.floor(percent);
      return percent.toString().padStart(2, 0);
    }
  };

  formatToken = name => {
    name = name.toLowerCase();
    function isAlphaNumeric(char) {
      let code = char.charCodeAt(0);
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 96 && code < 123)
      ) {
        // lower alpha (a-z)
        return false;
      } else return true;
    }

    let formatted = name
      .split('')
      .map(char => {
        if (isAlphaNumeric(char)) {
          return char;
        } else if (char === ' ') {
          return '-';
        } else if (char === '-') {
          return char;
        } else return '';
      })
      .join('');
    return formatted;
  };

  categorizeDomains = async () => {
    const { newServiceId, newDomainName, similarDomainNames, createNewServiceName, newCategoryId } = this.state;

    let serviceId = newServiceId;
    let allDomainNames = [newDomainName].concat(similarDomainNames);

    if (createNewServiceName) {
      let token = this.formatToken(createNewServiceName);
      let body = {
        name: createNewServiceName,
        token: token,
        category_id: newCategoryId,
      };

      try {
        let res = await CategoryService.addService(body);
        serviceId = res.data.id;
      } catch (err) {
        console.log(err);
      }
    }

    let promises = allDomainNames.map(domain => {
      const body = {
        service_id: serviceId,
        domain: domain,
      };

      return CategoryService.addServiceDomain(body);
    });

    await Promise.all(promises).then(() => {
      this.setState({
        currentEditDomain: null,
        newCategoryId: '',
        newServiceId: '',
        newDomainName: '',
        similarDomainNames: [],
        displayNewServiceInput: false,
        createNewServiceName: '',
      });
    });
  };

  cancelCategorizeDomains = () => {
    this.setState({
      currentEditDomain: null,
      newCategoryId: '',
      newServiceId: '',
      newDomainName: '',
      similarDomainNames: [],
      displayNewServiceInput: false,
      createNewServiceName: '',
    });
  };

  getFirstLevelDomain = fullDomain => {
    let domainArr = fullDomain.split('.');
    let firstLevelDomain = domainArr[domainArr.length - 2];
    return firstLevelDomain;
  };

  findSimilarDomains = fullDomain => {
    const { uncategorizedDomainUsage } = this.state;
    let firstLevelDomain = this.getFirstLevelDomain(fullDomain);
    let matches = uncategorizedDomainUsage.filter(usage => {
      let domain = usage.key.split('').reverse().join('');
      return this.getFirstLevelDomain(domain).includes(firstLevelDomain) && fullDomain !== domain;
    });
    return matches;
  };

  filterCustomersObj = (customersObj, search) => {
    let customersArr = [];
    let newCustomersObj = {};

    for (let key in customersObj) {
      customersArr.push(customersObj[key]);
    }

    let filtered = customersArr.filter(customer => {
      if (customer.name.toLowerCase().includes(search.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    });

    filtered.forEach(obj => {
      newCustomersObj[obj.id] = obj;
    });

    return newCustomersObj;
  };

  filterDevicesByCustomer = customerId => {
    const { uncategorizedDeviceUsage } = this.state;

    if (customerId === 'all') {
      return uncategorizedDeviceUsage;
    } else {
      let filteredDevices = uncategorizedDeviceUsage.filter(device => device.customer_id === customerId);
      return filteredDevices;
    }
  };

  filterDevicesBySearch = (devices, search) => {
    let filtered = devices.filter(device => {
      if (typeof search === 'string') {
        // stripping non alphanumeric characters
        let formattedSearch = search.replace(/[^0-9a-z]/gi, '');
        let formattedAddress = device.address.replace(/[^0-9a-z]/gi, '');
        let formattedServiceNum = device.service_number.replace(/[^0-9a-z]/gi, '');
        if (
          device.name.toLowerCase().includes(formattedSearch.toLowerCase()) ||
          formattedAddress.includes(formattedSearch) ||
          formattedServiceNum.includes(formattedSearch)
        ) {
          return true;
        }
      }
      return false;
    });
    return filtered;
  };

  filterDomainsBySearch = (domains, search) => {
    let filtered = domains.filter(domain => {
      if (typeof search === 'string') {
        let domainName = domain.key.split('').reverse().join('');
        if (domainName.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
    return filtered;
  };

  displayCustomerList = () => {
    const { customers, uncategorizedCustomerUsage, allCustomerUsage, customerSearch } = this.state;

    let filteredCustomers = this.filterCustomersObj(customers, customerSearch);

    let filteredUncategorizedUsage = uncategorizedCustomerUsage.sort((a, b) => {
      return b.bytes.value - a.bytes.value;
    });
    let allPercentUncategorized = this.getPercentage(
      allCustomerUsage.totalUncategorizedUsage,
      allCustomerUsage.totalUsage
    );

    let knownCustomerUncategorizedUsage = filteredUncategorizedUsage.filter(
      customerUsage => filteredCustomers[customerUsage.key]
    );

    let uncategorizedCustomersArr = knownCustomerUncategorizedUsage.map(customerUsage => {
      let customer = filteredCustomers[customerUsage.key];
      let totalCustomerUsage = this.convertToMegabytes(allCustomerUsage[customerUsage.key].bytes.value);
      let uncategorizedCustomerUsage = this.convertToMegabytes(customerUsage.bytes.value);
      let percentUncategorized = this.getPercentage(uncategorizedCustomerUsage, totalCustomerUsage);

      if (!percentUncategorized) {
        percentUncategorized = '0';
      }

      return (
        <div
          key={customerUsage.key}
          className={
            this.state.selectedCustomer === customerUsage.key
              ? `${styles.selectedCategorizationItem} ${styles.categorizationListItem}`
              : `${styles.categorizationListItem}`
          }
          onClick={() => this.handleSelection('selectedCustomer', customerUsage.key)}
        >
          <p>{customer.name}</p>
          <div className={styles.usageContainer}>
            <p>{uncategorizedCustomerUsage > 1 ? uncategorizedCustomerUsage.toLocaleString() : '< 1'} MB</p>
            <p>|</p>
            <p>{percentUncategorized}%</p>
          </div>
        </div>
      );
    });
    let allCustomersRow = [
      <div
        key={0}
        style={{ borderBottom: '1px solid lightgray' }}
        className={
          this.state.selectedCustomer === 'all'
            ? `${styles.selectedCategorizationItem} ${styles.categorizationListItem}`
            : `${styles.categorizationListItem}`
        }
        onClick={() => this.handleSelection('selectedCustomer', 'all')}
      >
        <p>All</p>
        <div className={styles.usageContainer}>
          <p>{allCustomerUsage.totalUncategorizedUsage.toLocaleString()} MB</p>
          <p>|</p>
          <p>{allPercentUncategorized}%</p>
        </div>
      </div>,
    ];

    return allCustomersRow.concat(uncategorizedCustomersArr);
  };

  displayDeviceList = () => {
    const { selectedCustomer, allCustomerUsage, uncategorizedCustomerUsage, allDeviceUsage, deviceSearch } = this.state;
    let deviceArr = this.filterDevicesByCustomer(selectedCustomer).sort((a, b) => {
      return b.bytes.value - a.bytes.value;
    });

    // let allPercentUncategorized = this.getPercentage(allDeviceUsage.totalUncategorizedUsage, allDeviceUsage.totalUsage);
    let knownDeviceUsageSum = this.convertToMegabytes(
      deviceArr.reduce((total, deviceUsage) => {
        return total + deviceUsage.bytes.value;
      }, 0)
    );
    let unknownDeviceUsage = 0;

    if (selectedCustomer === 'all') {
      unknownDeviceUsage = allCustomerUsage.totalUncategorizedUsage - allDeviceUsage.totalUncategorizedUsage;
    } else if (selectedCustomer > 0) {
      let customer = uncategorizedCustomerUsage.filter(customer => customer.key === selectedCustomer);
      let totalCustomerUsage = this.convertToMegabytes(customer[0].bytes.value);
      unknownDeviceUsage = totalCustomerUsage - knownDeviceUsageSum;
    }

    let allDeviceUsageSum = knownDeviceUsageSum + unknownDeviceUsage; // should be equal to total uncategorized usage for customer(s)

    let filteredDevices = this.filterDevicesBySearch(deviceArr, deviceSearch);

    const filteredDeviceArr = filteredDevices.map(deviceUsage => {
      let formattedServiceNum = utils.readableServiceNum(deviceUsage.service_number);
      let totalDeviceUsage;
      if (allDeviceUsage[deviceUsage.key]) {
        totalDeviceUsage = this.convertToMegabytes(allDeviceUsage[deviceUsage.key].bytes.value);
      }
      let uncategorizedDeviceUsage = this.convertToMegabytes(deviceUsage.bytes.value);
      let percentUncategorized = this.getPercentage(uncategorizedDeviceUsage, totalDeviceUsage);

      return (
        <div
          key={deviceUsage.key}
          className={
            this.state.selectedDevice === deviceUsage.key
              ? `${styles.selectedCategorizationItem} ${styles.categorizationListItem}`
              : `${styles.categorizationListItem}`
          }
          onClick={() => this.handleSelection('selectedDevice', deviceUsage.key)}
        >
          <p>{formattedServiceNum}</p>
          <div className={styles.usageContainer}>
            <p>{uncategorizedDeviceUsage > 1 ? uncategorizedDeviceUsage.toLocaleString() : '< 1'} MB</p>
            {percentUncategorized && (
              <>
                <p>|</p>
                <p>{percentUncategorized}%</p>
              </>
            )}
          </div>
        </div>
      );
    });
    let allDevicesRow = [
      <div
        key={0}
        style={{ borderBottom: '1px solid lightgray' }}
        className={
          this.state.selectedDevice === 'all'
            ? `${styles.selectedCategorizationItem} ${styles.categorizationListItem}`
            : `${styles.categorizationListItem}`
        }
        onClick={() => this.handleSelection('selectedDevice', 'all')}
      >
        <p>All</p>
        <div className={styles.usageContainer}>
          <p>{allDeviceUsageSum > 1 ? allDeviceUsageSum.toLocaleString() : '< 1'} MB</p>
          {/* <p>|</p>
               <p>{allPercentUncategorized}%</p> */}
        </div>
      </div>,
    ];
    let unknownUsageRow = [];
    if (unknownDeviceUsage > 0) {
      unknownUsageRow = [
        <div
          key={filteredDeviceArr.length + 1}
          className={
            this.state.selectedDevice === 'unknown'
              ? `${styles.selectedCategorizationItem} ${styles.categorizationListItem}`
              : `${styles.categorizationListItem}`
          }
          onClick={() => this.handleSelection('selectedDevice', 'unknown')}
        >
          <p>Unknown Devices</p>
          <div className={styles.usageContainer}>
            <p>{unknownDeviceUsage > 1 ? unknownDeviceUsage.toLocaleString() : '< 1'} MB</p>
            {/* <p>|</p>
               <p>{allPercentUncategorized}%</p> */}
          </div>
        </div>,
      ];
    }

    return [...allDevicesRow, ...filteredDeviceArr, ...unknownUsageRow];
  };

  displayDomains = () => {
    const { uncategorizedDomainUsage, domainSearch } = this.state;
    const filteredDomains = this.filterDomainsBySearch(uncategorizedDomainUsage, domainSearch);

    let list = filteredDomains.map(domain => {
      let domainName = domain.key.split('').reverse().join('');
      let megabytes = this.convertToMegabytes(domain.bytes.value);
      if (megabytes === 0) {
        megabytes = '< 1';
      }
      return (
        <div
          key={domainName}
          className={
            this.state.selectedDomain === domainName
              ? `${styles.selectedCategorizationItem} ${styles.categorizationListItem}`
              : `${styles.categorizationListItem}`
          }
          onClick={() => this.setState({ currentEditDomain: domain, newDomainName: domainName })}
        >
          <p style={{ width: '70%' }}>{domainName}</p>
          <div className={styles.usageContainer}>
            <p>{megabytes.toLocaleString()} MB</p>
          </div>
        </div>
      );
    });

    return list;
  };

  displaySimilarDomains = domainList => {
    let list = domainList.map(domain => {
      let domainName = domain.key.split('').reverse().join('');
      return (
        <div key={`similar: ${domainName}`} className={styles.similarDomainListItem}>
          <Checkbox
            value={domainName}
            color='primary'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={event => this.handleSelectDomain(event)}
            checked={this.state.similarDomainNames.includes(domainName)}
          />
          <p>{domainName}</p>
        </div>
      );
    });
    return list;
  };

  domainModal = () => {
    const {
      currentEditDomain,
      categories,
      services,
      newDomainName,
      newCategoryId,
      newServiceId,
      createNewServiceName,
    } = this.state;
    const fullDomain = currentEditDomain.key.split('').reverse().join('');
    let similarDomains = this.findSimilarDomains(fullDomain);

    let categoryOptions = Object.keys(categories).map(categoryId => {
      let category = categories[categoryId];
      return (
        <option key={category.id} value={category.id}>
          {category.name}
        </option>
      );
    });

    let filteredServiceIds = Object.keys(services).filter(
      serviceId => services[serviceId].category_id === newCategoryId
    );

    let servicesArr = filteredServiceIds.map(id => {
      let service = services[id];
      return service;
    });

    let sortedServices = servicesArr.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      else return 0;
    });

    let serviceOptions = sortedServices.map(service => {
      return (
        <option key={service.id} value={service.id}>
          {service.name}
        </option>
      );
    });

    let isDisabled = true;

    if (newServiceId || createNewServiceName) {
      isDisabled = false;
    }

    return (
      <div className={miscStyles.modalWrapper} onClick={() => this.cancelCategorizeDomains()}>
        <div className={styles.categorizeDomainModal} onClick={e => e.stopPropagation()}>
          <h4 style={{ margin: '0' }}>Categorize Domain</h4>
          <div className={styles.domainModalInputContainer}>
            <div>
              <p>Name:</p>
              <input
                type='text'
                value={newDomainName}
                onChange={e => this.handleSelection('newDomainName', e.target.value)}
                style={{ width: '50%' }}
              />
            </div>
            <div>
              <p>Category:</p>
              <select
                name='categorize-domain-categories'
                id='categorize-domain-categories'
                value={newCategoryId}
                onChange={e => this.handleSelection('newCategoryId', parseInt(e.target.value))}
              >
                <option value='' disabled defaultValue>
                  -- Select Category --
                </option>
                {categoryOptions}
              </select>
            </div>
            <div>
              <p>Service:</p>
              <select
                disabled={this.state.newCategoryId === '' || this.state.displayNewServiceInput}
                name='categorize-domain-services'
                id='categorize-domain-services'
                value={newServiceId}
                onChange={e => this.handleSelection('newServiceId', parseInt(e.target.value))}
              >
                <option value='' disabled defaultValue>
                  -- Select Service --
                </option>
                {serviceOptions}
              </select>
              {!this.state.displayNewServiceInput && (
                <p
                  onClick={() => this.setState({ displayNewServiceInput: true, newServiceId: '' })}
                  className={styles.createNewService}
                >
                  Create New Service
                </p>
              )}
              {this.state.displayNewServiceInput && (
                <div className={styles.newServiceContainer}>
                  <input
                    type='text'
                    value={this.state.createNewServiceName}
                    placeholder='New Service Name'
                    onChange={e => this.handleSelection('createNewServiceName', e.target.value)}
                  />
                  <p onClick={() => this.setState({ displayNewServiceInput: false, createNewServiceName: '' })}>
                    Cancel
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={styles.similarDomainsContainer}>
            <p>Select Similar Domains:</p>
            <div className={styles.selectAllDomains}>
              <Checkbox
                color='primary'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={event => this.handleSelectAllDomains(event, similarDomains)}
                disabled={similarDomains.length === 0}
              />
              <p style={{ color: similarDomains.length === 0 ? 'gray' : 'initial' }}>All</p>
            </div>
            <div className={styles.similarDomainsList}>{this.displaySimilarDomains(similarDomains)}</div>
          </div>
          <div className={styles.categorizeDomainModalButtons}>
            <Button
              onClick={() => this.cancelCategorizeDomains()}
              size='sm'
              style={{ margin: '.5rem 1rem .5rem 1rem' }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.categorizeDomains()}
              size='sm'
              style={{ margin: '.5rem 1rem .5rem 1rem' }}
              color='success'
              disabled={isDisabled}
            >
              Categorize
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={pageStyles.page}>
        <div className={pageStyles.header}>
          <p className={pageStyles.title}>Categorization</p>
        </div>

        <div className={pageStyles.body}>
          <div className={styles.container}>
            <div className={`${styles.categorizationPanel} ${styles.customerPanel}`}>
              <h5>Customer</h5>
              <div className={styles.panelContentContainer}>
                <div className={styles.panelContentHeader}>
                  <p>Name</p>
                  <div className={styles.usageContainer}>
                    <p id='total-customer-usage'>Uncategorized Usage</p>
                    <PopoverBox
                      title={'Total Data Usage'}
                      body={'Total amount of data usage for Customer'}
                      target={'total-customer-usage'}
                      placement={'top'}
                      trigger={'hover'}
                    />
                    <p>|</p>
                    <p id='uncategorized-customer-usage'>%</p>
                    <PopoverBox
                      title={'Uncategorized Data Usage'}
                      body={'Percent of Total Customer Data Usage that is Uncategorized'}
                      target={'uncategorized-customer-usage'}
                      placement={'top'}
                      trigger={'hover'}
                    />
                  </div>
                </div>
                <div className={`${pageStyles.searchBox} ${styles.categorizationSearchBox}`}>
                  <input
                    type='search'
                    placeholder='Search Name'
                    ref={el => (this._customerSearch = el)}
                    onChange={e => this.handleSearch('customerSearch', e.target.value)}
                  />
                  <p onClick={() => this.clearSearch('customerSearch')}>&#10005;</p>
                </div>
                <div className={styles.panelContentList}>
                  {this.state.uncategorizedCustomerUsage.length === 0 ? (
                    <div className={miscStyles.progressContainer}>
                      <CircularProgress />
                    </div>
                  ) : (
                    this.displayCustomerList()
                  )}
                </div>
              </div>
            </div>
            <div className={`${styles.categorizationPanel} ${styles.devicePanel}`}>
              <h5>Device</h5>
              <div className={styles.panelContentContainer}>
                <div className={styles.panelContentHeader}>
                  <p>Number</p>
                  <div className={styles.usageContainer}>
                    <p id='total-device-usage'>Uncategorized Usage</p>
                    <PopoverBox
                      title={'Total Data Usage'}
                      body={'Total amount of data usage for Device'}
                      target={'total-device-usage'}
                      placement={'top'}
                      trigger={'hover'}
                    />
                    <p>|</p>
                    <p id='uncategorized-device-usage'>%</p>
                    <PopoverBox
                      title={'Uncategorized Data Usage'}
                      body={'Percent of Total Device Data Usage that is Uncategorized'}
                      target={'uncategorized-device-usage'}
                      placement={'top'}
                      trigger={'hover'}
                    />
                  </div>
                </div>
                <div className={`${styles.searchBox} ${styles.categorizationSearchBox}`}>
                  <input
                    type='search'
                    placeholder='Search Number, Address, or Name'
                    ref={el => (this._deviceSearch = el)}
                    onChange={e => this.handleSearch('deviceSearch', e.target.value)}
                  />
                  <p onClick={() => this.clearSearch('deviceSearch')}>&#10005;</p>
                </div>
                <div className={styles.panelContentList}>
                  {this.state.deviceUsageLoading && (
                    <div className={miscStyles.progressContainer}>
                      <CircularProgress />
                    </div>
                  )}
                  {this.state.uncategorizedDeviceUsage.length !== 0 &&
                    !this.state.deviceUsageLoading &&
                    this.displayDeviceList()}
                </div>
              </div>
            </div>
            <div className={`${styles.categorizationPanel} ${styles.uncategorizedDomainPanel}`}>
              <h5>Uncategorized Domains</h5>
              <div className={styles.panelContentContainer}>
                <div className={styles.panelContentHeader} style={{ justifyContent: 'space-between' }}>
                  <p>Name</p>
                  <p>Usage</p>
                </div>
                <div className={`${styles.searchBox} ${styles.categorizationSearchBox}`}>
                  <input
                    type='search'
                    placeholder='Search Name'
                    ref={el => (this._domainSearch = el)}
                    onChange={e => this.handleSearch('domainSearch', e.target.value)}
                  />
                  <p onClick={() => this.clearSearch('domainSearch')}>&#10005;</p>
                </div>
                <div className={styles.panelContentList}>
                  {this.state.domainUsageLoading ? (
                    <div className={miscStyles.progressContainer}>
                      <CircularProgress />
                    </div>
                  ) : (
                    this.displayDomains()
                  )}
                </div>
              </div>
            </div>
          </div>
          {this.state.currentEditDomain && this.domainModal()}
        </div>
      </div>
    );
  }
}

export default CategorizationPage;
