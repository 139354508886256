import React, { useState } from 'react';
import CSVReader from 'react-csv-reader';
import { CSVLink } from 'react-csv';
import { Button } from 'reactstrap';
import miscStyles from '../../../styles/misc.module.scss';
import './ReconcileIPModal.scss';
import utils from '../../../utils/utils';

// returns array of objects
export function getIPDiscrepancies(data, devices) {
  try {
    if (!Array.isArray(data) || !Array.isArray(devices)) return [];
    if (data.length === 0 || devices.length === 0) return [];

    const dataHeaders = data.slice(0, 1)[0];
    const verizonDevices = data.slice(1);
    const ipNamePossibilities = [
      'IP Address',
      'IP address',
      'Address',
      'address',
      'IP ADDRESS',
      'ip address',
      'ip_address',
      'IP_Address',
      'IP_address',
      'IP-Address',
      'IP-address',
      'ipaddress',
    ];
    const serviceNumNamePossibilities = [
      'service number',
      'Service Number',
      'Service number',
      'service_number',
      'service num',
      'service_num',
      'Wireless Number',
      'Wireless_Number',
      'wireless_number',
      'Wireless number',
      'wireless number',
      'Wireless num',
      'wireless num',
      'Wireless Num',
      'Wireless_Num',
      'Wireless_num',
      'wireless_num',
      'Device Wireless Number',
    ];
    let verizonIpIdx;
    let verizonServiceNumIdx;
    let serviceNumMap = {}; // service_number : device
    let addressMap = {}; // address : device

    // determine header indexes
    dataHeaders.forEach(header => {
      if (ipNamePossibilities.includes(header)) {
        verizonIpIdx = dataHeaders.indexOf(header);
      } else if (serviceNumNamePossibilities.includes(header)) {
        verizonServiceNumIdx = dataHeaders.indexOf(header);
      }
    });

    if ((!verizonIpIdx && verizonIpIdx !== 0) || (!verizonServiceNumIdx && verizonServiceNumIdx !== 0)) return [];

    // create service number & address dictionaries
    devices.forEach(device => {
      serviceNumMap[device.service_number] = device;
      if (device.address) {
        addressMap[device.address] = device;
      }
    });

    let discrepancies = verizonDevices
      .filter(verizonDevice => {
        const verizonServiceNum = utils.formatServiceNum(verizonDevice[verizonServiceNumIdx]);
        const verizonIP = utils.formatIpAddress(verizonDevice[verizonIpIdx]);

        if (addressMap.hasOwnProperty(verizonIP)) {
          const rtsvcServiceNum = addressMap[verizonIP].service_number;

          if (rtsvcServiceNum !== verizonServiceNum) {
            verizonDevice.discrepancyMemo = 'IP address assigned to different service number in RTSVC';
            verizonDevice.rtsvcServiceNumber = rtsvcServiceNum;
            return true;
          }
        }

        if (serviceNumMap.hasOwnProperty(verizonServiceNum)) {
          const rtsvcIP = utils.formatIpAddress(serviceNumMap[verizonServiceNum].address);

          if (rtsvcIP !== verizonIP) {
            if (verizonIP) {
              verizonDevice.discrepancyMemo = 'Service number has different IP assigned to it in RTSVC';
            } else {
              verizonDevice.discrepancyMemo = 'RTSVC has IP address that is no longer associated with service number';
            }
            return true;
          }
        }

        return false;
      })
      .map(verizonDevice => {
        const rtsvcServiceNumber = utils.formatServiceNum(verizonDevice.rtsvcServiceNumber);
        const verizonServiceNumber = utils.formatServiceNum(verizonDevice[verizonServiceNumIdx]);
        const verizonIP = utils.formatIpAddress(verizonDevice[verizonIpIdx]);
        const { discrepancyMemo } = verizonDevice;

        return { rtsvcServiceNumber, verizonServiceNumber, verizonIP, discrepancyMemo };
      });

    console.log(discrepancies.length);
    return discrepancies;
  } catch (err) {
    console.log(err);
    return [];
  }
}

export const formatDiscrepanciesForCSV = data => {
  if (!data || (data && data.length === 0)) return [];

  try {
    const headers = ['Service Number', 'Verizon Service Number', 'IP Address', 'Description'];
    let rows = data.map(device => [
      device.rtsvcServiceNumber,
      device.verizonServiceNumber,
      device.verizonIP,
      device.discrepancyMemo,
    ]);

    return [headers, ...rows];
  } catch (err) {
    return [];
  }
};

export default function ReconcileIPModal(props) {
  const [CSVData, setCSVData] = useState();
  const [discrepancies, setDiscrepancies] = useState();

  const displayDiscrepancies = () => {
    return discrepancies.map(device => {
      const rtsvcServiceNumber = utils.readableServiceNum(device.rtsvcServiceNumber) || '--';
      const verizonServiceNumber = utils.readableServiceNum(device.verizonServiceNumber) || '--';
      const verizonIP = device.verizonIP || '--';
      const description = device.discrepancyMemo || '--';

      return (
        <div className='discrepant-device' key={device.verizonServiceNumber}>
          <p>{rtsvcServiceNumber}</p>
          <p>{verizonServiceNumber}</p>
          <p>{verizonIP}</p>
          <p>{description}</p>
        </div>
      );
    });
  };

  return (
    <div className={miscStyles.modalWrapper} onClick={props.closeReconcileIPModal}>
      <div className='reconcile-ip-modal' onClick={e => e.stopPropagation()}>
        <i className='fas fa-times close-reconcile-modal-button' onClick={props.closeReconcileIPModal}></i>

        <div className='reconcile-modal-header'>
          <h3>Reconcile IP Addresses</h3>
          <p>
            Upload a Verizon device report to check for IP Address discrepancies between Verizon's data and the data
            within RTSVC.
          </p>
        </div>

        <div className='reconcile-modal-body'>
          <div className='reconcile-instruction-container'>
            <div className='reconcile-instruction-header'>
              <div className='number-circle'>1</div>
              <p>Select file to upload</p>
            </div>
            <div className='reconcile-instruction-body'>
              <CSVReader
                onFileLoaded={data => setCSVData(utils.stripCSVNoise(data))}
                label={'Report File (.csv):'}
                cssClass='ip-report-csv'
              />
            </div>
          </div>

          <div className='reconcile-instruction-container'>
            <div className='reconcile-instruction-header'>
              <div className='number-circle'>2</div>
              <p>Check for discrepancies</p>
            </div>
            <div className='reconcile-instruction-body'>
              <Button
                size='sm'
                disabled={!CSVData}
                onClick={() => setDiscrepancies(getIPDiscrepancies(CSVData, props.devices))} // returns array of objects
              >
                Check
              </Button>
            </div>
          </div>

          <div className='reconcile-instruction-container'>
            <div className='reconcile-instruction-header'>
              <div className='number-circle'>3</div>
              <p>View / Export Results</p>
            </div>
            <div className='reconcile-instruction-body'>
              <div className='reconcile-results-container'>
                <div className='reconcile-results'>
                  <div className='reconcile-results-header'>
                    <p>RTSVC Service Number</p>
                    <p>Verizon Service Number</p>
                    <p>Verizon IP Address</p>
                    <p>Discrepancy</p>
                  </div>
                  <div className='reconcile-results-body'>
                    {discrepancies && (
                      <>
                        {discrepancies.length === 0 ? (
                          <div className='no-discrepancies-container'>
                            <i className='far fa-check-square'></i>
                            <p>All good! No discrepancies.</p>
                          </div>
                        ) : (
                          displayDiscrepancies()
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className='export-results-button-container'>
                  <CSVLink data={formatDiscrepanciesForCSV(discrepancies)} filename={'discrepancies.csv'}>
                    <Button size='sm' disabled={!discrepancies || (discrepancies && discrepancies.length === 0)}>
                      Export
                    </Button>
                  </CSVLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
