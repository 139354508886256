const initialState = {
  displayMessageModal: false,
  errorMessage: '',
  errorsArr: [],
};

// Action types
const UPDATE_DISPLAY_STATUS = 'UPDATE_DISPLAY_STATUS';
const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
const UPDATE_ERRORS = 'UPDATE_ERRORS';

// Action Creators
export function updateDisplayStatus(status) {
  //status is boolean
  return {
    type: UPDATE_DISPLAY_STATUS,
    payload: status,
  };
}
export function updateErrorMessage(message) {
  return {
    type: UPDATE_MESSAGE,
    payload: message,
  };
}
export function updateErrors(errorsArr) {
  return {
    type: UPDATE_ERRORS,
    payload: errorsArr,
  };
}

//Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DISPLAY_STATUS:
      return { ...state, displayMessageModal: action.payload };
    case UPDATE_MESSAGE:
      return { ...state, errorMessage: action.payload };
    case UPDATE_ERRORS:
      return { ...state, errorsArr: action.payload };
    default:
      return state;
  }
}
