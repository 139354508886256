import React, { Component } from 'react';
import styles from './User.module.scss';
import tableStyles from '../../../styles/table.module.scss';
import miscStyles from '../../../styles/misc.module.scss';
import EditAssignedModal from '../../../components/modals/EditAssignedModal/EditAssignedModal';
import { formatRoleName as formatName } from './functions';
import UserService from '../../../services/UserService';
import PasswordService from '../../../services/PasswordService';
import PopoverBox from '../../../components/PopoverBox/PopoverBox';

import { connect } from 'react-redux';
import { updateDisplayStatus, updateErrorMessage } from '../../../ducks/reducer';
import Swal from 'sweetalert2';

export class User extends Component {
  state = {
    user_id: this.props.user_id,
    first_name: this.props.first_name,
    last_name: this.props.last_name,
    email: this.props.email,
    assignedRoles: this.props.assigned_roles,
    unassignedRoles: this.props.unassigned_roles,
    edit: false,
    etag: '',
    displayRolesModal: false,
    displayChangePasswordModal: false,
    newUserPassword: '',
    confirmNewUserPassword: '',
  };

  componentDidUpdate = prevProps => {
    if (prevProps.currentEdit !== this.props.currentEdit) {
      this.cancelChanges();
    }
    if (prevProps.assigned_roles !== this.props.assigned_roles) {
      this.setState({ assignedRoles: this.props.assigned_roles });
    }
    if (prevProps.unassigned_roles !== this.props.unassigned_roles) {
      this.setState({ unassignedRoles: this.props.unassigned_roles });
    }
  };

  handleInput = (key, value) => {
    this.setState({ [key]: value });
  };

  saveChanges = async () => {
    const { first_name, last_name, email, assignedRoles, unassignedRoles, etag } = this.state;
    let body = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      assignedRoles: assignedRoles,
      unassignedRoles: unassignedRoles,
    };
    let config = {
      headers: {
        'If-Match': etag,
      },
    };
    try {
      await UserService.editUser(this.props.user_id, body, config);
      await this.props.getUsers(undefined, true);
      await this.props.getAllRoles();
      await this.props.assignRolesToUser();
      this.setState({ displayRolesModal: false });
      if (first_name === this.props.first_name && last_name === this.props.last_name && email === this.props.email) {
        this.setState({ edit: false });
      }
    } catch (err) {
      console.log(err.data.error);
    }
  };

  closeModal = async () => {
    const { first_name, last_name, email } = this.state;
    this.setState({
      displayRolesModal: false,
      displayChangePasswordModal: false,
      newUserPassword: '',
      confirmNewUserPassword: '',
      adminPassword: '',
    });
    if (first_name === this.props.first_name && last_name === this.props.last_name && email === this.props.email) {
      this.setState({ edit: false });
    } else {
      this.setState({ edit: true });
    }
  };

  cancelChanges = () => {
    const { first_name, last_name, email } = this.props;
    this.setState({
      first_name: first_name,
      last_name: last_name,
      email: email,
      edit: false,
      currentEdit: null,
    });
  };

  addRole = async role_id => {
    const { user_id } = this.state;
    let unassigned = this.state.unassignedRoles;
    let assigned = this.state.assignedRoles;
    let roleIndex = unassigned.findIndex(element => {
      if (element.id === role_id) {
        return true;
      } else return false;
    });
    let addedRole = unassigned.splice(roleIndex, 1)[0];
    try {
      await UserService.addRole(user_id, addedRole);
      assigned.push(addedRole);
      this.setState({
        unassignedRoles: unassigned,
        assignedRoles: assigned,
      });
    } catch (err) {
      console.log(err);
      unassigned.splice(roleIndex, 0, addedRole);
    }
  };

  removeRole = async role_id => {
    const { user_id } = this.state;
    let unassigned = this.state.unassignedRoles;
    let assigned = this.state.assignedRoles;
    let roleIndex = assigned.findIndex(element => {
      if (element.id === role_id) {
        return true;
      } else return false;
    });
    let removedRole = assigned.splice(roleIndex, 1)[0];
    try {
      await UserService.removeRole(user_id, role_id);
      unassigned.push(removedRole);
      this.setState({
        unassignedRoles: unassigned,
        assignedRoles: assigned,
      });
    } catch (err) {
      console.log(err);
      assigned.splice(roleIndex, 0, removedRole);
    }
  };

  toggleEdit = async () => {
    const { user_id, first_name, last_name, email } = this.state;
    let user = await UserService.getUserById(user_id);
    let updatedUser = user.data;
    let etag = user.headers.etag;
    await this.props.setCurrentEdit(user_id);
    if (updatedUser.first_name !== first_name || updatedUser.last_name !== last_name || updatedUser.email !== email) {
      this.setState({
        first_name: updatedUser.first_name,
        last_name: updatedUser.last_name,
        email: updatedUser.email,
      });
    }
    this.setState({ etag: etag, edit: true });
  };

  changePasswordModal = () => {
    const firstName = this.state.first_name;
    const fullName = `${firstName} ${this.state.last_name}`;

    if (this.state.user_id === this.props.loggedInUser) {
      window.location.hash = '#/account'; // if the user is trying to change their own password it routes them to the account details page
      return;
    }

    return (
      <div className={miscStyles.modalWrapper} onClick={() => this.closeModal()}>
        <div className={styles.changePasswordModal} onClick={e => e.stopPropagation()}>
          <h4>Change {fullName}'s Password</h4>
          <div className={styles.changePasswordBox}>
            <label htmlFor='user-new-password'>{firstName}'s New Password:</label>
            <input
              type='password'
              name='user-new-password'
              id='user-new-password'
              onChange={e => this.handleInput('newUserPassword', e.target.value)}
            />
            <label htmlFor='confirm-user-new-password'>Confirm {firstName}'s Password:</label>
            <input
              type='password'
              name='confirm-user-new-password'
              id='confirm-user-new-password'
              onChange={e => this.handleInput('confirmNewUserPassword', e.target.value)}
            />
          </div>
          <div className={styles.changePasswordModalButtons}>
            <p onClick={() => this.closeModal()}>CANCEL</p>
            <p style={{ color: 'forestgreen' }} onClick={() => this.changeUserPassword()}>
              SUBMIT
            </p>
          </div>
        </div>
      </div>
    );
  };

  changeUserPassword = () => {
    const { newUserPassword, confirmNewUserPassword, email } = this.state;
    if (newUserPassword !== confirmNewUserPassword) {
      this.props.updateDisplayStatus(true);
      this.props.updateErrorMessage('Passwords do not match. Please try again.');
      return;
    }
    if (newUserPassword.length === 0 || confirmNewUserPassword.length === 0 || email.length === 0) {
      this.props.updateDisplayStatus(true);
      this.props.updateErrorMessage('Missing required field(s)..');
      return;
    }
    let body = {
      email: email,
      password: newUserPassword,
    };
    let success = res => {
      if (res.status >= 200 && res.status <= 299) {
        this.setState({
          newUserPassword: '',
          confirmNewUserPassword: '',
          displayChangePasswordModal: false,
          edit: false,
        });
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Password successfully changed!',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    };
    let fail = err => {
      console.log(err);
      this.setState({
        newUserPassword: '',
        confirmNewUserPassword: '',
        displayChangePasswordModal: false,
      });
      this.props.updateDisplayStatus(true);
      this.props.updateErrorMessage('Password was not updated. Please check credentials and try again.');
    };
    PasswordService.updatePassword(body).then(success, fail);
  };

  formatRoleName = name => {
    return formatName(name);
  };

  displayUnassignedRolesList = () => {
    let unassignedRolesList = this.state.unassignedRoles.map(role => {
      const formattedRoleName = this.formatRoleName(role.name);
      return (
        <div className={miscStyles.modalAddRemoveItem} key={role.id}>
          <p test='unassigned-role'>{formattedRoleName}</p>
          <p onClick={() => this.addRole(role.id)}>Add</p>
        </div>
      );
    });
    return unassignedRolesList;
  };

  displayAssignedRolesList = () => {
    let assignedRolesList = this.state.assignedRoles.map(role => {
      const formattedRoleName = this.formatRoleName(role.name);
      return (
        <div className={miscStyles.modalAddRemoveItem} key={role.id}>
          <p test='assigned-role'>{formattedRoleName}</p>
          <p onClick={() => this.removeRole(role.id)}>Remove</p>
        </div>
      );
    });
    return assignedRolesList;
  };

  render() {
    let unassignedRolesList = this.displayUnassignedRolesList();
    let assignedRolesList = this.displayAssignedRolesList();
    let assignedRolesArr = this.state.assignedRoles.map((role, index) => {
      const formattedRoleName = this.formatRoleName(role.name);
      let roleName;
      let titleText;
      let bodyText;
      if (index !== this.state.assignedRoles.length - 1) {
        roleName = `${formattedRoleName}, `;
      } else if (index === this.state.assignedRoles.length - 1) {
        roleName = `${formattedRoleName} `;
      }
      if (role.name === 'role:Admin') {
        titleText = 'Admin Role';
        bodyText = 'Full access role for MobilSense back-office employees.';
      } else if (role.name === 'role:Application') {
        titleText = 'Application Role';
        bodyText =
          'Role for any users that are not MobilSense employees that includes most of the permissions of the Admin role, but does not include the ability to create and modify users.';
      } else if (role.name === 'role:MobilSentry') {
        titleText = 'Mobilsentry Role';
        bodyText =
          'Role for users logged into a MobilSentry instance. This is the most restrictive access of the available roles. When MobilSentry integration begins, the specific access permissions will be clarified. An example use case for a MobilSentry user would be to set, change, or remove an existing policy from a device.';
      }
      return (
        <div key={role.id}>
          <span id={`user-${this.state.user_id}-role-${role.id}`}>{roleName}</span>
          <PopoverBox
            title={titleText}
            body={bodyText}
            target={`user-${this.state.user_id}-role-${role.id}`}
            placement={'left'}
            trigger={'hover'}
          />
        </div>
      );
    });

    let userRow = (
      <div className={this.state.edit ? `${tableStyles.row} ${tableStyles.editRow}` : `${tableStyles.row}`}>
        <div>
          {!this.state.edit ? (
            this.state.first_name
          ) : (
            <input
              type='text'
              defaultValue={this.state.first_name}
              onChange={e => this.handleInput('first_name', e.target.value)}
            />
          )}
        </div>
        <div>
          {!this.state.edit ? (
            this.state.last_name
          ) : (
            <input
              type='text'
              defaultValue={this.state.last_name}
              onChange={e => this.handleInput('last_name', e.target.value)}
            />
          )}
        </div>
        <div className={styles.emailPasswordContainer} style={{ flex: '2.5' }}>
          {!this.state.edit ? (
            this.state.email
          ) : (
            <>
              <input
                type='text'
                defaultValue={this.state.email}
                onChange={e => this.handleInput('email', e.target.value)}
              />
              {this.props.isAdmin && (
                <>
                  <p
                    onClick={() => this.setState({ displayChangePasswordModal: true })}
                    className={`${styles.changePasswordButton} ${miscStyles.editButton}`}
                  >
                    CHANGE PASSWORD
                  </p>
                </>
              )}
            </>
          )}
        </div>
        <div className={styles.rolesContainer}>
          {!this.state.edit ? (
            assignedRolesArr
          ) : (
            <>
              <p onClick={() => this.setState({ displayRolesModal: true })} className={miscStyles.editButton}>
                EDIT ROLES
              </p>
            </>
          )}
        </div>
        <div>
          {!this.state.edit ? (
            <div className={tableStyles.rowButtonContainer}>
              <p className={miscStyles.editButton} onClick={() => this.toggleEdit()}>
                EDIT
              </p>
            </div>
          ) : (
            <div className={tableStyles.rowButtonContainer}>
              <p className={tableStyles.saveButton} onClick={() => this.saveChanges()}>
                SAVE
              </p>
              <p className={tableStyles.cancelEditButton} onClick={() => this.cancelChanges()}>
                CANCEL
              </p>
            </div>
          )}
        </div>
      </div>
    );

    let roleDescriptions = (
      <>
        <h6>Admin Role:</h6>
        <p>Full access role for MobilSense back-office employees.</p>
        <h6>Application Role:</h6>
        <p>
          Role for any users that are not MobilSense employees that includes most of the permissions of the Admin role,
          but does not include the ability to create and modify users.
        </p>
        <h6>MobilSentry Role:</h6>
        <p>
          Role for users logged into a MobilSentry instance. This is the most restrictive access of the available roles.
          When MobilSentry integration begins, the specific access permissions will be clarified. An example use case
          for a MobilSentry user would be to set, change, or remove an existing policy from a device.
        </p>
      </>
    );

    return (
      <>
        {userRow}
        {this.state.displayRolesModal && (
          <EditAssignedModal
            unassigned={unassignedRolesList}
            assigned={assignedRolesList}
            property={'Roles'}
            closeModal={this.closeModal}
            descriptions={roleDescriptions}
          />
        )}
        {this.state.displayChangePasswordModal && <>{this.changePasswordModal()}</>}
      </>
    );
  }
}

const mapStateToProps = reduxState => reduxState;

export default connect(mapStateToProps, { updateDisplayStatus, updateErrorMessage })(User);
