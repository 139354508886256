import React from 'react';

import { ResponsiveLine } from '@nivo/line';

export default function DataUsageChart(props) {
  const theme = {
    axis: {
      ticks: {
        text: {
          fontSize: '.8rem',
          letterSpacing: '.25px',
        },
      },
      legend: {
        text: {
          fontSize: '1rem',
        },
      },
    },
    legends: {
      text: {
        fontSize: '.7rem',
      },
    },
  };

  const getTickValues = () => {
    const { data, xAxisLabel } = props;
    if (xAxisLabel === 'Day') {
      if (data.some(x => x.data.length <= 8)) {
        return 'every day';
      }
    }
    return undefined;
  };

  return (
    <ResponsiveLine
      data={props.data}
      margin={{ top: 20, right: 150, bottom: 70, left: 65 }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -40,
        tickValues: getTickValues(),
        legend: props.xAxisLabel,
        legendOffset: 60,
        legendPosition: 'middle',
        format: '%b %d',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'GB',
        legendOffset: -55,
        legendPosition: 'middle',
      }}
      colors={props.colors}
      theme={theme}
      xScale={{
        type: 'time',
        format: '%m-%d-%Y',
      }}
      xFormat='time:%m-%d-%Y'
      pointSize={10}
      pointColor='white'
      pointBorderWidth={3}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabel='y'
      pointLabelYOffset={-12}
      useMesh={true}
      tooltip={slice => {
        return (
          <div
            className='point-tooltip'
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              backgroundColor: 'white',
              boxShadow: '-2px 2px 5px 0px gray',
              width: 'auto',
              height: 'auto',
              padding: '0 .25rem',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              <strong>{slice.point.serieId}</strong>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: slice.point.serieColor,
                  marginLeft: '5px',
                  borderRadius: '50%',
                }}
              ></div>
            </div>
            <p style={{ margin: '0' }}>Date: {slice.point.data.xFormatted}</p>
            <p style={{ margin: '0' }}>Usage: {slice.point.data.yFormatted} GB</p>
          </div>
        );
      }}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 10,
          itemsSpacing: 0,
          itemDirection: 'top-to-bottom',
          itemWidth: 80,
          itemHeight: 40,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          // effects: [
          // 	{
          // 		on: 'hover',
          // 		style: {
          // 			itemBackground: 'rgba(0, 0, 0, .03)',
          // 			itemOpacity: 1
          // 		}
          // 	}
          // ]
        },
      ]}
    />
  );
}
