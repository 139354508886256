import React, { Component } from 'react';
import styles from './Customer.module.scss';
import tableStyles from '../../../styles/table.module.scss';
import miscStyles from '../../../styles/misc.module.scss';
import EditAssignedModal from '../../../components/modals/EditAssignedModal/EditAssignedModal';
import CustomerService from '../../../services/CustomerService';
import ApnService from '../../../services/ApnService';
import SubnetService from '../../../services/SubnetService';

import { connect } from 'react-redux';
import { updateDisplayStatus, updateErrorMessage, updateErrors } from '../../../ducks/reducer';
import { Typeahead } from 'react-bootstrap-typeahead';

export class Customer extends Component {
  _isMounted = false;

  state = {
    customer_id: null,
    name: null,
    mobilsentry_id: null,
    allApns: null,
    carriers: null,
    unassignedApns: [],
    assignedApns: [],
    unassignedSubnets: [],
    assignedSubnets: [],
    etag: '',
    editCustomer: false,
    displayApnModal: false,
    displaySubnetModal: false,
    expand: false,
    isLoading: true,
    unassignedSubnetsList: [],
    assignedSubnetsList: [],
    unassignedApnsLList: [],
    assignedApnsList: [],
  };

  componentDidMount = async () => {
    this._isMounted = true;
    try {
      await this.getCustomerApns();
      await this.getAllSubnets();
      if (this._isMounted) {
        this.setState({
          isLoading: false,
          customer_id: this.props.customer_id,
          name: this.props.name,
          mobilsentry_id: this.props.mobilsentry_id,
          allApns: this.props.allApns,
          carriers: this.props.carriers,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  componentDidUpdate = async prevProps => {
    if (prevProps.currentEdit !== this.props.currentEdit) {
      this.cancelEdit();
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  submitEdit = async () => {
    const typeaheadInstance = this._typeahead.getInstance();
    let typeAheadValue = typeaheadInstance.getInput().value;
    const { customer_id } = this.props;
    const { name, etag } = this.state;
    let mobilSentries = this.props.mobilSentries;
    for (let i = 0; i < mobilSentries.length; i++) {
      if (mobilSentries[i].db_name === typeAheadValue) {
        await this.setState({ mobilsentry_id: mobilSentries[i].id });
      }
    }
    let body = {
      name: name,
      id: customer_id,
      mobilsentry_id: this.state.mobilsentry_id,
    };
    let config = {
      headers: {
        'If-Match': etag,
      },
    };
    try {
      await CustomerService.editCustomer(customer_id, body, config);
      await this.props.getCustomers();
      this.setState({ editCustomer: false });
    } catch (err) {
      console.log(err);
    }
  };

  cancelEdit = () => {
    this.setState({
      name: this.props.name,
      mobilsentry_id: this.props.mobilsentry_id,
      editCustomer: false,
    });
  };

  getCustomerApns = async () => {
    const { customer_id } = this.props;
    let allApns = this.props.allApns;
    let assignedApns = await ApnService.getApnsByCustomerId(customer_id);
    let unassigned = [];
    allApns.forEach(apn => {
      let assigned = false;
      assignedApns.data.forEach(aApn => {
        if (aApn.id === apn.id) {
          assigned = true;
        }
      });
      if (!assigned) {
        unassigned.push(apn);
      }
    });
    this.setState(
      {
        unassignedApns: unassigned,
        assignedApns: assignedApns.data,
      },
      () => {
        this.makeAssignedApnsList();
        this.makeUnassignedApnsList();
      }
    );
  };

  addApn = async apn_id => {
    const { customer_id } = this.props;
    let unassigned = this.state.unassignedApns;
    let assigned = this.state.assignedApns;
    let apnIndex = unassigned.findIndex(element => element.id === apn_id);
    let addedApn = unassigned.splice(apnIndex, 1)[0];
    try {
      await ApnService.addApn(customer_id, addedApn);
      assigned.push(addedApn);
      this.setState(
        {
          unassignedApns: unassigned,
          assignedApns: assigned,
        },
        () => {
          this.makeAssignedApnsList();
          this.makeUnassignedApnsList();
        }
      );
    } catch (err) {
      console.log(err);
      this.props.updateErrorMessage('This APN cannot be added.');
      unassigned.splice(apnIndex, 0, addedApn);
    }
  };

  removeApn = async apn_id => {
    const { unassignedApns, assignedApns } = this.state;
    const { customer_id } = this.props;
    let unassigned = unassignedApns;
    let assigned = assignedApns;
    let apnIndex = assigned.findIndex(element => {
      if (element.id === apn_id) {
        return true;
      } else return false;
    });
    let removedApn = assigned.splice(apnIndex, 1)[0];
    try {
      await ApnService.removeApn(customer_id, apn_id);
      unassigned.push(removedApn);
      this.setState(
        {
          unassignedApns: unassigned,
          assignedApns: assigned,
        },
        () => {
          this.makeAssignedApnsList();
          this.makeUnassignedApnsList();
        }
      );
    } catch (err) {
      console.log(err);
      this.props.updateErrorMessage('This APN cannot be removed.');
      assigned.splice(apnIndex, 0, removedApn); //putting the apn back in it's original place
    }
  };

  editApns = event => {
    event.stopPropagation();
    this.setState({ displayApnModal: true });
  };

  getSubnet = async apn_id => {
    try {
      let res = await SubnetService.getSubnetsByApnId(apn_id);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  getAllSubnets = async () => {
    const { assignedApns } = this.state;
    const { customer_id } = this.props;
    let promises = assignedApns.map(apn => this.getSubnet(apn.id));
    let results = await Promise.all(promises);
    let all = [].concat(...results);
    let unassigned = [];
    let assigned = [];
    all.forEach(subnet => {
      if (subnet.customer_id === null) {
        unassigned.push(subnet);
      } else if (subnet.customer_id === customer_id) {
        assigned.push(subnet);
      }
    });
    this.setState(
      {
        unassignedSubnets: unassigned,
        assignedSubnets: assigned,
      },
      () => {
        this.assignCarrierToSubnet();
        this.makeAssignedSubnetsList();
        this.makeUnassignedSubnetsList();
      }
    );
  };

  addSubnet = async subnet_id => {
    const { unassignedSubnets, assignedSubnets } = this.state;
    const { customer_id } = this.props;
    let unassigned = unassignedSubnets;
    let assigned = assignedSubnets;
    let subnetIndex = unassigned.findIndex(element => element.id === subnet_id);
    let addedSubnet = unassigned.splice(subnetIndex, 1)[0];
    let res = await SubnetService.getSubnetById(addedSubnet.apn_id, addedSubnet.id);
    let etag = res.headers.etag;
    addedSubnet = res.data;
    addedSubnet.customer_id = customer_id;
    let config = {
      headers: {
        'If-Match': etag,
      },
    };
    try {
      await SubnetService.addSubnet(addedSubnet.apn_id, addedSubnet.id, addedSubnet, config);
      assigned.push(addedSubnet);
      this.setState(
        {
          unassignedSubnets: unassigned,
          assignedSubnets: assigned,
        },
        () => {
          this.assignCarrierToSubnet();
          this.makeAssignedSubnetsList();
          this.makeUnassignedSubnetsList();
        }
      );
    } catch (err) {
      console.log(err);
      this.props.updateErrorMessage('This subnet cannot be added.');
      unassigned.splice(subnetIndex, 0, addedSubnet);
    }
  };

  removeSubnet = async subnet_id => {
    const { unassignedSubnets, assignedSubnets } = this.state;
    let unassigned = unassignedSubnets;
    let assigned = assignedSubnets;
    let subnetIndex = assigned.findIndex(element => {
      if (element.id === subnet_id) {
        return true;
      } else return false;
    });
    let removedSubnet = assigned.splice(subnetIndex, 1)[0];
    let res = await SubnetService.getSubnetById(removedSubnet.apn_id, removedSubnet.id);
    let etag = res.headers.etag;
    removedSubnet = res.data;
    removedSubnet.customer_id = null;
    let config = {
      headers: {
        'If-Match': etag,
      },
    };
    try {
      await SubnetService.removeSubnet(removedSubnet.apn_id, removedSubnet.id, removedSubnet, config);
      unassigned.push(removedSubnet);
      this.setState(
        {
          unassignedSubnets: unassigned,
          assignedSubnets: assigned,
        },
        () => {
          this.assignCarrierToSubnet();
          this.makeAssignedSubnetsList();
          this.makeUnassignedSubnetsList();
        }
      );
    } catch (err) {
      console.log(err);
      this.props.updateErrorMessage('This subnet cannot be removed.');
      assigned.splice(subnetIndex, 0, removedSubnet); //putting the apn back in it's original place
    }
  };

  editSubnets = event => {
    event.stopPropagation();
    this.getAllSubnets();
    this.setState({ displaySubnetModal: true });
  };

  assignCarrierToSubnet = async () => {
    const { allApns } = this.props;
    let assigned = [...this.state.assignedSubnets];
    let unassigned = [...this.state.unassignedSubnets];
    if ((assigned !== []) & (unassigned !== [])) {
      let appendedAssigned = assigned.map(subnet => {
        let apnMatch = allApns.filter(apn => apn.id === subnet.apn_id)[0];
        subnet.carrier_name = apnMatch.carrier_name;
        return subnet;
      });
      let appendedUnassigned = unassigned.map(subnet => {
        let apnMatch = allApns.filter(apn => apn.id === subnet.apn_id)[0];
        subnet.carrier_name = apnMatch.carrier_name;
        return subnet;
      });
      this.setState({ assignedSubnets: appendedAssigned, unassignedSubnets: appendedUnassigned });
    }
  };

  closeModal = () => {
    const { name } = this.state;
    const { mobilsentry_id } = this.props;
    this.setState({
      displayApnModal: false,
      displaySubnetModal: false,
    });
    if (name === this.props.name && mobilsentry_id === this.props.mobilsentry_id) {
      this.setState({
        editCustomer: false,
      });
    }
  };

  clearName = event => {
    event.stopPropagation();
    this.setState({ name: '' });
  };

  handleSelection = selected => {
    if (selected.length) {
      this.setState({ mobilsentry_id: selected[0].id });
    } else {
      this.setState({ mobilsentry_id: this.props.mobilsentry_id });
    }
  };

  handleInput = (key, value) => {
    this.setState({ [key]: value });
  };

  handleEdit = async () => {
    const { customer_id, name, mobilsentry_id } = this.props;
    await this.props.setCurrentEdit(customer_id);
    this.setState({ editCustomer: true }, async () => {
      let customer = await CustomerService.getCustomerById(customer_id);
      let customerInfo = customer.data;
      let etag = customer.headers.etag;
      if (customerInfo.name !== name || customerInfo.mobilsentry_id !== mobilsentry_id) {
        this.setState({
          name: customerInfo.name,
          mobilsentry_id: customerInfo.mobilsentry_id,
        });
      }
      this.setState({ etag: etag });
    });
  };

  displayMobilSentryIdName = () => {
    let all = this.props.mobilSentries;
    let name = 'Choose MobilSentry ID';
    all.forEach(element => {
      if (element.id === this.state.mobilsentry_id) {
        name = element.db_name;
      }
    });
    return name;
  };

  makeUnassignedApnsList = () => {
    let list = this.state.unassignedApns.map(apn => {
      return (
        <div className={miscStyles.modalAddRemoveItem} key={apn.id}>
          <p>{apn.name}</p>
          <p onClick={() => this.addApn(apn.id)}>Add</p>
        </div>
      );
    });
    this.setState({ unassignedApnsList: list });
  };

  makeAssignedApnsList = () => {
    let list = this.state.assignedApns.map(apn => {
      return (
        <div className={miscStyles.modalAddRemoveItem} key={apn.id}>
          <p>{apn.name}</p>
          <p onClick={() => this.removeApn(apn.id)}>Remove</p>
        </div>
      );
    });
    this.setState({ assignedApnsList: list });
  };

  makeUnassignedSubnetsList = () => {
    let list = this.state.unassignedSubnets.map(subnet => {
      return (
        <div className={miscStyles.modalAddRemoveItem} key={subnet.id}>
          <p>
            {subnet.subnet} - {subnet.carrier_name}
          </p>
          <p onClick={() => this.addSubnet(subnet.id)}>Add</p>
        </div>
      );
    });
    this.setState({ unassignedSubnetsList: list });
  };

  makeAssignedSubnetsList = () => {
    let list = this.state.assignedSubnets.map(subnet => {
      return (
        <div className={miscStyles.modalAddRemoveItem} key={subnet.id}>
          <p>
            {subnet.subnet} - {subnet.carrier_name}
          </p>
          <p onClick={() => this.removeSubnet(subnet.id)}>Remove</p>
        </div>
      );
    });
    this.setState({ assignedSubnetsList: list });
  };

  displayItems = (list, description, isSubnet) => {
    let result = list.map((item, index) => {
      let p;
      let totalItems = list.length;
      let remainingItems = totalItems - 2;
      if (index === 0) {
        if (!isSubnet) {
          p = <p key={item.id}>{item[description]}</p>;
        } else {
          p = (
            <p key={item.id}>
              {item[description]} - {item.carrier_name}
            </p>
          );
        }
      } else if (index === 1) {
        p = (
          <p key={item.id}>
            {!isSubnet ? (
              <>{item[description]}</>
            ) : (
              <>
                {item[description]} - {item.carrier_name}
              </>
            )}
            {totalItems > 2 && (
              <span
                className={miscStyles.hoverCursor}
                style={{ fontSize: '.8rem', color: 'indianred', fontWeight: 'bold' }}
                onClick={e => (isSubnet ? this.editSubnets(e) : this.editApns(e))}
              >
                {' '}
                +{remainingItems} more...
              </span>
            )}
          </p>
        );
      }
      return p;
    });

    return result;
  };

  displayAllItems = (list, description, isSubnet) => {
    let result = list.map(item => {
      return (
        <p key={item.id}>
          {!isSubnet ? (
            <>{item[description]}</>
          ) : (
            <>
              {item[description]} - {item.carrier_name}
            </>
          )}
        </p>
      );
    });
    return result;
  };

  displayCustomerRow = () => {
    const { name, mobilsentry_id, assignedApns, assignedSubnets, editCustomer } = this.state;
    let rowStyles = {
      minHeight: '3rem',
    };
    if (this.state.editCustomer) {
      rowStyles.minHeight = '5rem';
      rowStyles.overflow = 'initial';
    }

    return (
      <div
        className={
          this.state.editCustomer
            ? `${styles.editCustomerRow} ${tableStyles.row} ${tableStyles.editRow}`
            : `${tableStyles.row}`
        }
        style={rowStyles}
      >
        <div>
          {!this.state.editCustomer ? (
            <p>{name}</p>
          ) : (
            <>
              <input
                type='text'
                placeholder=' Enter name'
                value={name}
                style={{ width: '75%' }}
                onChange={e => this.handleInput('name', e.target.value)}
                onClick={e => e.stopPropagation()}
              />
              <p className={miscStyles.editButton} onClick={e => this.clearName(e)}>
                CLEAR
              </p>
            </>
          )}
        </div>
        <div>
          {!this.state.editCustomer ? (
            <>
              {mobilsentry_id ? (
                <>
                  <>{this.displayMobilSentryIdName()}</>
                </>
              ) : null}
            </>
          ) : (
            <div onClick={e => e.stopPropagation()}>
              <Typeahead
                options={this.props.mobilSentries}
                labelKey='db_name'
                id='mobilSentry-id-selector'
                placeholder={this.displayMobilSentryIdName()}
                selectHintOnEnter={true}
                ref={ref => (this._typeahead = ref)}
                onChange={selected => this.handleSelection(selected)}
              />
              <p className={miscStyles.editButton} onClick={() => this.setState({ mobilsentry_id: null })}>
                CLEAR
              </p>
            </div>
          )}
        </div>
        <div>
          {!editCustomer ? (
            <>{assignedApns.length === 0 ? null : <>{this.displayItems(assignedApns, 'name')}</>}</>
          ) : (
            <>
              {this.displayItems(assignedApns, 'name')}
              <p className={miscStyles.editButton} onClick={e => this.editApns(e)}>
                EDIT
              </p>
            </>
          )}
        </div>
        <div>
          {!editCustomer ? (
            <>{assignedSubnets.length === 0 ? null : <>{this.displayItems(assignedSubnets, 'subnet', true)}</>}</>
          ) : (
            <>
              {this.displayItems(assignedSubnets, 'subnet', true)}

              <p className={miscStyles.editButton} onClick={e => this.editSubnets(e)}>
                EDIT
              </p>
            </>
          )}
        </div>
        <div style={{ flex: '.5' }}>
          {!editCustomer ? (
            <div className={tableStyles.rowButtonContainer}>
              <p className={miscStyles.editButton} onClick={() => this.handleEdit()}>
                EDIT
              </p>
            </div>
          ) : (
            <div className={tableStyles.rowButtonContainer}>
              <p className={tableStyles.saveButton} onClick={() => this.submitEdit()}>
                SAVE
              </p>
              <p className={tableStyles.cancelEditButton} onClick={() => this.cancelEdit()}>
                CANCEL
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { unassignedApnsList, assignedApnsList, unassignedSubnetsList, assignedSubnetsList } = this.state;
    return (
      <>
        {!this.state.isLoading && this.displayCustomerRow()}
        {this.state.displayApnModal && (
          <EditAssignedModal
            property={`APN's`}
            closeModal={this.closeModal}
            assigned={assignedApnsList}
            unassigned={unassignedApnsList}
          />
        )}
        {this.state.displaySubnetModal && (
          <EditAssignedModal
            property={'Subnets'}
            closeModal={this.closeModal}
            assigned={assignedSubnetsList}
            unassigned={unassignedSubnetsList}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = reduxState => reduxState;

export default connect(mapStateToProps, { updateDisplayStatus, updateErrorMessage, updateErrors })(Customer);
