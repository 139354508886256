import React, { Component } from 'react';
import styles from './CustomersPage.module.scss';
import pageStyles from '../../styles/page.module.scss';
import rightMenuStyles from '../../styles/rightMenu.module.scss';
import tableStyles from '../../styles/table.module.scss';
import miscStyles from '../../styles/misc.module.scss';
import Customer from './Customer/Customer';
import utils from '../../utils/utils';
import CustomerService from '../../services/CustomerService';
import ApnService from '../../services/ApnService';
import MobilSentryService from '../../services/MobilSentryService';
import CarrierService from '../../services/CarrierService';

import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { updateDisplayStatus, updateErrorMessage, updateErrors } from '../../ducks/reducer';
import { Button } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

export class CustomersPage extends Component {
  state = {
    customers: [],
    filteredCustomers: [],
    carriers: [],
    allApns: [],
    mobilSentries: [],
    showMenu: false,
    name: '',
    mobilsentry_id: null,
    sortDescending: true,
    mouseHover: false,
    hoveredCategory: '',
    customerSearch: '',
    currentEdit: null,
    isLoading: true,
  };

  componentDidMount = async () => {
    try {
      await this.getCustomers();
      await this.getMobilSentries();
      await this.getApns();
      await this.getCarriers();
      this.assignCarriersToApns();
      this.setState({ isLoading: false });
    } catch (err) {
      console.log(err);
    }
  };

  getCustomers = async () => {
    try {
      const res = await CustomerService.getAllCustomers();
      this.setState({ customers: res.data });
    } catch (err) {
      console.log(err);
    }
  };

  getMobilSentries = async () => {
    let mobilSentries = await MobilSentryService.getMobilSentries();
    this.setState({ mobilSentries: mobilSentries.data });
  };

  getCarriers = async () => {
    let res = await CarrierService.getCarriers();
    this.setState({ carriers: res.data });
  };

  getApns = async () => {
    let res = await ApnService.getApns();
    this.setState({ allApns: res.data });
  };

  addCustomer = async () => {
    if (!this.state.name || !this.state.mobilsentry_id) {
      this.props.updateDisplayStatus(true);
      this.props.updateErrorMessage('Missing required field(s)..');
      return;
    }
    const body = {
      name: this.state.name,
      mobilsentry_id: this.state.mobilsentry_id,
    };
    await CustomerService.addCustomer(body);
    this.toggleAddModal();
    await this.getCustomers();
  };

  cancelAddCustomer = () => {
    this.setState({ name: '' });
    this._typeahead.getInstance().clear();
    this.toggleAddModal();
  };

  toggleAddModal = () => this.setState({ showMenu: !this.state.showMenu });

  setCurrentEdit = id => {
    this.setState({ currentEdit: id });
  };

  assignCarriersToApns = () => {
    this.state.allApns.forEach(apn => {
      let carrier = this.state.carriers.filter(carrier => carrier.id === apn.carrier_id)[0];
      apn.carrier_name = carrier.short_name;
    });
  };

  handleInput = (key, value) => {
    this.setState({ [key]: value });
  };

  handleSearch = input => {
    this.setState({ customerSearch: input });
  };

  handleSelection = selected => {
    if (selected.length) {
      this.setState({ mobilsentry_id: selected[0].id });
    } else {
      this.setState({ mobilsentry_id: this.props.mobilsentry_id });
    }
  };

  handleSort = property => {
    let customersList = this.state.customers;
    let sortDescending = this.state.sortDescending;
    let sorted = utils.handleSort(property, customersList, sortDescending);
    this.setState({
      customers: sorted,
      sortDescending: !this.state.sortDescending,
    });
  };

  handleMouseEnter = category => {
    this.setState({
      mouseHover: true,
      hoveredCategory: category,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      mouseHover: false,
      hoveredCategory: '',
    });
  };

  clearSearch = () => {
    this._customerSearch.value = '';
    this.setState({ customerSearch: '' });
  };

  filterCustomers = () => {
    const { customerSearch, customers, mobilSentries } = this.state;

    let filteredCustomers = [...customers];

    if (customerSearch) {
      filteredCustomers = filteredCustomers.filter(customer => {
        for (let key in customer) {
          if (typeof customer[key] === 'string') {
            if (customer[key].toLowerCase().includes(customerSearch.toLowerCase())) {
              return true;
            }
          } else if (typeof customer[key] === 'number') {
            if (customer[key].toString().includes(customerSearch.toString())) {
              return true;
            }
          }
        }
        return false;
      });
    }
    filteredCustomers.forEach(customer => {
      //adding mobilsentries to customer object
      for (let i = 0; i < mobilSentries.length; i++) {
        if (mobilSentries[i].id === customer.mobilsentry_id) {
          customer.mobilsentry_name = mobilSentries[i].db_name;
        }
      }
    });
    return filteredCustomers;
  };

  displayCustomers = () => {
    let filteredCustomers = this.filterCustomers();
    let customerList = filteredCustomers.map(customer => {
      return (
        <Customer
          key={customer.id}
          customer_id={customer.id}
          mobilsentry_id={customer.mobilsentry_id}
          name={customer.name}
          allApns={this.state.allApns}
          carriers={this.state.carriers}
          getCustomers={this.getCustomers}
          deleteCustomer={this.deleteCustomer}
          mobilSentries={this.state.mobilSentries}
          currentEdit={this.state.currentEdit}
          setCurrentEdit={this.setCurrentEdit}
        />
      );
    });
    return customerList;
  };

  customersTable = () => {
    return (
      <div className={tableStyles.table}>
        <div className={tableStyles.header} style={{ height: '2rem' }}>
          <div className={tableStyles.columnTitleRow}>
            <div
              className={miscStyles.hoverCursor}
              onClick={() => this.handleSort('name')}
              onMouseEnter={() => this.handleMouseEnter('name')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Name
              {utils.displayArrow('name', this.state.mouseHover, this.state.hoveredCategory, this.state.sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              onClick={() => this.handleSort('mobilsentry_id')}
              onMouseEnter={() => this.handleMouseEnter('mobilsentry_id')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              MobilSentry ID
              {utils.displayArrow(
                'mobilsentry_id',
                this.state.mouseHover,
                this.state.hoveredCategory,
                this.state.sortDescending
              )}
            </div>
            <div>APN's</div>
            <div>Subnets</div>
            <div style={{ flex: '.5' }}></div>
          </div>
        </div>
        <div className={tableStyles.body} style={{ height: 'calc(100% - 2rem)' }}>
          {this.displayCustomers()}
        </div>
      </div>
    );
  };

  render() {
    const addCustomerMenu = (
      <div
        className={
          this.state.showMenu
            ? `${rightMenuStyles.rightMenu} ${rightMenuStyles.slideRightMenu}`
            : `${rightMenuStyles.rightMenu}`
        }
        style={{ zIndex: '3' }}
      >
        <div className={rightMenuStyles.addMenuInputs}>
          <input
            type='text'
            value={this.state.name}
            placeholder=' Customer Name'
            onChange={e => this.handleInput('name', e.target.value)}
          />
          <Typeahead
            className={rightMenuStyles.rightMenuTypeahead}
            options={this.state.mobilSentries}
            labelKey='db_name'
            id='mobilSentry-id-selector'
            onChange={selected => this.handleSelection(selected)}
            placeholder={'Select MobilSentry'}
            selectHintOnEnter={true}
            ref={ref => (this._typeahead = ref)}
          />
          <div>
            <Button onClick={() => this.cancelAddCustomer()} style={{ margin: '1rem' }} size='sm'>
              Cancel
            </Button>
            <Button
              onClick={() => this.addCustomer()}
              style={{ margin: '1rem' }}
              size='sm'
              color='success'
              disabled={!this.state.mobilsentry_id || !this.state.name}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );

    return (
      <div className={pageStyles.page}>
        <div className={pageStyles.header}>
          <p className={pageStyles.title}>Customers</p>
          <div className={pageStyles.buttonInputContainer}>
            <div className={pageStyles.searchBox}>
              <input
                type='search'
                placeholder=' Search'
                ref={el => (this._customerSearch = el)}
                onChange={e => this.handleSearch(e.target.value)}
              />
              <p onClick={() => this.clearSearch()}>&#10005;</p>
            </div>
            <Button
              size='sm'
              onClick={() => {
                this.toggleAddModal();
              }}
            >
              Add Customer
            </Button>
          </div>
        </div>

        <div className={pageStyles.body}>
          {this.state.isLoading ? (
            <div className={miscStyles.progressContainer}>
              <CircularProgress />
            </div>
          ) : (
            <div className={styles.tableContainer}>{this.customersTable()}</div>
          )}
          {addCustomerMenu}
        </div>
      </div>
    );
  }
}

const mapStateToProps = reduxState => reduxState;

export default connect(mapStateToProps, { updateDisplayStatus, updateErrorMessage, updateErrors })(CustomersPage);
