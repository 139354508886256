import React, { Component } from 'react';
import msLogo from '../../assets/ms-logo.gif';
import miscStyles from '../../styles/misc.module.scss';

import { Button } from 'reactstrap';

export default class LoginPage extends Component {
  render() {
    const { handleKeyPress, loginFail, handleInput, handleLogin } = this.props;

    return (
      <div className={miscStyles.modalWrapper} style={{ backgroundColor: 'white' }}>
        <div className='auth-modal' onKeyPress={handleKeyPress}>
          <div>
            <img src={msLogo} alt='ms-logo' />
          </div>
          <h2 style={{ color: 'black' }}>LOGIN</h2>
          {loginFail && <p style={{ color: 'orangeRed' }}>Incorrect email or password. Please try again</p>}
          <input
            type='email'
            name='username'
            id='username'
            placeholder=' Username'
            onChange={e => handleInput('user_name', e.target.value)}
            data-lpignore='false'
            autofill='true'
          />
          <input
            type='password'
            name='password'
            id='password'
            placeholder=' Password'
            onChange={e => handleInput('password', e.target.value)}
            data-lpignore='false'
          />
          <Button onClick={() => handleLogin()} size='sm' style={{ margin: '1rem' }}>
            Submit
          </Button>
        </div>
      </div>
    );
  }
}
