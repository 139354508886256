import React, { Component } from 'react';
import styles from './SubnetsPage.module.scss';
import pageStyles from '../../styles/page.module.scss';
import tableStyles from '../../styles/table.module.scss';
import miscStyles from '../../styles/misc.module.scss';
import rightMenuStyles from '../../styles/rightMenu.module.scss';
import utils from '../../utils/utils';
import Subnet from './Subnet/Subnet';
import ApnService from '../../services/ApnService';
import SubnetService from '../../services/SubnetService';
import CustomerService from '../../services/CustomerService';

import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { updateDisplayStatus, updateErrorMessage, updateErrors } from '../../ducks/reducer';
import { Button } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

export class SubnetsPage extends Component {
  state = {
    subnets: [],
    apns: {},
    customers: {},
    newSubnet: {},
    subnetSearch: '',
    hoveredCategory: '',
    mobilsentry_id: '',
    mouseHover: true,
    currentEdit: null,
    isLoading: true,
    sortDescending: true,
    displayMenu: false,
  };

  componentDidMount = async () => {
    try {
      await this.getSubnets(undefined, false);
      await this.getCustomers(undefined, false);
      await this.assignNamesToSubnets();
      this.setState({ isLoading: false });
    } catch (err) {
      console.log(err);
    }
  };

  getSubnets = async (params, forceReload) => {
    const apns = await this.getApns(params, forceReload);
    const allData = await Promise.all(
      apns.map(apn => {
        return SubnetService.getSubnetsByApnId(apn.id, params, forceReload);
      })
    );
    let allSubnets = [];
    allData.forEach(res => {
      allSubnets = allSubnets.concat(res.data);
    });
    this.setState({ subnets: allSubnets });
  };

  getApns = async (params, forceReload) => {
    const res = await ApnService.getApns(params, forceReload);
    let apns = {};
    res.data.forEach(apn => {
      apns[apn.id] = apn;
    });
    this.setState({ apns: apns });
    return res.data;
  };

  getCustomers = async (params, forceReload) => {
    let res = await CustomerService.getAllCustomers(params, forceReload);
    let customers = {};
    res.data.forEach(c => {
      customers[c.id] = c;
    });
    this.setState({ customers: customers });
    return res.data;
  };

  assignNamesToSubnets = () => {
    const { subnets, apns, customers } = this.state;
    let appended = subnets.map(subnet => {
      let apn = apns[subnet.apn_id] || { name: '' };
      let customer = customers[subnet.customer_id] || { name: '' };
      subnet.apn_name = apn.name;
      subnet.customer_name = customer.name;
      return subnet;
    });
    this.setState({ subnets: appended });
  };

  displayAddSubnet = () => {
    this.setState({ displayMenu: true, newSubnet: {} });
  };

  updateNewSubnet = (field, value) => {
    let { newSubnet } = this.state;
    if (field === 'apn' && value.length) {
      newSubnet.apn = this.state.apns[value[0].id];
    } else if (field === 'customer' && value.length) {
      newSubnet.customer = this.state.customers[value[0].id];
    } else {
      newSubnet[field] = value;
    }
    this.setState({ newSubnet: newSubnet });
  };

  addSubnet = async () => {
    let { newSubnet } = this.state;
    if (!newSubnet.apn || !newSubnet.customer || !newSubnet.description || !newSubnet.subnet) {
      this.props.updateDisplayStatus(true);
      this.props.updateErrorMessage('Missing required field(s).');
      return;
    }
    try {
      let body = {
        apn_id: newSubnet.apn.id,
        customer_id: newSubnet.customer.id,
        description: newSubnet.description,
        subnet: newSubnet.subnet,
      };
      await SubnetService.createNewSubnet(newSubnet.apn.id, body);
      delete newSubnet.apn;
      delete newSubnet.customer;
      await this.getSubnets(undefined, true);
      await this.getCustomers(undefined, true);
      this.assignNamesToSubnets();
      this._typeahead.getInstance().clear();
      this._typeahead2.getInstance().clear();
      this.setState({ displayMenu: false });
    } catch (err) {
      this.setState({ displayMenu: true });
    }
  };

  cancelAddSubnet = () => {
    this._typeahead.getInstance().clear();
    this._typeahead2.getInstance().clear();
    this.setState({
      newSubnet: {},
      displayMenu: false,
    });
  };

  clearSearch = () => {
    this._subnetSearch.value = '';
    this.setState({ subnetSearch: '' });
  };

  setCurrentEdit = id => {
    this.setState({ currentEdit: id });
  };

  handleSort = property => {
    this.setState({ sortDescending: !this.state.sortDescending }, () => {
      let subnetsList = this.state.subnets;
      let sortDescending = this.state.sortDescending;
      let sorted = utils.handleSort(property, subnetsList, sortDescending);
      this.setState({ subnets: sorted });
    });
  };

  handleMouseEnter = category => {
    this.setState({
      mouseHover: true,
      hoveredCategory: category,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      mouseHover: false,
      hoveredCategory: '',
    });
  };

  filterSubnets = () => {
    const { subnetSearch, subnets } = this.state;
    let filteredSubnets = [...subnets];
    if (subnetSearch) {
      filteredSubnets = filteredSubnets.filter(subnet => {
        for (let key in subnet) {
          if (typeof subnet[key] === 'string') {
            if (subnet[key].toLowerCase().includes(subnetSearch.toLowerCase())) {
              return true;
            }
          } else if (typeof subnet[key] === 'number') {
            if (subnet[key].toString().includes(subnetSearch.toString())) {
              return true;
            }
          }
        }
        return false;
      });
    }
    return filteredSubnets;
  };

  displaySubnets = () => {
    let filteredSubnets = this.filterSubnets();
    let subnetsList = filteredSubnets.map(subnet => {
      let apn = this.state.apns[subnet.apn_id] || {};
      let customer = this.state.customers[subnet.customer_id] || {};
      return (
        <Subnet
          key={subnet.id}
          id={subnet.id}
          subnet_id={subnet.id}
          apn={apn}
          customer={customer}
          description={subnet.description}
          subnet={subnet.subnet}
          deleteSubnet={this.deleteSubnet}
          currentEdit={this.state.currentEdit}
          setCurrentEdit={this.setCurrentEdit}
        />
      );
    });
    return subnetsList;
  };

  displaySubnetsTable = () => {
    const { mouseHover, hoveredCategory, sortDescending } = this.state;

    return (
      <div className={tableStyles.table}>
        <div className={tableStyles.header} style={{ height: '2rem' }}>
          <div className={tableStyles.columnTitleRow}>
            <div
              className={miscStyles.hoverCursor}
              onClick={() => this.handleSort('apn_name')}
              onMouseEnter={() => this.handleMouseEnter('apn_name')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              APN
              {utils.displayArrow('apn_name', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              onClick={() => this.handleSort('description')}
              onMouseEnter={() => this.handleMouseEnter('description')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Description
              {utils.displayArrow('description', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              onClick={() => this.handleSort('subnet')}
              onMouseEnter={() => this.handleMouseEnter('subnet')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Subnet
              {utils.displayArrow('subnet', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div
              className={miscStyles.hoverCursor}
              onClick={() => this.handleSort('customer_name')}
              onMouseEnter={() => this.handleMouseEnter('customer_name')}
              onMouseLeave={() => this.handleMouseLeave()}
            >
              Customer
              {utils.displayArrow('customer_name', mouseHover, hoveredCategory, sortDescending)}
            </div>
            <div style={{ flex: '.5' }}></div>
          </div>
        </div>

        <div className={tableStyles.body} style={{ height: 'calc(100% - 2rem)' }}>
          {this.displaySubnets()}
        </div>
      </div>
    );
  };

  addSubnetMenu = () => {
    const { subnets, newSubnet } = this.state;
    let customersArr = [];
    let apnsArr = [];
    for (let id in this.state.customers) {
      customersArr.push(this.state.customers[id]);
    }
    for (let id in this.state.apns) {
      apnsArr.push(this.state.apns[id]);
    }
    let sortedCustomersArr = customersArr.sort((a, b) => (a.name > b.name ? 1 : -1));
    apnsArr = apnsArr.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    let existingSubnets = [];
    if (newSubnet.apn) {
      subnets.forEach(subnet => {
        if (subnet.apn_id === newSubnet.apn.id) {
          existingSubnets.push(subnet);
        }
      });
    }

    return (
      <div
        className={
          this.state.displayMenu
            ? `${rightMenuStyles.rightMenu} ${rightMenuStyles.slideRightMenu}`
            : `${rightMenuStyles.rightMenu}`
        }
      >
        <div className={rightMenuStyles.addMenuInputs}>
          <div>
            <Typeahead
              options={sortedCustomersArr}
              labelKey={'name'}
              id='subnet-customer-selector'
              placeholder={'Select Customer'}
              selectHintOnEnter={true}
              ref={ref => (this._typeahead = ref)}
              onChange={selected => this.updateNewSubnet('customer', selected)}
            />
          </div>
          <div>
            <Typeahead
              options={apnsArr}
              labelKey={'name'}
              id='subnet-apn-selector'
              placeholder={'Select APN'}
              selectHintOnEnter={true}
              ref={ref => (this._typeahead2 = ref)}
              onChange={selected => this.updateNewSubnet('apn', selected)}
            />
          </div>
          <div>
            <input
              value={newSubnet.description || ''}
              type='text'
              placeholder=' Description'
              onChange={e => this.updateNewSubnet('description', e.target.value)}
            />
          </div>
          <div>
            <input
              value={newSubnet.subnet || ''}
              type='text'
              placeholder=' Subnet'
              onChange={e => this.updateNewSubnet('subnet', e.target.value)}
            />
          </div>
          <div>
            <Button onClick={() => this.cancelAddSubnet()} size='sm' style={{ margin: '1rem' }}>
              Cancel
            </Button>
            <Button
              onClick={() => this.addSubnet()}
              size='sm'
              style={{ margin: '1rem' }}
              color='success'
              disabled={!newSubnet.customer || !newSubnet.apn || !newSubnet.description || !newSubnet.subnet}
            >
              Submit
            </Button>
          </div>
        </div>
        <div className={styles.newSubnetText}>
          <div>
            Currently Existing Subnets For {newSubnet.apn ? newSubnet.apn.name : ''}(
            {newSubnet.apn ? newSubnet.apn.network : ''}):
          </div>
          <br />
          {existingSubnets.map(subnet => (
            <div key={subnet.id}> - {subnet.subnet}</div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={pageStyles.page}>
        <div className={pageStyles.header}>
          <p className={pageStyles.title}>Subnets</p>
          <div className={pageStyles.buttonInputContainer}>
            <div className={pageStyles.searchBox}>
              <input
                type='search'
                ref={el => (this._subnetSearch = el)}
                placeholder=' Search'
                onChange={e => this.setState({ subnetSearch: e.target.value })}
              />
              <p onClick={() => this.clearSearch()}>&#10005;</p>
            </div>
            <Button onClick={() => this.displayAddSubnet()} size='sm'>
              Add Subnet
            </Button>
          </div>
        </div>

        <div className={pageStyles.body}>
          {this.state.isLoading ? (
            <div className={miscStyles.progressContainer}>
              <CircularProgress />
            </div>
          ) : (
            <div className={styles.tableContainer}>{this.displaySubnetsTable()}</div>
          )}
          {this.addSubnetMenu()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = reduxState => reduxState;

export default connect(mapStateToProps, { updateDisplayStatus, updateErrorMessage, updateErrors })(SubnetsPage);
