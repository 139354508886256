import axios from 'axios';

export class MobilSentryService {
  cache = {
    mobilsentries: {},
  };

  getMobilSentries = (params, forceReload) => {
    let paramsString = JSON.stringify(params);
    if (this.cache.mobilsentries[paramsString] && !forceReload) {
      return this.cache.mobilsentries[paramsString];
    }
    let options = { params: params };
    let retVal = axios.get('/mobilsentries', options).then(res => {
      this.cache.mobilsentries[paramsString] = retVal;
      return res;
    });
    return retVal;
  };

  clearCache = () => {
    this.cache = {
      mobilsentries: {},
    };
  };
}

export default new MobilSentryService();
