import React, { Component } from 'react';
import miscStyles from '../../styles/misc.module.scss';
import styles from './ServicesPage.module.scss';
import pageStyles from '../../styles/page.module.scss';
import CategoryService from '../../services/CategoryService';
import Service from './Service/Service';
import ServiceDomain from './ServiceDomain/ServiceDomain';

import { Button } from 'reactstrap';

export class ServicesPage extends Component {
  state = {
    categories: [],
    services: [],
    serviceDomains: [],
    selectedCategoryId: '',
    selectedServiceId: '',
    selectedServiceName: '',
    selectedServiceDomainId: '',
    showAddServiceModal: false,
    showAddServiceDomainModal: false,
    category_id: '',
    service_id: '',
    name: '',
    token: '',
    domain: '',
  };

  componentDidMount = () => {
    try {
      this.getCategories();
      this.getServices();
      this.getServiceDomains();
    } catch (err) {
      console.log(err);
    }
  };

  getCategories = async () => {
    let res = await CategoryService.getCategories();
    this.setState({ categories: res.data });
  };

  getServices = async () => {
    let res = await CategoryService.getServices();
    this.setState({ services: res.data });
  };

  getServiceDomains = async () => {
    let res = await CategoryService.getServiceDomains();
    this.setState({ serviceDomains: res.data });
  };

  handleInput = async (key, value) => {
    this.setState({ [key]: value }, () => {
      if (key === 'name') {
        this.formatToken();
      }
    });
  };

  formatToken = () => {
    let name = this.state.name.toLowerCase();
    function isAlphaNumeric(char) {
      let code = char.charCodeAt(0);
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 96 && code < 123)
      ) {
        // lower alpha (a-z)
        return false;
      } else return true;
    }

    let formatted = name
      .split('')
      .map(char => {
        if (isAlphaNumeric(char)) {
          return char;
        } else if (char === ' ') {
          return '-';
        } else if (char === '-') {
          return char;
        } else return '';
      })
      .join('');
    this.setState({ token: formatted });
  };

  handleSelection = (property, id) => {
    if (property === 'selectedCategoryId') {
      if (id === '') {
        this.setState({
          selectedCategoryId: '',
          selectedServiceId: '',
          selectedServiceDomainId: '',
        });
      } else {
        this.setState({
          selectedCategoryId: id,
          category_id: id,
          selectedServiceId: '',
          selectedServiceDomainId: '',
        });
      }
    } else if (property === 'selectedServiceId') {
      let serviceName = this.getServiceName(id);
      let categoryId = this.state.services.filter(service => service.id === id)[0].category_id;
      this.setState({
        service_id: id,
        selectedServiceId: id,
        selectedServiceName: serviceName,
        selectedCategoryId: categoryId,
      });
    } else {
      this.setState({ [property]: id });
    }
  };

  filterServices = () => {
    const { services, selectedCategoryId } = this.state;
    return services.filter(service => service.category_id === selectedCategoryId);
  };

  filterServiceDomains = () => {
    const { serviceDomains, selectedServiceId } = this.state;
    return serviceDomains.filter(serviceDomain => serviceDomain.service_id === selectedServiceId);
  };

  getServiceName = id => {
    let service = this.state.services.filter(service => service.id === id);
    if (service.length) {
      return service[0].name;
    } else {
      return '';
    }
  };

  addService = async () => {
    const { name, token, category_id } = this.state;
    let body = {
      name: name,
      token: token,
      category_id: category_id,
    };
    try {
      let res = await CategoryService.addService(body);
      await this.getServices();
      await this.getServiceDomains();
      this.setState({
        name: '',
        token: '',
        showAddServiceModal: false,
      });
      this.handleSelection('selectedCategoryId', res.data.category_id);
      this.handleSelection('selectedServiceId', res.data.id);
    } catch (err) {
      console.log(err);
      this.setState({
        name: '',
        token: '',
      });
    }
  };

  addServiceDomain = async () => {
    const { service_id, domain, services, categories } = this.state;
    let body = {
      service_id: service_id,
      domain: domain,
    };
    try {
      let res = await CategoryService.addServiceDomain(body);
      await this.getServices();
      await this.getServiceDomains();
      this.setState({
        domain: '',
        showAddServiceDomainModal: false,
      });
      let service = services.filter(service => service.id === res.data.service_id)[0];
      let category = categories.filter(category => category.id === service.category_id)[0];
      this.handleSelection('selectedCategoryId', category.id);
      this.handleSelection('selectedServiceId', res.data.service_id);
      this.handleSelection('selectedServiceDomainId', res.data.id);
    } catch (err) {
      console.log(err);
      this.setState({
        domain: '',
      });
    }
  };

  cancelAdd = () => {
    this.setState({
      showAddServiceModal: false,
      showAddServiceDomainModal: false,
      name: '',
      token: '',
      domain: '',
    });
  };

  categoryOptions = () => {
    return this.state.categories.map(category => {
      return (
        <option key={category.id} value={category.id}>
          {category.name}
        </option>
      );
    });
  };

  serviceOptions = () => {
    return this.state.services.map(service => {
      return (
        <option key={service.id} value={service.id}>
          {service.name}
        </option>
      );
    });
  };

  displayServiceList = () => {
    let filteredServices = this.filterServices();
    let list = filteredServices.map(service => {
      return (
        <Service
          key={service.id}
          service={service}
          handleSelection={this.handleSelection}
          selectedServiceId={this.state.selectedServiceId}
          categoryOptions={this.categoryOptions}
          getCategories={this.getCategories}
          getServices={this.getServices}
          getServiceDomains={this.getServiceDomains}
        />
      );
    });
    return list;
  };

  displayServiceDomainList = () => {
    let filteredServiceDomains = this.filterServiceDomains();
    let list = filteredServiceDomains.map(serviceDomain => {
      return (
        <ServiceDomain
          key={serviceDomain.id}
          serviceDomain={serviceDomain}
          handleSelection={this.handleSelection}
          selectedServiceDomainId={this.state.selectedServiceDomainId}
          getServices={this.getServices}
          getServiceDomains={this.getServiceDomains}
          serviceOptions={this.serviceOptions}
        />
      );
    });
    return list;
  };

  addServiceModal = () => {
    const { name, token, selectedCategoryId } = this.state;
    return (
      <div className={miscStyles.modalWrapper} onClick={() => this.cancelAdd()}>
        <div className={styles.editAddServiceModal} onClick={e => e.stopPropagation()}>
          <h3>Services</h3>
          <div className={styles.serviceModalInputs}>
            <div>
              <p>Category:</p>
              <select
                name='edit-service-category-options'
                id='edit-service-category-options'
                value={selectedCategoryId}
                onChange={e => this.handleSelection('selectedCategoryId', parseInt(e.target.value))}
              >
                <option value='' disabled defaultValue>
                  -- Select Category --
                </option>
                <option value=''>(none)</option>
                {this.categoryOptions()}
              </select>
            </div>
            <div>
              <p>Name:</p>
              <input type='text' value={name} onChange={e => this.handleInput('name', e.target.value)} />
            </div>
            <div>
              <p>Token:</p>
              <input type='text' value={token} onChange={e => this.handleInput('token', e.target.value)} />
            </div>
          </div>
          <div className={styles.serviceModalButtons}>
            <Button onClick={() => this.cancelAdd()} size='sm' style={{ margin: '.5rem 1rem .5rem 1rem' }}>
              Cancel
            </Button>
            <Button
              onClick={() => this.addService()}
              size='sm'
              style={{ margin: '.5rem 1rem .5rem 1rem' }}
              color='success'
              disabled={!selectedCategoryId || !name || !token}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  };

  addServiceDomainModal = () => {
    const { service_id, domain } = this.state;
    return (
      <div className={miscStyles.modalWrapper} onClick={() => this.cancelAdd()}>
        <div className={styles.editAddServiceModal} onClick={e => e.stopPropagation()}>
          <h3>Add New Service Domain</h3>
          <div className={styles.serviceModalInputs}>
            <div>
              <p>Service:</p>
              <select
                name='edit-service-category-options'
                id='edit-service-category-options'
                value={service_id}
                onChange={e => this.handleSelection('selectedServiceId', parseInt(e.target.value))}
                style={{ width: '13rem' }}
              >
                <option disabled defaultValue>
                  -- Select Service --
                </option>
                <option value=''>(none)</option>
                {this.serviceOptions()}
              </select>
            </div>
            <div>
              <p>Domain:</p>
              <input type='text' value={domain} onChange={e => this.handleInput('domain', e.target.value)} />
            </div>
          </div>
          <div className={styles.serviceModalButtons}>
            <Button onClick={() => this.cancelAdd()} size='sm' style={{ margin: '.5rem 1rem .5rem 1rem' }}>
              Cancel
            </Button>
            <Button
              onClick={() => this.addServiceDomain()}
              size='sm'
              style={{ margin: '.5rem 1rem .5rem 1rem' }}
              color='success'
              disabled={!service_id || !domain}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={pageStyles.page}>
        <div className={pageStyles.header}>
          <p className={pageStyles.title}>Services</p>
        </div>
        <div className={pageStyles.body}>
          <div className={styles.container}>
            <div className={styles.servicePanel}>
              <select
                value={this.state.selectedCategoryId}
                name='select-service-category'
                id='select-service-category'
                onChange={e => this.handleSelection('selectedCategoryId', parseInt(e.target.value) || '')}
              >
                <option value='' disabled defaultValue>
                  -- Select Category --
                </option>
                <option value=''>(none)</option>
                {this.categoryOptions()}
              </select>
              <div className={styles.servicePanelColumnContainer}>
                <div className={styles.servicePanelListContainer}>
                  <h5>Services</h5>
                  <Button onClick={() => this.setState({ showAddServiceModal: true })} size='sm' color='info'>
                    Add Service
                  </Button>
                  <div className={styles.servicePanelList}>{this.displayServiceList()}</div>
                </div>
                <span></span>
                <div className={styles.servicePanelListContainer}>
                  <h5>
                    Service Domains {this.state.selectedServiceName ? ` - ${this.state.selectedServiceName}` : null}
                  </h5>
                  <Button onClick={() => this.setState({ showAddServiceDomainModal: true })} size='sm' color='info'>
                    Add Service Domain
                  </Button>
                  <div className={styles.servicePanelList}>{this.displayServiceDomainList()}</div>
                </div>
              </div>
            </div>
          </div>

          {this.state.showAddServiceModal && this.addServiceModal()}
          {this.state.showAddServiceDomainModal && this.addServiceDomainModal()}
        </div>
      </div>
    );
  }
}

export default ServicesPage;
