import React from 'react';
import tableStyles from '../styles/table.module.scss';

const utils = {
  handleSort: function (property, list, sortDescending) {
    let sorted;
    if (
      property === 'id' ||
      property === 'mobilsentry_id' ||
      property === 'customer_id' ||
      property === 'device_model_id'
    ) {
      //this sorts integers
      sorted = list.sort((a, b) => {
        if (sortDescending === true) {
          return b[property] - a[property];
        } else {
          return a[property] - b[property];
        }
      });
    } else {
      sorted = list.sort((a, b) => {
        //sorts alphabetically
        if (sortDescending === true) {
          if (a[property] === null || a[property] === '') return 1;
          else if (b[property] === null || b[property] === '') return -1;
          else if (b[property].toLowerCase() < a[property].toLowerCase()) return -1;
          else if (b[property].toLowerCase() > a[property].toLowerCase()) return 1;
          else return 0;
        } else {
          if (a[property] === null || a[property] === '') return -1;
          else if (b[property] === null || b[property] === '') return 1;
          else if (a[property].toLowerCase() < b[property].toLowerCase()) return -1;
          else if (a[property].toLowerCase() > b[property].toLowerCase()) return 1;
          else return 0;
        }
      });
    }
    return sorted;
  },
  displayArrow: (category, mouseHover, hoveredCategory, sortDescending) => {
    if (mouseHover && category === hoveredCategory) {
      if (sortDescending) {
        return <span className={tableStyles.sortArrow}> &#8593;</span>;
      } else {
        return <span className={tableStyles.sortArrow}> &#8595;</span>;
      }
    }
  },
  stripCSVNoise: data => {
    let rowLengths = data.map(row => row.length);
    let lengthsDictionary = {};
    rowLengths.forEach(length => {
      if (lengthsDictionary[length]) {
        lengthsDictionary[length]++;
      } else {
        lengthsDictionary[length] = 1;
      }
    });
    let maxOccurrences = 0;
    let mostCommonLength;
    for (let key in lengthsDictionary) {
      if (lengthsDictionary[key] > maxOccurrences) {
        maxOccurrences = lengthsDictionary[key];
        mostCommonLength = key;
      }
    }
    let cleanData = data.filter(row => row.length >= mostCommonLength);
    return cleanData;
  },
  formatIpAddress: address => {
    if (!address) return null;

    let ipArr = address.split('.');
    let removedZeros = ipArr.map(part => {
      let partArr = part.split('');
      let isNum = false;
      while (!isNum) {
        if (partArr[0] === '0' && partArr.length > 1) {
          partArr.splice(0, 1);
        } else {
          isNum = true;
        }
      }
      return partArr.join('');
    });
    return removedZeros.join('.');
  },
  formatServiceNum: str => {
    if (!str) return null;
    str = str.replace(/\D/g, '').split('');
    if (str.length === 10) {
      str.unshift('1');
      return str.join('');
    } else if (str.length === 11 && str.join('').startsWith('1')) {
      return str.join('');
    } else return null;
  },
  readableServiceNum: num => {
    // converts service number to more readable format
    if (!num) return '';
    if (typeof num === 'number') {
      num = num.toString();
    }
    num = num.split('');
    if (num[0] === '1') {
      num.splice(0, 1);
    }
    let first = num.splice(0, 3).join('');
    let second = num.splice(0, 3).join('');
    let third = num.join('');

    return `(${first}) ${second}-${third}`;
  },
};

export default utils;
