import React, { Component } from 'react';
import styles from './ServiceDomain.module.scss';
import ServicesPageStyles from '../ServicesPage.module.scss';
import miscStyles from '../../../styles/misc.module.scss';
import CategoryService from '../../../services/CategoryService';

import Swal from 'sweetalert2';

export default class ServiceDomain extends Component {
  state = {
    service_id: '',
    domain: '',
    etag: '',
    currentEditServiceDomain: null,
  };

  setData = async id => {
    let res = await CategoryService.getServiceDomainById(id);
    this.setState({
      service_id: this.props.serviceDomain.service_id,
      domain: this.props.serviceDomain.domain,
      etag: res.headers.etag,
      currentEditServiceDomain: res.data,
    });
  };

  handleEditClick = id => {
    this.setData(id);
    this.props.handleSelection('selectedServiceDomainId', id);
  };

  handleInput = (key, value) => {
    this.setState({ [key]: value });
  };

  submitEdit = () => {
    const { service_id, domain, etag } = this.state;
    const { id } = this.props.serviceDomain;
    let body = {
      service_id: service_id,
      domain: domain,
    };
    let config = {
      headers: {
        'If-Match': etag,
      },
    };
    try {
      CategoryService.editServiceDomain(id, body, config).then(res => {
        this.props.getServices();
        this.props.getServiceDomains();
        this.setState({
          etag: '',
          currentEditServiceDomain: null,
        });
        this.props.handleSelection('selectedServiceId', res.data.service_id);
      });
    } catch (err) {
      console.log(err);
      this.setState({
        service_id: this.props.serviceDomain.service_id,
        domain: this.props.serviceDomain.domain,
      });
    }
  };

  cancelEdit = () => {
    this.setState({
      service_id: this.props.serviceDomain.service_id,
      domain: this.props.serviceDomain.domain,
      etag: '',
      currentEditServiceDomain: null,
    });
  };

  deleteServiceDomain = async () => {
    const { id } = this.props.serviceDomain;
    const { etag, domain } = this.state;
    const config = {
      headers: {
        'If-Match': etag,
      },
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.value) {
        try {
          CategoryService.deleteServiceDomain(id, config).then(res => {
            Swal.fire('Deleted!', `${domain} has been deleted.`, 'success');
            this.props.getServices();
            this.props.getServiceDomains();
            this.setState({
              etag: '',
              currentEditServiceDomain: null,
            });
          });
        } catch (err) {
          console.log(err);
        }
      }
    });
  };

  editServiceDomainModal = () => {
    const { service_id, domain } = this.state;
    return (
      <div className={miscStyles.modalWrapper} onClick={() => this.cancelEdit()}>
        <div className={ServicesPageStyles.editAddServiceModal} onClick={e => e.stopPropagation()}>
          <h3>
            Edit <span style={{ color: 'gray', border: 'none' }}>{this.props.serviceDomain.domain}</span>
          </h3>
          <div className={ServicesPageStyles.serviceModalInputs}>
            <div>
              <p>Service:</p>
              <select
                name='edit-service-category-options'
                id='edit-service-category-options'
                value={service_id}
                onChange={e => this.handleInput('service_id', e.target.value)}
                style={{ width: '13rem' }}
              >
                <option disabled defaultValue>
                  -- Select Service --
                </option>
                <option value=''>(none)</option>
                {this.props.serviceOptions()}
              </select>
            </div>
            <div>
              <p>Domain:</p>
              <input type='text' value={domain} onChange={e => this.handleInput('domain', e.target.value)} />
            </div>
          </div>
          <div className={ServicesPageStyles.serviceModalButtons}>
            <p onClick={() => this.cancelEdit()}>Cancel</p>
            <p onClick={() => this.submitEdit()}>Submit</p>
          </div>
          <div className={styles.deleteServiceDomainContainer}>
            <i onClick={() => this.deleteServiceDomain()} className='far fa-trash-alt'></i>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { serviceDomain, selectedServiceDomainId } = this.props;
    return (
      <div
        className={
          selectedServiceDomainId === serviceDomain.id
            ? `${ServicesPageStyles.serviceItem} ${styles.selectedServiceDomain}`
            : `${ServicesPageStyles.serviceItem}`
        }
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
      >
        <p className={ServicesPageStyles.selectServiceText} onClick={e => this.handleEditClick(serviceDomain.id)}>
          {serviceDomain.domain}
        </p>

        {this.state.currentEditServiceDomain && this.editServiceDomainModal()}
      </div>
    );
  }
}
