import React, { Component } from 'react';
import styles from './Device.module.scss';
import tableStyles from '../../../styles/table.module.scss';
import miscStyles from '../../../styles/misc.module.scss';
import CustomerService from '../../../services/CustomerService';
import DeviceService from '../../../services/DeviceService';
import utils from '../../../utils/utils';

import { Typeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import BeatLoader from 'react-spinners/BeatLoader';
import Swal from 'sweetalert2';

const DarkTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'rgb(74, 74, 74)',
  },
}))(Tooltip);

export default class Device extends Component {
  state = {
    id: '',
    apn: '',
    carrier: '',
    device_type: '',
    mobilsentry: '',
    service_number: '',
    address: '',
    name: '',
    username: '',
    password: '',
    email: '',
    customer: '',
    deleted: '',
    etag: '',
    latestAuth: '',
    latestAuthDate: '',
    editDevice: false,
    displayShareConfigModal: false,
  };

  componentDidMount = () => {
    this.setState({
      id: this.props.id,
      apn: this.props.apn,
      carrier: this.props.carrier,
      device_type: this.props.device_type,
      mobilsentry: this.props.mobilsentry,
      service_number: this.props.service_number,
      address: this.props.address,
      name: this.props.name,
      username: this.props.username,
      password: this.props.password,
      email: this.props.email,
      customer: this.props.customer,
      deleted: this.props.deleted,
      latestAuth: this.props.latestAuth,
      latestAuthDate: this.props.latestAuthDate,
    });
  };

  handleInput = (key, value) => {
    this.setState({ [key]: value });
  };

  toggleEdit = () => {
    const { id } = this.props;
    this.props.setCurrentEdit(id);
    this.setState({ editDevice: !this.state.editDevice });
    this.getEtag();
  };

  cancelEdit = () => {
    const {
      id,
      service_number,
      address,
      name,
      username,
      password,
      email,
      device_type,
      customer,
      apn,
      deleted,
    } = this.props;
    this.setState({
      id: id,
      service_number: service_number,
      address: address,
      name: name,
      username: username,
      password: password,
      email: email,
      device_type: device_type,
      customer: customer,
      apn: apn,
      deleted: deleted,
      editDevice: false,
    });
  };

  getEtag = async () => {
    const { id } = this.props;
    let res = await DeviceService.getDeviceById(id, undefined, true);
    this.setState({ etag: res.headers.etag });
  };

  submitEdit = async () => {
    const { id, address, name, device_type, username, password, email, customer, apn } = this.state;
    let serviceNum = utils.formatServiceNum(this.state.service_number);
    let body = {
      id: id,
      service_number: serviceNum,
      address: address,
      name: name,
      device_type: device_type,
      username: apn.carrier_id === 2 ? '' : username,
      password: apn.carrier_id === 2 ? '' : password,
      email: email,
      customer_id: customer.id,
      apn_id: apn.id,
    };
    let config = {
      headers: {
        'If-Match': this.state.etag,
      },
    };

    try {
      let updatedDevice = await DeviceService.editDevice(this.props.id, body, config);
      updatedDevice = updatedDevice.data;
      let customer = await CustomerService.getCustomerById(updatedDevice.customer_id);
      customer = customer.data;
      let carrier = this.props.allCarriers.filter(carrier => carrier.id === this.state.apn.carrier_id)[0] || {
        name: '',
      };
      let mobilsentry = this.props.allMobilsentries.filter(
        mobilsentry => mobilsentry.id === customer.mobilsentry_id
      )[0] || { name: '' };
      this.setState({
        customer: customer,
        mobilsentry: mobilsentry,
        carrier: carrier,
        username: updatedDevice.username,
        password: updatedDevice.password,
      });
    } catch (err) {
      this.cancelEdit();
      console.log(err);
    }
    this.setState({ editDevice: false });
  };

  handleSelection = (property, value) => {
    if (value) {
      this.setState({
        [property]: value,
      });
    }
  };

  sendConfigFile = async protocol => {
    const { id, service_number } = this.props;
    const readableServiceNum = utils.readableServiceNum(service_number);

    Swal.fire({
      title: 'Share Config File',
      text: `Send file via SMS to ${readableServiceNum}?`,
      showCancelButton: true,
      confirmButtonColor: ' #16a2b8',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Yes, send it!',
    }).then(result => {
      if (result.value) {
        const body = [
          {
            device_id: id,
            protocol: protocol,
          },
        ];

        DeviceService.sendConfigFile(body)
          .then(res => {
            Swal.fire('Success!', `Config file has been sent to ${readableServiceNum}`, 'success');
          })
          .catch(err => {
            Swal.fire('Error', 'Config file could not be sent', 'error');
          });
      }
    });
  };

  displayServiceNum = () => {
    let numArr = this.state.service_number.split('');
    let formattedServiceNum = `${numArr.slice(1, 4).join('')}-${numArr.slice(4, 7).join('')}-${numArr
      .slice(7, 11)
      .join('')}`;
    return formattedServiceNum;
  };

  carrierOptions = () => {
    let options = this.props.allCarriers.map(carrier => {
      return (
        <option key={carrier.id} value={carrier.id}>
          {carrier.short_name}
        </option>
      );
    });
    return options;
  };

  deviceTypeOptions = () => {
    let options = this.props.allDeviceTypes.map(device_type => {
      return (
        <option key={device_type.device_type} value={device_type.device_type}>
          {device_type.device_type}
        </option>
      );
    });
    return options;
  };

  render() {
    const {
      id,
      address,
      device_type,
      customer,
      name,
      username,
      password,
      email,
      mobilsentry,
      apn,
      carrier,
      service_number,
      carrier_id,
      editDevice,
      latestAuth,
      latestAuthDate,
    } = this.state;
    const { allApns, deviceAuthAttempts } = this.props;

    return (
      <div className={editDevice ? `${styles.row} ${styles.editRow}` : `${styles.row}`}>
        <div className={styles.typeaheadContainer} style={{ width: '12rem' }}>
          {!editDevice ? (
            apn.name
          ) : (
            <Typeahead
              options={allApns ? allApns : []}
              labelKey='name'
              id='device apn selector'
              onChange={apn => this.handleSelection('apn', apn[0])}
              placeholder={apn.name ? apn.name : 'Select APN'}
              selectHintOnEnter={true}
              ref={ref => (this._typeahead = ref)}
            />
          )}
        </div>

        <div style={{ width: '6rem' }}>{carrier.short_name}</div>

        <div style={{ width: '12rem' }}>{mobilsentry.db_name}</div>

        <div style={{ width: '10rem' }}>
          {!editDevice ? (
            this.displayServiceNum()
          ) : (
            <input
              type='text'
              value={service_number}
              onChange={e => this.handleInput('service_number', e.target.value)}
              placeholder=' Service Number'
            />
          )}
        </div>

        <div style={{ width: '12rem' }}>
          {carrier.id === 2 ? (
            <>--</>
          ) : (
            <>
              {deviceAuthAttempts ? (
                <>
                  {latestAuthDate ? (
                    <span>
                      {latestAuth.reply === 'Access-Accept' ? (
                        <i className='fas fa-check' style={{ color: 'green', marginRight: '1rem' }}></i>
                      ) : (
                        <i className='fas fa-times' style={{ color: 'crimson', marginRight: '1rem' }}></i>
                      )}
                      <DarkTooltip title='View Radius / Session Data'>
                        <Link to={`/radius/${service_number}`}>{latestAuthDate}</Link>
                      </DarkTooltip>
                    </span>
                  ) : (
                    '--'
                  )}
                </>
              ) : (
                <BeatLoader size={5} loading={true} color={'#16a2b8'} />
              )}
            </>
          )}
        </div>

        <div style={{ width: '8rem' }}>
          {!editDevice ? (
            address || '--'
          ) : (
            <>
              {carrier_id === 1 ? (
                address
              ) : (
                <input
                  type='text'
                  value={address ? address : ''}
                  onChange={e => this.handleInput('address', e.target.value)}
                  placeholder={'IP Address'}
                />
              )}
            </>
          )}
        </div>

        <div style={{ width: '8rem' }}>
          {!editDevice ? (
            device_type
          ) : (
            <select
              value={device_type}
              name='select-device-type'
              id=''
              onChange={e => this.handleInput('device_type', e.target.value)}
            >
              {this.deviceTypeOptions()}
            </select>
          )}
        </div>

        <div className={styles.typeaheadContainer} style={{ width: '13rem' }}>
          {!editDevice ? (
            customer.name
          ) : (
            <Typeahead
              options={this.props.allCustomers ? this.props.allCustomers : []}
              labelKey='name'
              id='device customer selector'
              onChange={customer => this.handleSelection('customer', customer[0])}
              placeholder={customer ? customer.name : 'Select Customer'}
              selectHintOnEnter={true}
              ref={ref => (this._typeahead = ref)}
            />
          )}
        </div>

        <div style={{ width: '15rem' }}>
          {!editDevice ? (
            name
          ) : (
            <input
              type='text'
              value={name}
              onChange={e => this.handleInput('name', e.target.value)}
              placeholder={'name'}
            />
          )}
        </div>

        <div style={{ width: '10rem' }}>
          {!editDevice ? (
            username || '--'
          ) : (
            <>
              {carrier.id !== 2 ? ( //id 2 is verizon
                <input
                  type='text'
                  value={username}
                  onChange={e => this.handleInput('username', e.target.value)}
                  placeholder={'Username'}
                />
              ) : (
                username || '--'
              )}
            </>
          )}
        </div>

        <div style={{ width: '8rem' }}>
          {!editDevice ? (
            password || '--'
          ) : (
            <>
              {carrier.id !== 2 ? ( //id 2 is verizon
                <input
                  type='text'
                  value={password}
                  onChange={e => this.handleInput('password', e.target.value)}
                  placeholder={'Password'}
                />
              ) : (
                password
              )}
            </>
          )}
        </div>

        <div style={{ width: '15rem' }}>
          {!editDevice ? (
            email || '--'
          ) : (
            <input
              type='text'
              value={email}
              onChange={e => this.handleInput('email', e.target.value)}
              placeholder={'Email'}
              style={{ width: '100%' }}
            />
          )}
        </div>

        <div style={{ width: '7rem' }}>
          {device_type === 'iPhone' || device_type === 'iPad' ? (
            <div className={styles.configFileButtonContainer}>
              <Tooltip title='Download File'>
                <a href={`/api/mobile_config/!${id}`} target='_blank' rel='noopener noreferrer'>
                  <i className='fas fa-file-download'></i>
                </a>
              </Tooltip>
              <Tooltip title='Share File'>
                <i
                  className={`${miscStyles.hoverCursor} fas fa-share-alt`}
                  onClick={() => this.sendConfigFile('sms')}
                ></i>
              </Tooltip>
            </div>
          ) : (
            '--'
          )}
        </div>

        <div style={{ width: '5rem' }}>
          {!editDevice ? (
            <div className={tableStyles.rowButtonContainer}>
              <p className={miscStyles.editButton} onClick={() => this.toggleEdit()}>
                EDIT
              </p>
            </div>
          ) : (
            <div className={tableStyles.rowButtonContainer}>
              <p className={tableStyles.saveButton} onClick={() => this.submitEdit()}>
                SAVE
              </p>
              <p className={tableStyles.cancelEditButton} onClick={() => this.cancelEdit()}>
                CANCEL
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
