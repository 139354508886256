import React from 'react';
import styles from './GeneralMessageModal.module.scss';
import miscStyles from '../../../styles/misc.module.scss';

export default function GeneralMessageModal(props) {
  const { message, handleCloseModal, errors } = props;

  let errorsList;
  if (errors) {
    errorsList = errors.map(error => {
      return <p>- {error}</p>;
    });
  }

  return (
    <div className={miscStyles.modalWrapper}>
      <div className={styles.generalMessageModal}>
        <h2>Error:</h2>
        {props.message && <p>{message}</p>}
        {props.errors && (
          <>
            <div className={styles.errorList}>{errorsList}</div>
          </>
        )}
        <div>
          <button onClick={handleCloseModal}>Ok</button>
        </div>
      </div>
    </div>
  );
}
