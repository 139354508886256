import axios from 'axios';

export class SubnetService {
  cache = {
    subnets: {},
  };

  getSubnetById = (apn_id, subnet_id) => {
    return axios.get(`/apns/${apn_id}/subnets/${subnet_id}`);
  };

  getSubnetsByApnId = (apn_id, params, forceReload) => {
    if (this.cache.subnets[apn_id] && !forceReload) {
      return this.cache.subnets[apn_id];
    }
    let options = { params: params };
    let retVal = axios.get(`/apns/${apn_id}/subnets`, options).then(res => {
      this.cache.subnets[apn_id] = retVal;
      return res;
    });
    return retVal;
  };

  addSubnet = (apn_id, subnet_id, addedSubnet, config) => {
    return axios.put(`/apns/${apn_id}/subnets/${subnet_id}`, addedSubnet, config).then(res => {
      this.cache.subnets[apn_id] = null;
      return res;
    });
  };

  removeSubnet = (apn_id, subnet_id, removedSubnet, config) => {
    return axios.put(`/apns/${apn_id}/subnets/${subnet_id}`, removedSubnet, config).then(res => {
      this.cache.subnets[apn_id] = null;
      return res;
    });
  };

  createNewSubnet = (apn_id, newSubnet) => {
    return axios.post(`/apns/${apn_id}/subnets`, newSubnet).then(res => {
      this.clearCache();
      return res;
    });
  };

  clearCache = () => {
    this.cache = {
      subnets: {},
    };
  };
}

export default new SubnetService();
