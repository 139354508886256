import React, { Component } from 'react';
import tableStyles from '../../../styles/table.module.scss';
import miscStyles from '../../../styles/misc.module.scss';
import SubnetService from '../../../services/SubnetService';

export default class Subnet extends Component {
  state = {
    apn: '',
    description: '',
    subnet: '',
    customer: '',
    editSubnet: false,
  };

  componentDidMount = () => {
    const { apn, description, subnet, customer } = this.props;
    this.setState({
      apn: apn,
      description: description,
      subnet: subnet,
      customer: customer,
    });
  };

  componentDidUpdate = prevProps => {
    if (prevProps.currentEdit !== this.props.currentEdit) {
      this.cancelEdit();
    }
  };

  getEtag = async () => {
    let res = await SubnetService.getSubnetById(this.props.apn.id, this.props.id);
    this.setState({ etag: res.headers.etag });
  };

  editSubnet = async () => {
    const { id } = this.props;
    await this.props.setCurrentEdit(id);
    this.setState({ editSubnet: !this.state.editSubnet });
    this.getEtag();
  };

  submitEdit = async () => {
    // const { id } = this.props;
    // const { apn, customer, description, subnet, etag } = this.state;
    // let body = {
    //    id: id,
    //    apn_id: apn.id,
    //    customer_id: customer.id,
    //    description: description,
    //    subnet: subnet
    // };
    // let config = {
    //    headers: {
    //       'If-Match': etag,
    //    }
    // };
    // try {
    //    axios.put(`/apns/${apn.id}/subnets/${id}`, body, config);
    // }
    // catch(err) {
    //    console.log(err)
    // }
    this.setState({ editSubnet: false });
  };

  cancelEdit = () => {
    this.setState({
      apn: this.props.apn,
      description: this.props.description,
      subnet: this.props.subnet,
      customer: this.props.customer,
      editSubnet: false,
    });
  };

  handleInput = (key, value) => {
    this.setState({ [key]: value });
  };

  displayDefault = () => {
    return (
      <div className={tableStyles.row}>
        <div>{this.props.apn.name}</div>
        <div>{this.props.description}</div>
        <div>{this.props.subnet}</div>
        <div>{this.props.customer.name}</div>
        <div style={{ flex: '.5' }}>
          <div className={tableStyles.rowButtonContainer}>
            {/* <p onClick={()=> this.props.deleteSubnet(this.props.subnet_id, this.props.apn.id)}>	&#128465;</p> */}
            <p className={miscStyles.editButton} onClick={() => this.editSubnet()}>
              EDIT
            </p>
          </div>
        </div>
      </div>
    );
  };

  displayEdit = () => {
    return (
      <div className={`${tableStyles.row} ${tableStyles.editRow}`}>
        <div>
          <input
            type='text'
            value={this.state.apn.name}
            placeholder={this.state.apn.name}
            onChange={e => this.handleInput('apn', e.target.value)}
          />
        </div>
        <div>
          <input
            type='text'
            value={this.state.description}
            placeholder={this.state.description}
            onChange={e => this.handleInput('description', e.target.value)}
          />
        </div>
        <div>
          <input
            type='text'
            value={this.state.subnet}
            placeholder={this.state.subnet}
            onChange={e => this.handleInput('subnet', e.target.value)}
          />
        </div>
        <div>
          <input
            type='text'
            value={this.state.customer.name}
            placeholder={this.state.customer.name}
            onChange={e => this.handleInput('customer', e.target.value)}
          />
        </div>
        <div style={{ flex: '.5' }}>
          <div className={tableStyles.rowButtonContainer}>
            <p style={{ color: '#45d200' }} onClick={() => this.submitEdit()}>
              SAVE
            </p>
            <p style={{ color: 'white' }} onClick={() => this.cancelEdit()}>
              CANCEL
            </p>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return !this.state.editSubnet ? this.displayDefault() : this.displayEdit();
  }
}
