import React from 'react';
import styles from './EditAssignedModal.module.scss';
import miscStyles from '../../../styles/misc.module.scss';

export default function EditAssignedModal(props) {
  const { property, unassigned, assigned, closeModal, descriptions } = props;

  return (
    <div className={miscStyles.modalWrapper} onClick={() => closeModal()}>
      <div className={styles.editAssignedModal} onClick={e => e.stopPropagation()}>
        <div className={styles.assignedUnassignedList}>
          <div>
            <h3>Unassigned {property}:</h3>
            {unassigned}
          </div>
          <div>
            <h3>Assigned {property}:</h3>
            {assigned}
          </div>
          <div onClick={() => closeModal()} className={styles.modalCloseButton}>
            &#10005;
          </div>
        </div>
        <div className={styles.editModalDescriptions}>{descriptions}</div>
      </div>
    </div>
  );
}
